import { ConfirmModal } from "components/Modal/ConfirmModal";
import Icon from "components/Icon";

export const NaverLinkedConfirmModal = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <ConfirmModal
      body={
        <div>
          <p>
            <Icon variant="naver" /> 네이버 예약 연동 예약입니다.
            <br />
            예약 시작 시간을 변경하시겠습니까?
          </p>
          <p>
            예약 시간을 변경하면 네이버 예약 정보도 동일하게 변경되며
            <br />
            알림이 발송됩니다.
          </p>
        </div>
      }
      onConfirm={() => onConfirm()}
      onClose={() => onCancel()}
      cancelText="변경하지 않음"
      confirmText="변경"
      open={true}
    />
  );
};

export default NaverLinkedConfirmModal;
