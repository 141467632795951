import { parse, differenceInMinutes } from "date-fns";

export function parseTime(timeStr: string) {
  const [hours, minutes] = timeStr.split(":");

  return {
    hours: Number(hours),
    minutes: Number(minutes),
  };
}

export function toMinutes(time: { hours: number; minutes: number }) {
  return time.hours * 60 + time.minutes;
}

export function minutesToTime(minutes: number) {
  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
}

export function estimatedServiceMinutesToString(data: any) {
  const startAt = parse(
    `${data.date} ${data.startHour}`,
    "yyyy-MM-dd HH:mm",
    new Date()
  );
  const endAt = parse(
    `${data.date} ${data.endHour}`,
    "yyyy-MM-dd HH:mm",
    new Date()
  );
  const diff = differenceInMinutes(endAt, startAt) || 30;
  const time = minutesToTime(diff);

  return `${time.hours}시간 ${time.minutes}분`;
}
