import ReactQuill from "react-quill";
import styled, { css } from "styled-components";
import "./quill.css";
import "react-quill/dist/quill.snow.css";
import { ComponentType } from "react";

export const StyledReactQuill = styled(ReactQuill)`
  width: 100%;
  .ql-snow {
    border-color: #dee2ec;
    ${({ style }: any) => css`
      ${style}
    `}
  }
  & .ql-container {
    font-size: 12px;
    height: auto;
  }
` as ComponentType<any>;
