import { useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { maskName, maskBirthday } from "utils/maskString";
import { Box } from "@mui/material";

export const CustomerList = ({ customer }) => {
  const { isLandscape } = useContext(SimpleRegistrationsContext);

  return (
    <Box
      sx={{
        border: "solid 1px #DEE2EC",
        background: "#F3F8FF",
        borderRadius: "4px",
        height: isLandscape ? "10vh" : "8vh",
        padding: "2%",
        fontSize: "3vw",
        display: "flex",
        gap: "3%",
        alignItems: "center",
        fontWeight: "bold",
        margin: "3% 0",
      }}
    >
      <Box
        sx={{
          width: "44vw",
        }}
      >
        {maskName(customer.name ?? "")}
      </Box>
      <Box sx={{ width: "5vw" }}>{customer.sex === "female" ? "여" : "남"}</Box>
      <Box sx={{ width: "41vw" }}>{maskBirthday(customer.birthday)}</Box>
    </Box>
  );
};
