import {
  styled,
  Box as MuiBox,
  TextField as _MuiTextField,
  IconButton as MuiIconButton,
} from "@mui/material";
import _Button from "components/Button";
import _QuillTextField from "~/components/quill/QuillTextField";

export const IconButton = styled(MuiIconButton)(
  ({ theme }) => `
  position: absolute;
  right: 30px; 
  width: 22px;
  height: 22px;
  color: ${theme.palette.grey["700"]};
`
);

export const Boilerplate = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  width: 100%;
  p {
    flex: 0 0 auto;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  button {
    cursor: pointer;
    height: 24px;
    max-width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px 10px 3px;
    font-size: 12px;
    margin-left: 8px;
    border: solid 1px #dee2ec;
    background-color: #fff;
    color: #484760;
  }
`;

export const Box = styled(MuiBox)(
  () => `
  &.calendar-icon-wrapper{
    position:absolute;
    top:37px;
    right: 12px;
  }
  `
);

export const MuiTextField = styled(_MuiTextField)(
  ({ theme }) => `
  height: 34px;
  input {
    padding: 0 20px !important;
    font-size: 14px !important;
    height: 34px;

    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 100%;
  }
  fieldset {
    border-width: 1px !important;
    border-color: ${theme.palette.layout.line};
  }
`
);

export const QuillTextField = styled(_QuillTextField)(
  ({ theme }) => `
   
  .ql-editor.ql-blank::before {
    

    color: ${theme.palette.bluegrey["600"]};
    font-style: normal;
  }
  .ql-editor {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`
);

export const Button = styled(_Button)(
  () => `
  height: 54px;
  `
);

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
