import { styled, Box as MuiBox, Stack as MuiStack } from "@mui/material";
const BellIcon = "/assets/images/ic-update-bell.png";

export const Box = styled(MuiBox)`
  &.icon-box {
    width: 16px;
    height: 16px;
    background-image: url(${BellIcon});
    background-size: 100%;
  }

  &.contents {
    font-size: 13px;
    font-weight: 500;
  }
`;

export const Stack = styled(MuiStack)`
  &.dialog-wrapper {
    min-width: 376px;
    min-height: 184px;
    padding: 40px 30px 20px 30px;

    .title {
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
    }
  }
`;
