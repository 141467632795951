import { styled } from "@mui/material";
import { Body as BodyWrapper } from "components/Modal/SlideModal";
import { Tabs as _Tabs } from "components/Tabs";

export const Body = styled(BodyWrapper)`
  padding: 0;
`;

export const TabWrapper = styled("div")`
  border-bottom: solid 1px #dee2ec;
  background: #ffffff;
`;

export const CustomerName = styled("div")`
  width: 100%;
`;

export const iconButtonStyle = {
  width: "50px",
  height: "50px",
  margin: "5px 10px",
};

export const Tabs = styled(_Tabs)`
  background: #fff;

  .MuiTabs-flexContainer {
    display: flex;
    gap: 0;
  }

  button {
    padding-top: 10px;
  }

  & .indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
    & > span {
      background-color: #0060ff;
    }
  }
`;
