import React from "react";
import { Label, Value, Wrapper } from "components/List/List.styled";

// data 예시
// data = [
//   [{ label: "차트번호", value: customer?.chartNo }],
//   [
//     { label: "문자수신", value: customer?.smsEnabled ? "동의" : "거부" },
//     { label: "문자수신2", value: customer?.smsEnabled ? "동의" : "거부" },
//   ],
// ];

export function List({ data = [[]] }) {
  return (
    <Wrapper className="list-wrapper">
      {data.map((row, rowIndex) => (
        <div key={rowIndex}>
          {row.map((v: any, i: number) => (
            <React.Fragment key={i}>
              <Label>{v.label}</Label>
              <Value>{v.value || "-"}</Value>
            </React.Fragment>
          ))}
        </div>
      ))}
    </Wrapper>
  );
}
