import { useContext } from "react";
import { Tabs, Tab, styled } from "@mui/material";
import { AppointmentContext } from "pages/AppointmentPage/AppointmentPage";

const AntTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #e8e8e8",
  position: "sticky",
  top: 0,
  zIndex: 2,
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 3,
  },
  "& .MuiTabs-indicatorSpan": {
    backgroundColor: theme.palette.primary.main,
    maxWidth: 40,
    width: "100%",
  },
}));

const AntTab = styled((props: any) => <Tab disableRipple {...props} />)(() => ({
  color: "#adaebc",
  fontSize: 14,
  fontWeight: "normal",
  minWidth: "auto",
  padding: "0 20px",
  "&.Mui-selected": {
    fontWeight: "bold",
  },
}));

export function FilterTab() {
  const { departments, setDepartmentId, departmentId } =
    useContext(AppointmentContext);

  const handleChange = (e: any, v: any) => {
    setDepartmentId(v);
  };

  if (departments.length === 0) {
    return <></>;
  }
  return (
    <AntTabs
      value={JSON.parse(departmentId) || null}
      onChange={handleChange}
      sx={{ borderColor: "#efeff4", bgcolor: "#fff" }}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      variant="scrollable"
      scrollButtons={false}
    >
      <AntTab label="전체" value={null} key={"null"} />
      {departments.map((v: any) => (
        <AntTab key={v.id} label={v.name} value={v.id} />
      ))}
    </AntTabs>
  );
}
