import { useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import {
  AutoCompleteSelect,
  SelectLabel,
  Popper,
  Li,
  Input,
  IconWrapper,
  NoOptionsText,
  ClearButton,
} from "./ComboBox.styled";

const SelectLabelText = "직접 검색 또는 선택해주세요.";

export const ComboBox = ({
  options = [],
  onChange,
  value = null,
  placeholder = "",
  getOptionLabel,
  disableClearable,
  disabled,
  ...props
}: any) => {
  const [isClose, setIsClose] = useState(true);

  return (
    <AutoCompleteSelect
      style={{
        background: "white",
      }}
      options={options}
      onChange={(event, value) => {
        onChange(value);
      }}
      value={value}
      getOptionLabel={getOptionLabel}
      noOptionsText={
        <>
          <SelectLabel>{SelectLabelText}</SelectLabel>
          <NoOptionsText />
        </>
      }
      PopperComponent={Popper}
      onOpen={() => {
        setIsClose(false);
      }}
      onClose={() => {
        setIsClose(true);
      }}
      renderOption={(props: any, option: any) => {
        return (
          <div key={props.key}>
            {props["data-option-index"] === 0 && (
              <SelectLabel>{SelectLabelText}</SelectLabel>
            )}
            <Li {...props}>
              <div>{option.label}</div>
            </Li>
          </div>
        );
      }}
      renderInput={(params) => {
        return <Input {...params} placeholder={placeholder ?? ""} />;
      }}
      disableClearable={disableClearable ? disableClearable : isClose}
      clearIcon={<ClearButton />}
      disabled={disabled}
      popupIcon={
        <IconWrapper>
          <KeyboardArrowDownRoundedIcon />
        </IconWrapper>
      }
      {...props}
    />
  );
};
