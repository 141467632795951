import { styled } from "@mui/material/styles";
import {
  Radio as RadioIcon,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

const RadioWrapper = styled(RadioGroup)({
  height: "100%",
});

const RadioButton = styled(FormControlLabel)({
  marginLeft: "0px",
  marginRight: "24px",
  fontSize: "13px",
  "& .MuiFormControlLabel-label": {
    fontSize: "inherit",
    lineHeight: "1",
  },
});

const Icon = styled(RadioIcon)({
  padding: "0",
  paddingRight: "4px",
  "& .MuiSvgIcon-root": { fontSize: "15px" },
});

type RadioProps = {
  options?: any[];
  value: any;
  onChange: (value?: any) => void;
  disabled?: boolean;
};

export const Radio = ({
  options = [],
  value,
  onChange,
  disabled = false,
}: RadioProps) => {
  return (
    <RadioWrapper row value={value} onChange={(e) => onChange(e.target.value)}>
      {options.map((v, i) => (
        <RadioButton
          key={i}
          value={v.value}
          label={v.label}
          control={<Icon color="primary" />}
          disabled={disabled}
        />
      ))}
    </RadioWrapper>
  );
};
