export const disabledBgColor = "#f3f8ff";

export const setDefaultValue = (type: string) => {
  let defaultValue: any = {
    required: true,
    title: "",
    content: {
      text: "",
      values: [],
    },
    result: {
      text: "",
      values: [],
    },
  };
  switch (type) {
    case "RADIO-1":
    case "CHECKBOX-1":
      defaultValue = {
        required: true,
        title: "",
        content: {
          text: "",
          values: [""],
        },
        result: {
          text: "",
          values: [],
        },
      };
      break;
    case "AGREEMENT-1":
      defaultValue = {
        required: true,
        title: "이용동의",
        content: {
          text: "1. 개인정보의 수집･이용 목적 : \n2. 수집하는 개인정보의 항목 : \n3. 개인정보의 보유 및 이용 기간 : \n4. 동의거부권 및 동의 거부에 따른 불이익 안내 :",
          values: ["개인정보의 수집 및 이용에 동의합니다."],
        },
        result: {
          text: "",
          values: [],
        },
      };
      break;
    case "DATE-1":
    case "DATE-2":
      defaultValue = {
        required: true,
        title: "",
        content: {
          text: "",
          values: [],
        },
        result: {
          text: "",
          values: [],
        },
      };
      break;
    case "TEXT-1":
    case "default":
      defaultValue = {
        required: true,
        title: "",
        content: {
          text: "",
          values: [],
        },
        result: {
          text: "",
          values: [],
        },
      };
  }
  return defaultValue;
};

export const typeOptions = [
  {
    label: "객관식 (1건 선택)",
    value: "RADIO-1",
  },
  {
    label: "객관식 (다건 선택)",
    value: "CHECKBOX-1",
  },
  {
    label: "주관식",
    value: "TEXT-1",
  },
  {
    label: "날짜",
    value: "DATE-1",
  },
  {
    label: "날짜 & 시간",
    value: "DATE-2",
  },
  {
    label: "이용동의",
    value: "AGREEMENT-1",
  },
];

export const hoursOptions = Array.from({ length: 24 }, (_, index) => {
  const hour = String(index).padStart(2, "0");
  return { label: hour, value: hour };
});

export const minutesOptions = Array.from({ length: 6 }, (_, index) => {
  const minute = String(index).padEnd(2, "0");
  return { label: minute, value: minute };
});

export const surveyMockSchema = {
  subject: "초진 환자 문진",
  expiredDay: 7,
  description:
    '<p>케어랩스 성형외과&nbsp;강남 1호점 초진 환자 문진입니다.</p><p><strong>처음 방문해주신 고객님</strong> 께서는 최대한 솔직하게 작성 부탁드리며 작성 시 어려운 점이 있으시면 <span style="color: rgb(230, 0, 0);">카운터</span>에 문의 부탁드립니다.</p>',
  resultText:
    "모든 문항 응답을 완료했으며, 정상적으로 제출되었습니다.\n감사합니다.",
  content:
    '[{"type":"RADIO-1","required":false,"title":"저희 병원을 알게된 경로를 선택해 주세요.","content":{"text":"","values":["검색 (네이버, 유튜브)","바비톡","강남언니","인스타그램","버스광고","지인추천"]},"result":{"text":"","values":[]}},{"type":"CHECKBOX-1","required":true,"title":"관심있는 다이어트 프로그램 및 시술을 선택해 주세요.","content":{"text":"","values":["여성 다이어트","남성 다이어트","출산 전후 다이어트","체질개선 치료 다이어트 한약 처방 + 식단 관리 ★3달 패키지★","미니지방흡입","전신 지방흡입"]},"result":{"text":"","values":[]}},{"type":"DATE-1","required":true,"title":"예약 희망일을 선택해주세요.","content":{"text":"","values":[]},"result":{"text":"","values":[]}},{"type":"DATE-2","required":true,"title":"예약 희망일시를 선택해주세요.","content":{"text":"","values":[]},"result":{"text":"","values":[]}},{"type":"TEXT-1","required":false,"title":"복용 약이 있다면 기재해주세요.","content":{"text":"","values":[]},"result":{"text":"","values":[]}},{"type":"AGREEMENT-1","required":true,"title":"개인 정보 수집 및 이용 동의","content":{"text":"1. 개인정보 수집주체 : 주식회사 케어랩스\\n2. 개인정보 수집항목 : 병원명, 신청자명, 연락처, 카페 닉네임, 문의내용, 등록일시\\n3. 개인정보 수집 및 이용목적 : 주식회사 케어랩스에서 교육 신청, 서비스 제공 활용 (전화,문자)\\n4. 개인정보 보유 및 이용기간 : 수집일로부터 3개월 (고객 동의 철회 시 지체 없이 파기)\\n5. 개인정보 수집 이용에 동의를 거부 할 수 있으며, 동의하지 않을 경우 교육 신청이 제한됩니다.","values":["개인정보의 수집 및 이용에 동의합니다."]},"result":{"text":"","values":[]}}]',
  temp: "",
};
