export const ManagerNames = ({ item }: { item: any }) => {
  const name = [];
  const { counselor, doctor, facialist, assist, nurse } = item;
  if (doctor) {
    name.push(doctor.name);
  }

  if (counselor) {
    name.push(counselor.name);
  }

  if (facialist) {
    name.push(facialist.name);
  }

  if (assist) {
    name.push(assist.name);
  }

  if (nurse) {
    name.push(nurse.name);
  }

  return name.length > 0 ? <div>{name.join("∙")}</div> : <></>;
};

export default ManagerNames;
