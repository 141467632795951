import { Checkbox as MuiCheckbox, styled } from "@mui/material";
import Icon from "components/Icon";

export const Checkbox = styled(MuiCheckbox)`
  padding: 4px;
`;

Checkbox.defaultProps = {
  icon: <Icon variant="unchecked" />,
  checkedIcon: <Icon variant="checked" />,
  size: "small",
  color: "primary",
};
