import { styled, IconButton as MuiIconButton } from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  () => `
  width: 24px;
  height: 24px;
  padding: 0;
`
);

export const Wrapper = styled("div")`
  height: 100%;
  background: #fff;
  overflow: auto;
`;

export const Title = styled("div")`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  padding: 0 20px;
  height: 42px;
`;

export const Name = styled("span")``;

export const DeleteButton = styled("span")`
  margin-left: auto;
  cursor: pointer;
  color: #adaebc;
`;

export const List = styled("ul")`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  li {
    height: 42px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    list-style: none;
    button {
      margin-left: auto;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
