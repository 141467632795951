import {
  IconButton as MuiIconButton,
  Paper as MuiPaper,
  styled,
  TextField,
} from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  () => `
  padding: 2px;
  width: 20px;
  height: 20px;
  svg {
    width: 16px;
    height: 16px;
  }
`
);

export const Paper = styled(MuiPaper)(
  ({ theme }) => `
  border-radius: 0;
  box-shadow: none;
  border: 1px solid ${theme.palette.layout.line};
  overflow: auto;
`
);

export const StyledTextField = styled(TextField)({
  ".MuiInputBase-input": {
    padding: "4px 4px 5px 8px",
    width: 40,
    height: 34,
    boxSizing: "border-box",
    textAlign: "end",
  },
  ".MuiInput-root": {
    fontSize: 12,
    "::before": {
      borderBottom: "unset !important",
    },
    "::after": {
      borderBottom: "unset !important",
    },
  },
  ".MuiInputAdornment-root": {
    marginLeft: 0,
  },
  ".MuiTypography-root": {
    fontSize: 12,
  },
});
