import { styled as MuiStyled, IconButton } from "@mui/material";
import styled, { css } from "styled-components";

export const Wrapper = MuiStyled("div")`
  display: flex;
  flex-direction: column;
  padding: 6px 14px;
  overflow: auto;
  border: solid 1px #dee2ec;
  border-radius: 4px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Header = MuiStyled("div")`
  display: flex;
`;

export const LeftSection = MuiStyled("div")`
  display: flex;
  font-size: 14px;
  
`;

export const RightSection = MuiStyled("div")`
  display: flex;
  align-items: center;
`;

export const BadgeWrapper = MuiStyled("div")`
  margin: 0 8px;
  display: flex;
  align-items: center;
`;

export const Body = MuiStyled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Contents = MuiStyled("div")`
  width: 100%;
  font-size: 14px;
`;

export const CollapseButton = styled(IconButton)`
  width: 20px;
  height: 16px;
  padding: 0;

  ${(props: { $collapsed: any }) =>
    props.$collapsed &&
    css`
      transform: rotate(180deg);
    `}
`;
