import { useMemo, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { DataTable } from "~/components/DataTable/DataTable";
import Layout from "./common/Layout";
import { Popper, Box } from "@mui/material";
import { Button } from "~/components/Button";
import { Checkbox } from "~/components/Checkbox";

interface TicketsResponse {
  id: number;
  isFree: boolean;
  lastUsedAt: any;
  payment: any;
  remainingCount: number;
  round: number;
  treatmentCount: number;
  treatmentItem: any;
  useCount: number;
}

const TableWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

export const Content = ({
  data,
  onClose,
  onAddSurgeries,
}: {
  data: Array<TicketsResponse>;
  onClose: any;
  onAddSurgeries: any;
}) => {
  const [checked, setChecked]: any = useState([]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: "checkbox",
          name: "",
          value: (item: TicketsResponse) => (
            <Box sx={{ width: "16px" }}>
              <Checkbox
                checked={checked.includes(item.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setChecked([item.id, ...checked]);
                  } else {
                    setChecked(checked.filter((c: any) => c !== item.id));
                  }
                }}
              />
            </Box>
          ),
        },
        {
          id: "categoryName",
          name: "카테고리",
          value: (item: TicketsResponse) => (
            <Box
              sx={{
                width: "135px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.treatmentItem.category.name}
            </Box>
          ),
          style: {
            textAlign: "left",
          },
        },
        {
          id: "name",
          name: "시/수술명",
          value: (item: TicketsResponse) => (
            <Box sx={{ display: "flex", gap: "3px" }}>
              <Box
                sx={{
                  width: "120px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item.treatmentItem.name}
              </Box>
              {item.isFree && <div className="ico-service" />}
            </Box>
          ),
          style: {
            textAlign: "left",
          },
        },
        {
          id: "treatmentCount",
          name: "총 횟수",
          value: (item: TicketsResponse) => item.treatmentCount,
        },
        {
          id: "remainingCount",
          name: "잔여",
          value: (item: TicketsResponse) => item.remainingCount,
        },
        {
          id: "round",
          name: "회차",
          value: (item: TicketsResponse) => `${item.round + 1}회차`,
        },
      ],
    }),
    [checked]
  );

  const onClickSave = () => {
    onAddSurgeries(
      data.filter((f) => checked.find((check: any) => check === f.id))
    );
    onClose();
  };

  return (
    <Layout title="진행 시/수술 등록" close={onClose}>
      <Box
        sx={{
          width: "100%",
          height: "232px",
          padding: "10px",
        }}
      >
        <TableWrapper>
          <DataTable data={data ?? []} schema={schema} />
        </TableWrapper>
      </Box>
      <Box
        sx={{
          padding: "16px 24px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <Button styled="outline" color="secondary" onClick={onClose}>
          취소
        </Button>
        <Button onClick={() => onClickSave()} disabled={checked.length === 0}>
          등록
        </Button>
      </Box>
    </Layout>
  );
};

const AddRemainingSurgeryModal = ({
  anchorEl,
  data,
  open,
  onClose,
  onAddSurgeries,
}: {
  anchorEl: any;
  data: Array<any>;
  open: any;
  onClose: (value: any) => void;
  onAddSurgeries: any;
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
      placement="left"
      sx={{
        background: "white",
        borderRadius: 1,
        boxShadow: "0px 2px 8px #a1b1ca",
        zIndex: 1300,
        maxHeight: "90%",
        overflow: "auto",
      }}
    >
      <Content data={data} onClose={onClose} onAddSurgeries={onAddSurgeries} />
    </Popper>
  );
};

export default observer(AddRemainingSurgeryModal);
