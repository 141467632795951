import { useContext, useState, useRef, useEffect, useCallback } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { FilterTab } from "./CategoryTab";
import { InfiniteScroll } from "components/InfiniteScroll";
import { StatusInfoBadge } from "components/StatusInfoBadge";
import { AppointmentContext } from "pages/AppointmentPage/AppointmentPage";
import { AppointmentInfoModal } from "modals/AppointmentInfoModal";
import { CustomerInfoModal } from "modals/CustomerInfoModal";
import { getCustomerInfo } from "utils/getCustomerName";
import { useNavigate } from "react-router-dom";
import { useApi } from "providers/api";
import { useClinic } from "providers/clinic";
import { maskName } from "utils/maskString";
import Icon from "components/Icon";
import { Button } from "components/Button";
import {
  Memos,
  Memo,
  MoreMemoButton,
  Label,
  Contents,
  Divider,
  SortColumnWrapper,
  EmptyView,
  CustomerName,
} from "./List.styled";
import { ageText } from "utils/index";
import { format } from "date-fns";
import { useUser } from "providers/user";

const AppointmentRow = ({
  appointment,
  onClickRow,
  onOpenCustomerModal,
  onChangeStatus,
}: {
  appointment?: any;
  onClickRow?: any;
  onOpenCustomerModal?: any;
  onChangeStatus?: any;
}) => {
  const nav = useNavigate();
  const api = useApi();
  const { isMasking } = useClinic();
  const { hasPermission } = useUser();
  const memoRef = useRef();
  const registratioinMemoRef = useRef();
  const [showMoreMemoText, setShowMoreMemoText] = useState(false);
  const [showMoreRegistrationMemoText, setShowMoreRegistrationMemoText] =
    useState(false);

  useEffect(() => {
    if (memoRef.current) {
      const element: any = memoRef.current;
      if (element.scrollHeight > element.offsetHeight + 1) {
        setShowMoreMemoText(true);
      } else {
        setShowMoreMemoText(false);
      }
    }
  }, []);

  useEffect(() => {
    const element: any = registratioinMemoRef.current;
    if (element?.scrollHeight > element?.offsetHeight + 1) {
      setShowMoreRegistrationMemoText(true);
    } else {
      setShowMoreRegistrationMemoText(false);
    }
  }, []);

  const onClickPenchart = async (e: any) => {
    e.stopPropagation();

    const customer = appointment.customer;
    const res = await api.getCustomerDrive(customer.id);
    const payload = await res.json();

    const directoryId = payload.data.root.id;

    nav(
      `/directories/${directoryId}?customerId=${customer.id}&name=${
        customer.name
      }(${customer.sex === "male" ? "M" : "F"}/${ageText(customer)}/${
        customer.birthday ? format(new Date(customer.birthday), "yyMMdd") : "-"
      }) 님의 펜차트&customerId=${customer.id}`
    );
  };

  return (
    <TableRow
      sx={{
        "& td": {
          padding: "10px 0",
          color: "#282828",
          borderBottom: "1px solid #F9FBFF",
        },
      }}
    >
      <TableCell align="center">
        <StatusInfoBadge
          appointment={appointment}
          onClickStatus={(v: any) => {
            onChangeStatus(appointment, v.status);
          }}
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          color: "#0060ff !important",
          textDecoration: "underline",
        }}
        onClick={() => {
          onOpenCustomerModal(appointment.customer);
        }}
      >
        <CustomerName>
          {isMasking
            ? maskName(appointment.customer.name)
            : appointment.customer.name}
        </CustomerName>
        ({getCustomerInfo(appointment.customer)})
      </TableCell>
      <TableCell
        align="center"
        onClick={() => {
          onClickRow(appointment);
        }}
      >
        {appointment.startHour} - {appointment.endHour}
      </TableCell>
      <TableCell
        align="center"
        onClick={() => {
          onClickRow(appointment);
        }}
      >
        {appointment.department.name}
      </TableCell>
      <TableCell
        onClick={() => {
          onClickRow(appointment);
        }}
      >
        <Memos>
          {(appointment.type === "APPOINTMENT" ||
            (appointment.appointment &&
              !appointment.appointment.isDeleted)) && (
            <Memo>
              <Label>예약메모</Label>
              <div>
                <Contents
                  ref={memoRef}
                  dangerouslySetInnerHTML={{
                    __html:
                      appointment.type === "APPOINTMENT"
                        ? appointment.memo || "-"
                        : appointment.appointment?.isDeleted
                        ? "-"
                        : appointment.appointment?.memo || "-",
                  }}
                />
                {showMoreMemoText && <MoreMemoButton>[더 보기]</MoreMemoButton>}
              </div>
            </Memo>
          )}
          {appointment.type === "REGISTRATION" && (
            <>
              {appointment.appointment &&
                !appointment.appointment.isDeleted && <Divider />}
              <Memo>
                <Label>접수메모</Label>
                <div>
                  <Contents
                    ref={registratioinMemoRef}
                    dangerouslySetInnerHTML={{
                      __html: appointment.memo || "-",
                    }}
                  />
                  {showMoreRegistrationMemoText && (
                    <MoreMemoButton>[더 보기]</MoreMemoButton>
                  )}
                </div>
              </Memo>
            </>
          )}
        </Memos>
      </TableCell>
      {hasPermission("PENCHART_CUSTOMER") && (
        <TableCell align="center">
          <Button styled="outline" size="s" onClick={onClickPenchart}>
            차트보기
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export function List() {
  const {
    appointments,
    date,
    updateOrder,
    updatePage,
    departmentId,
    reload,
    onChangeStatus,
  } = useContext(AppointmentContext);
  const { hasPermission } = useUser();
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [customer, setCustomer] = useState(null);

  const openCustomerInfoModal = (customer: any) => {
    setOpenCustomerModal(true);
    setCustomer(customer);
  };

  const openAppointmentInfoModal = (data: any) => {
    setOpenAppointmentModal(true);
    setCustomer(data.customer);
    setAppointment(data);
  };

  const closeCustomerInfoModal = () => {
    setOpenCustomerModal(false);
    setCustomer(null);
  };

  const closeAppointmentInfoModal = () => {
    setOpenAppointmentModal(false);
    setCustomer(null);
    setAppointment(null);
  };

  const onCustomerDelete = () => {
    setOpenCustomerModal(false);
    setCustomer(null);
    reload();
  };

  const onClickRow = useCallback(
    (appointment: any) => {
      openAppointmentInfoModal(appointment);
    },
    [appointment]
  );

  const onOpenCustomerModal = useCallback(
    (customer: any) => {
      openCustomerInfoModal(customer);
    },
    [customer]
  );

  return (
    <>
      <FilterTab />
      <TableContainer>
        <InfiniteScroll action={updatePage} key={`${date}-${departmentId}`}>
          <Table
            stickyHeader
            sx={{
              maxHeight: 220,
              background: "#fff",
            }}
          >
            {!appointments?.length && (
              <caption>
                <EmptyView text="금일 예약내역이 없습니다." />
              </caption>
            )}
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    padding: "13px 0",
                    color: "#282828",
                    fontWeight: 700,
                    borderBottom: "1px solid #F9FBFF",
                  },
                }}
              >
                <TableCell align="center" width="15%">
                  상태
                </TableCell>
                <TableCell align="center" width="15%">
                  고객정보
                </TableCell>
                <TableCell align="center" width="15%">
                  <SortColumnWrapper onClick={() => updateOrder("startHour")}>
                    예약시간
                    <Icon variant="sort" />
                  </SortColumnWrapper>
                </TableCell>
                <TableCell align="center" width="10%">
                  부서
                </TableCell>
                <TableCell align="center" width="35%">
                  메모
                </TableCell>
                {hasPermission("PENCHART_CUSTOMER") && (
                  <TableCell align="center" width="10%">
                    펜차트
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {Boolean(appointments?.length) && (
              <TableBody>
                {appointments.map((row: any) => (
                  <AppointmentRow
                    key={row.id}
                    appointment={row}
                    onClickRow={onClickRow}
                    onOpenCustomerModal={onOpenCustomerModal}
                    onChangeStatus={onChangeStatus}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </InfiniteScroll>
      </TableContainer>
      {openAppointmentModal && (
        <AppointmentInfoModal
          open={true}
          customerData={customer}
          appointmentData={appointment}
          onClose={closeAppointmentInfoModal}
          reload={reload}
        />
      )}
      {openCustomerModal && (
        <CustomerInfoModal
          open={true}
          customerData={customer}
          onClose={closeCustomerInfoModal}
          onCustomerDelete={onCustomerDelete}
          reload={reload}
        />
      )}
    </>
  );
}
