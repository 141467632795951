import {
  TextField,
  Autocomplete,
  Popper as MuiPopper,
  textFieldClasses,
  autocompleteClasses,
  styled,
} from "@mui/material";

const fontSize = "12px";
export const AutoCompleteSelect = styled(Autocomplete)(
  ({ theme }) => `
  .${autocompleteClasses.input} {
    padding: 0 !important;
    font-size: ${fontSize};
    line-height: 1rem;
  }

  .${autocompleteClasses.clearIndicator} {
    margin-right: -10px;
    color: ${theme.palette.grey["700"]};

    svg, path {
      fill: ${theme.palette.grey["700"]};
    }
  }
`
);

export const SearchInput = styled(TextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    & .Mui-disabled {
      background-color: #f3f8ff;
      color: #a1b1ca;
    }
    & .MuiAutocomplete-inputRoot {
      padding: 6px 8px;
    }
  }
`;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }
  .${autocompleteClasses.option} {
    background: none !important;
    &.Mui-focused {
      background-color: #f1f1f1 !important;
    }
  }
  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 4px 10px !important;
    min-height: 100px;
  }
`;
