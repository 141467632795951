import { TabType } from "constant/Tabs";
import Icon from "components/Icon";

export const getName = (tabType: string) => {
  switch (tabType) {
    case TabType.appointment:
      return "예약";
    case TabType.registration:
      return "접수";
    case TabType.consulting:
      return "상담";
    case TabType.treatment:
      return "진료";
    case TabType.surgery:
      return "시/수술";
    case TabType.nurseOperation:
      return "수술간호";
    case TabType.nurseCare:
      return "간호";
    case TabType.skinCare:
      return "피부관리";
    case TabType.payment:
      return "수납";
    case TabType.penchart:
      return "펜차트";
    case TabType.sms:
      return "메시지";
    case TabType.callHistory:
      return "통화";
    case TabType.prescriptions:
      return "처방전";
    default:
      return -1;
  }
};

export const getValue = (cardType: any) => {
  let value = "";

  if (cardType === TabType.consulting) {
    value = "consultings";
  }
  if (cardType === TabType.treatment) {
    value = "treatments";
  }
  if (cardType === TabType.surgery) {
    value = "surgeries";
  }
  if (cardType === TabType.nurseCare) {
    value = "nurseCares";
  }
  if (cardType === TabType.nurseOperation) {
    value = "nurseOperations";
  }
  if (cardType === TabType.skinCare) {
    value = "skincares";
  }
  if (cardType === TabType.payment) {
    value = "payments";
  }
  if (cardType === TabType.appointment) {
    value = "appointment";
  }

  return value;
};

export const getCardType = (item: any) => {
  const {
    consultings,
    treatments,
    surgeries,
    nurseCares,
    nurseOperations,
    skincares,
    payments,
    appointment,
  } = item;

  if (consultings?.length > 0) {
    return "CONSULTING";
  }
  if (treatments?.length > 0) {
    return "TREATMENT";
  }
  if (surgeries?.length > 0) {
    return "SURGERY";
  }
  if (nurseCares?.length > 0) {
    return "NURSE_CARE";
  }
  if (nurseOperations?.length > 0) {
    return "NURSE_OPERATION";
  }
  if (skincares?.length > 0) {
    return "SKINCARE";
  }
  if (payments?.length > 0) {
    return "PAYMENT";
  }
  if (appointment?.length > 0) {
    return "APPOINTMENT";
  }
};

export const getIconByType = (item: any) => {
  const cardType = getCardType(item);
  let iconSelector;

  if (cardType === TabType.consulting) {
    iconSelector = <Icon variant="consulting" />;
  }
  if (cardType === TabType.treatment) {
    iconSelector = <Icon variant="treatment" />;
  }
  if (cardType === TabType.surgery) {
    iconSelector = <Icon variant="operation" />;
  }
  if (cardType === TabType.nurseCare) {
    iconSelector = <Icon variant="operation" />;
  }
  if (cardType === TabType.nurseOperation) {
    iconSelector = <Icon variant="operation" />;
  }
  if (cardType === TabType.skinCare) {
    iconSelector = <Icon variant="skincare" />;
  }
  if (cardType === TabType.payment) {
    iconSelector = <Icon variant="payment" />;
  }
  if (cardType === TabType.appointment) {
    iconSelector = <Icon variant="appointment" />;
  }

  return { icon: iconSelector, type: cardType };
};

export const getCardValue = (item: any) => {
  const {
    consultings,
    treatments,
    surgeries,
    nurseCares,
    nurseOperations,
    skincares,
    payments,
    appointment,
  } = item;

  if (consultings?.length > 0) {
    return consultings;
  }
  if (treatments?.length > 0) {
    return treatments;
  }
  if (surgeries?.length > 0) {
    return surgeries;
  }
  if (nurseCares?.length > 0) {
    return nurseCares;
  }
  if (nurseOperations?.length > 0) {
    return nurseOperations;
  }
  if (skincares?.length > 0) {
    return skincares;
  }
  if (payments?.length > 0) {
    return payments;
  }
  if (appointment?.length > 0) {
    return appointment;
  }
};
