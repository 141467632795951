import { styled } from "@mui/material";
import QuillText from "~/components/quill/QuillText";
import { ManagerNames } from "./ManagerName";
import { TypeWrapper } from "./TypeWrapper";
import { IconWrapper } from "./IconWrapper";
import { Type } from "./Type";
import { SurgeryCategoriesNames } from "./SurgeryCategoriesNames";
import { TabType } from "constant/Tabs";
import { MemoWrapper } from "./MemoWrapper";
import { getName, getIconByType, getValue } from "utils/tabUtils";
import Icon from "components/Icon";
import { getLinkedChannelIconVariant } from "utils/linkedChannelUtil";

const RegistrationCardWrapper = styled("div")`
  margin: 5px 0;
`;

export const RegistrationConnectChart = ({ chart }: { chart: any }) => {
  const { key, value } = chart;
  const { icon } = getIconByType({
    [getValue(key)]: value,
  });

  const AppointmentTypeIcon = ({ item }: { item: any }) => {
    if (item.externalLinkedChannel) {
      const variant = getLinkedChannelIconVariant(item.externalLinkedChannel);
      return <Icon variant={variant} />;
    } else {
      return <Icon variant="appointment" />;
    }
  };

  return (
    value.length > 0 &&
    value.map((v: any) => (
      <RegistrationCardWrapper key={v.id}>
        <TypeWrapper>
          <IconWrapper>
            {key === TabType.appointment ? (
              <AppointmentTypeIcon item={v} />
            ) : (
              icon
            )}
          </IconWrapper>
          <Type>{getName(key)}</Type>
        </TypeWrapper>
        {key === TabType.appointment && v.category !== "NONE" && (
          <>
            <ManagerNames item={v} />
            <div>{v.acquisitionChannel?.name}</div>
            <SurgeryCategoriesNames item={v} />
          </>
        )}
        {(key === TabType.surgery ||
          key === TabType.skinCare ||
          key === TabType.payment) && (
          <SurgeryCategoriesNames
            item={{ category: "NONE", [getValue(key)]: [v] }}
          />
        )}
        {v.memo !== null && v.memo !== "" && (
          <MemoWrapper type={key}>
            <QuillText
              value={v.memo}
              style={{ lineHeight: "1.4", width: "auto" }}
            />
          </MemoWrapper>
        )}
      </RegistrationCardWrapper>
    ))
  );
};
