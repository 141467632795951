import { useCallback, useEffect, useState, useRef } from "react";
import Icon from "components/Icon";
import { SlideModal, Wrapper, Header } from "components/Modal/SlideModal";
import { SearchHistoryList } from "pages/Customer/list/SearchHistoryList";
import { List } from "pages/Customer/list/List";
import { useApi } from "providers/api";
import { useSearchHistory } from "hooks/useSearchHistory";
import { InfiniteScroll } from "components/InfiniteScroll";
import { SearchInput, Body, IconButton } from "./SearchCustomerModal.styled";

const getQuery = (searchQuery: any) => {
  const params: any = {};
  if (
    searchQuery.replace(/-/gi, "").length === 11 ||
    (!isNaN(searchQuery) && searchQuery.length === 4)
  ) {
    if (searchQuery.length === 4) params.phoneNumberLast = searchQuery;
    else params.phoneNumber = searchQuery.replace(/-/gi, "");
  } else {
    params.name = searchQuery;
  }

  return params;
};

type SearchCustomerModalProps = {
  open: boolean;
  onClose: () => void;
  type: "appointment" | "customer";
  placeholder: string;
  showHistory?: boolean;
};

export function SearchCustomerModal({
  open,
  onClose,
  type,
  placeholder,
  showHistory = false,
}: SearchCustomerModalProps) {
  const api = useApi();
  const { addHistory, removeHistory, removeAllHistory, history } =
    useSearchHistory();
  const [searchValue, setSearchValue] = useState("");
  const [data, setData]: any = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [init, setInit] = useState(true);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef.current && inputRef.current?.focus();
  }, [inputRef.current]);

  const loadData = useCallback(
    async ({ searchValue = "", page = 1 }) => {
      setInit(false);
      setSearchValue(searchValue);
      showHistory && addHistory(searchValue);
      const res = await api.searchCustomer({ page, ...getQuery(searchValue) });
      const payload = await res.json();
      if (payload.data) {
        if (page === 1) setData(payload.data);
        else setData((list: any) => [...list, ...payload.data]);
        setPage(page);
        setHasMore(payload.pagination.pages > page);
      }
    },
    [addHistory, api, showHistory]
  );

  const updateNextPage = useCallback(() => {
    if (hasMore) loadData({ searchValue, page: page + 1 });
  }, [hasMore, loadData, searchValue, page]);

  useEffect(() => {
    setSearchValue("");
    setData([]);
    setPage(1);
    setInit(true);
  }, [open]);

  return (
    <SlideModal open={open}>
      <Wrapper style={{ overflow: "hidden" }}>
        <Header>
          <IconButton onClick={onClose}>
            <Icon variant="back_arrow" />
          </IconButton>
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" && loadData({ searchValue, page: 1 })
            }
            ref={inputRef as any}
            placeholder={placeholder}
          />
          <IconButton
            sx={{
              color: searchValue ? "#0060ff" : "#ADAEBC",
            }}
            onClick={() => loadData({ searchValue, page: 1 })}
          >
            <Icon variant="search" />
          </IconButton>
        </Header>
        <Body>
          {init && showHistory ? (
            <SearchHistoryList
              data={history}
              onDelete={removeHistory}
              onDeleteAll={removeAllHistory}
              onSearch={(v: any) => loadData({ searchValue: v, page: 1 })}
            />
          ) : (
            <InfiniteScroll action={updateNextPage}>
              <List
                data={data}
                onClose={onClose}
                type={type}
                onCustomerDelete={() => {
                  loadData({ searchValue, page: 1 });
                }}
              />
            </InfiniteScroll>
          )}
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
