import { differenceInMinutes } from "date-fns";
import { parseTime, toMinutes } from "utils/timeUtil";

export const workTimeCheckByDate = (
  endDate: string,
  startDate: string,
  workEndTime: string
) => {
  const diffMinutes = differenceInMinutes(
    new Date(endDate),
    new Date(startDate)
  );

  const startAt = new Date(startDate);
  const startHour = {
    hours: startAt.getHours(),
    minutes: startAt.getMinutes(),
  };

  return toMinutes(startHour) + diffMinutes > toMinutes(parseTime(workEndTime));
};

export const workTimeCheckByTime = (
  startHour: string,
  time: {
    hours: number;
    minutes: number;
  },
  workEnd: string
) => {
  return (
    toMinutes(parseTime(startHour)) + toMinutes(time) >
    toMinutes(parseTime(workEnd))
  );
};
