import { useContext, useState, useCallback } from "react";
import { AppointmentContext } from "pages/AppointmentPage/AppointmentPage";
import { CreateCustomerModal } from "modals/CreateCustomerModal";
import { SearchCustomerModal } from "modals/SearchCustomerModal";
import { Button } from "components/Button";
import Icon from "components/Icon";
import { Wrapper, ButtonWrapper } from "pages/AppointmentPage/list/Info.styled";
import { StatItems } from "./StatItems";

export function Info() {
  const { count, reload } = useContext(AppointmentContext);
  const [openAppointModal, setOpenAppointmentModal] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  const openCreateCustomerModal = useCallback(() => {
    setOpenCustomerModal(true);
  }, []);

  const closeCreateCustomerModal = useCallback(() => {
    setOpenCustomerModal(false);
  }, []);

  const openCreateAppointmentModal = useCallback(() => {
    setOpenAppointmentModal(true);
  }, []);

  const closeCreateAppointmentModal = useCallback(() => {
    reload();
    setOpenAppointmentModal(false);
  }, []);

  return (
    <Wrapper>
      <StatItems data={count} />
      <ButtonWrapper>
        <Button
          styled="outline"
          color="mix"
          style={{ width: "30px", height: "30px", padding: 0 }}
          onClick={reload}
        >
          <Icon variant="refresh" />
        </Button>
        <Button onClick={openCreateCustomerModal}>+ 고객 등록</Button>
        <Button onClick={openCreateAppointmentModal}>+ 예약 등록</Button>
      </ButtonWrapper>
      {openCustomerModal && (
        <CreateCustomerModal open={true} onClose={closeCreateCustomerModal} />
      )}
      {openAppointModal && (
        <SearchCustomerModal
          open={true}
          onClose={closeCreateAppointmentModal}
          placeholder="예약할 고객을 검색하세요(고객명, 전화번호)"
          type="appointment"
        />
      )}
    </Wrapper>
  );
}
