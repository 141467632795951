import { Box as MuiBox, styled } from "@mui/material";

export const Box = styled(MuiBox)(
  () => `
  &.ql-toolbar.ql-snow {
    border-color: #dee2ec;
    border-bottom: none;
    box-shadow: none;
  }
`
);
