import { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "components/Button";

export function ConfirmModal({
  title = "",
  body,
  cancelText,
  confirmText,
  onClose,
  onConfirm,
  open,
}: {
  title?: string;
  body?: ReactNode;
  cancelText?: any;
  confirmText?: any;
  onClose?: any;
  onConfirm?: any;
  open?: any;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: "380px" } }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent
        sx={{
          fontSize: "14px",
          padding: title ? "20px 24px" : "40px 27px 24px",
        }}
      >
        {body}
      </DialogContent>
      <DialogActions sx={{ padding: "0 24px 16px 24px" }}>
        <Button onClick={onClose} styled="outline" color="mix">
          {cancelText ?? "취소"}
        </Button>
        <Button onClick={onConfirm}>{confirmText ?? "확인"}</Button>
      </DialogActions>
    </Dialog>
  );
}
