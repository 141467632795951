export const validatePassword = (password = "") => {
  if (password.length < 8 || password.length > 20) {
    return "8~20자 영문자, 숫자, 특수기호 조합하여 입력해 주세요.";
  }

  const symbolCheckReg = new RegExp(/[^a-zA-Z0-9!@#$%^*+=-]/);
  if (symbolCheckReg.test(password)) {
    return "특수기호는 !@#$%^*+=-만 입력할 수 있습니다.";
  }

  const reg = new RegExp(
    /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/,
    "g"
  );
  if (!reg.test(password)) {
    return "8~20자 영문자, 숫자, 특수기호 조합하여 입력해 주세요.";
  }
};
