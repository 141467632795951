import { ReactNode, useCallback, useRef } from "react";

type InfiniteScrollProps = {
  action: any;
  children: ReactNode;
};

export function InfiniteScroll({ action, children }: InfiniteScrollProps) {
  const ref = useRef<any>();
  const onScrollCheck = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } = ref.current;
    const max = scrollHeight - clientHeight - 1;
    if (max <= scrollTop) {
      action();
    }
  }, [action, ref]);

  return (
    <div
      ref={ref}
      onScroll={onScrollCheck}
      style={{ height: "100%", overflow: "auto" }}
    >
      {children}
    </div>
  );
}
