import { forwardRef, useContext, useState } from "react";
import { Button } from "components/Button";
import { AppointmentContext } from "pages/AppointmentPage/AppointmentPage";
import DateInput from "components/DateInput";
import { SearchCustomerModal } from "modals/SearchCustomerModal";
import {
  Wrapper,
  LeftSection,
  RightSection,
  DateWrapper,
  DatePickerInput,
  TextInputWrapper,
} from "pages/AppointmentPage/list/Header.styled";
import Icon from "components/Icon";
import { TextInput } from "components/TextInput";

const CustomInput = forwardRef(({ value, ...props }: any, ref) => (
  <DatePickerInput ref={ref} value={value} {...props}>
    {value}
    <Icon variant="chevron_down" />
  </DatePickerInput>
));

export function ControlHeader() {
  const { date, setDate, reload } = useContext(AppointmentContext);
  const [openModal, setOpenModal] = useState(false);

  const openSearchCustomerModal = () => {
    setOpenModal(true);
  };
  const closeSearchCustomerModal = () => {
    reload();
    setOpenModal(false);
  };

  return (
    <>
      <Wrapper>
        <LeftSection>
          <Button
            styled="fill"
            color="orange"
            style={{
              width: "47px",
              height: "30px",
              padding: 0,
            }}
            onClick={() => setDate(new Date())}
          >
            오늘
          </Button>
          <DateWrapper>
            <DateInput
              dateFormat="yyyy.MM.dd(E)"
              value={date}
              onChange={setDate}
              customInput={<CustomInput />}
            />
          </DateWrapper>
        </LeftSection>
        <RightSection>
          <TextInputWrapper>
            <TextInput
              placeholder="고객명, 전화번호를 검색하세요."
              onClick={() => {
                openSearchCustomerModal();
              }}
            />
          </TextInputWrapper>
        </RightSection>
      </Wrapper>
      <SearchCustomerModal
        open={openModal}
        onClose={closeSearchCustomerModal}
        placeholder="고객명, 전화번호로 검색하세요"
        type="customer"
        showHistory
      />
    </>
  );
}
