import { ComponentType } from "react";
import { Menu, styled } from "@mui/material";

export const CustomMenu = styled(Menu)`
  padding: 0;

  .MuiPaper-root {
    min-width: 0;
    min-height: 0;
    width: auto;
    border: 1px solid #dee2ec;
    box-shadow: none;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
  }

  .MuiList-root {
    padding: 0;
  }
` as ComponentType<any>;
