import { useRef, useMemo, useState, LegacyRef } from "react";
import styled, { css } from "styled-components";
import { Checkbox } from "../Checkbox/Checkbox";
import { ThStyle, ThCell } from "./DataTableUtils.styled";
import { CollapseButton } from "./CollapseButton";
import Icon from "components/Icon";
import update from "immutability-helper";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/material";
import { Tooltip } from "../Tooltip";

const ResizeButton = styled.div<{ active?: any }>`
  height: 100%;
  display: flex;
  position: absolute;
  cursor: col-resize;
  width: 8px;
  right: -2px;
  top: 0;
  z-index: 1;

  &:hover {
    border-right: 4px solid transparent;
    border-color: ${({ theme }) => theme.color.primary.unoblue};
  }
  ${({ active }) =>
    active &&
    css`
      border-right: 4px solid transparent;
      border-color: ${({ theme }) => theme.color.primary.unoblue};
    `}
`;

const CollapseButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const SortIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme, color }) =>
        color === "blue" && theme.color.primary.unoblue} !important;
    }
  }
`;

const Th = styled.th<{ style?: any; $grow?: any; $resizable?: boolean }>`
  ${({ style }) => style && style}
  ${ThStyle}
`;

const checkDuplicate = (array: any[] = []) => {
  const uniqueValues = new Set(array.map((v: any) => v.id));
  if (uniqueValues.size < array.length) {
    throw new Error("duplicate id in the headers.");
  }
};

const getNestedHeaders = (headers: any[] = [], column: any) => {
  const nested = [
    {
      id: column.id,
      name: column.name,
    },
  ];

  let parent: any = headers.find((h: any) => h.id === column.parentHeader);
  while (parent) {
    nested.push(parent);
    parent = headers.find((h: any) => h.id === parent.parentHeader);
  }

  return nested.reverse();
};

type DataTableHeadProps = {
  resizable?: boolean;
  tbodyRef?: any;
  columnsRef?: any;
  setColumnsRef?: (value: any) => void;
  checkedColumnName?: string;
  checkedAll?: boolean;
  checked?: boolean;
  onCheckAll: (value: any) => void;
  collapsed?: boolean;
  onCollapseAll?: (value: any) => void;
  columns: any[];
  headers?: any[];
  sorts?: any[];
  onChangeSorts?: (value: any) => void;
  styleType?: "chart";
};

export const DataTableHead = ({
  resizable,
  tbodyRef,
  columnsRef = [],
  setColumnsRef,
  checkedAll,
  checked,
  onCheckAll,
  collapsed,
  onCollapseAll,
  columns,
  headers,
  sorts,
  onChangeSorts,
}: DataTableHeadProps) => {
  const theadRef: LegacyRef<HTMLTableSectionElement> | undefined = useRef(null);
  const [isDropDown, setIsDropDown] = useState(false);

  const nestedHeaders = useMemo(
    () =>
      columns
        .map((col) => ({
          id: col.id,
          headers: getNestedHeaders(headers, col),
        }))
        .reduce((p: any, c) => {
          p[c.id] = c.headers;
          return p;
        }, {}),
    [columns, headers]
  );

  const maxDepth = useMemo(
    () => Math.max(...columns.map((col) => nestedHeaders[col.id].length)),
    [columns, nestedHeaders]
  );

  const rows = useMemo(
    () =>
      new Array(maxDepth).fill(undefined).map((_, i) =>
        columns.map((col) => {
          const columnHeaders = nestedHeaders[col.id];
          if (columnHeaders.length <= i) return null;

          const isLeaf = i === columnHeaders.length - 1;

          return {
            ...col,
            id: columnHeaders[i].id,
            name: columnHeaders[i].name,
            rowSpan: isLeaf ? maxDepth - i : 1,
            isLeaf,
          };
        })
      ),
    [maxDepth, columns, nestedHeaders]
  );

  // 첫번째 array value와 가까운 중복값 갯수 구하기
  // [1,1,2,2,3,3,1,1,1,1] -> 2
  const calcColspan = (arr: { name: string }[]) => {
    let num = 1;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === arr[i + 1]?.name) {
        num += 1;
      } else {
        break;
      }
    }
    return num;
  };

  checkDuplicate(headers);

  const resizer = (
    e: any,
    ref: any,
    col: any,
    colIndex: number,
    maxValue: string,
    minValue: string
  ) => {
    const ResizeRef = ref.ref;

    if (ResizeRef.current === null) {
      endResizeDragging(col);
      setIsDropDown(false);
      return;
    }

    window.addEventListener("mousemove", mousemove);
    window.addEventListener("mouseup", mouseup);

    const prevX = e.screenX;
    const resizePanel = ResizeRef.current.getBoundingClientRect();

    function mousemove(e: Event & { screenX: number }) {
      const xDelta = prevX - e.screenX;
      const _minWidth = Number(minValue?.replace(/[^0-9]/g, ""));
      const minWidth = isNaN(_minWidth)
        ? ref.headerRef.current.offsetWidth +
          parseInt(
            window.getComputedStyle(ref.headerRef.current.parentElement, null)
              .paddingLeft
          ) +
          parseInt(
            window.getComputedStyle(ref.headerRef.current.parentElement, null)
              .paddingRight
          )
        : _minWidth;

      const _maxWidth = Number(maxValue?.replace(/[^0-9]/g, ""));
      const updateWidth = Math.min(
        Math.max(minWidth, resizePanel.width - xDelta),
        isNaN(_maxWidth) ? 320 : _maxWidth
      );

      ref.headerRef.current.parentElement.style.width = `${updateWidth}px`;

      const trList = tbodyRef.current.children;
      for (let i = 0; i < trList.length; i++) {
        let td = trList[i].children[colIndex + (checked !== undefined ? 1 : 0)];
        if (trList[i].children.length < columns.length) {
          const tdIndex = columns
            .filter((f) => f.rowSpan == undefined)
            .findIndex((f) => f.id === col.id);
          td = trList[i].children[tdIndex];
        }
        if (td) {
          const padding = 28;
          td.children[0].style.width = updateWidth - padding + "px";
          td.children[0].style.overflow = "hidden";
        }
      }
    }

    function mouseup() {
      endResizeDragging(col);
      setIsDropDown(false);
      window.removeEventListener("mousemove", mousemove);
      window.removeEventListener("mouseup", mouseup);
    }
  };

  const startResizeDragging = (col: any) => {
    const refIndex = columnsRef.findIndex((f: any) => f.text === col.id);
    if (!columnsRef[refIndex].active) {
      setColumnsRef &&
        setColumnsRef((col: any) => {
          return update(col, {
            [refIndex]: { $merge: { active: true } },
          });
        });
    }
  };

  const endResizeDragging = (col: any) => {
    const refIndex = columnsRef.findIndex((f: any) => f.text === col.id);
    if (columnsRef[refIndex].active) {
      setColumnsRef &&
        setColumnsRef((col: any) => {
          return update(col, {
            [refIndex]: { $merge: { active: false } },
          });
        });
    }
  };

  return (
    <>
      <thead ref={theadRef}>
        {rows.map((row: any, rowIndex: number) => (
          <tr key={rowIndex}>
            {rowIndex === 0 && checked !== undefined && (
              <Th
                rowSpan={maxDepth}
                $grow={row.grow}
                style={{
                  padding: "6px 8px !important",
                  width: "32px !important",
                  "> span": { padding: "0 !important" },
                }}
              >
                <Checkbox
                  disabled={checkedAll}
                  checked={checked}
                  onChange={(e) => onCheckAll(e.target.checked)}
                />
              </Th>
            )}
            {rowIndex === 0 && collapsed !== undefined && (
              <Th rowSpan={maxDepth} $grow={row.grow}>
                <CollapseButtonWrapper
                  onClick={() => onCollapseAll && onCollapseAll(!collapsed)}
                >
                  {collapsed ? "접기" : "펼치기"}
                  <CollapseButton collapsed={!collapsed} />
                </CollapseButtonWrapper>
              </Th>
            )}
            {row.map(
              (col: any, colIndex: number) =>
                // 중복값은 그리지 않음
                col &&
                col.id !== row[colIndex - 1]?.id && (
                  <Th
                    key={colIndex}
                    colSpan={calcColspan(row.slice(colIndex))}
                    rowSpan={col.rowSpan}
                    $grow={col.grow}
                    $resizable={resizable}
                    style={col.headerStyle}
                  >
                    <span
                      style={{ display: "inline-block" }}
                      ref={(el) => {
                        const resizeObj = columnsRef.find(
                          (f: any) => f.text === col.id
                        );
                        if (resizeObj) {
                          resizeObj.headerRef.current = el;
                        }
                      }}
                    >
                      <ThCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Box>{col.name}</Box>
                          {col.tooltip && (
                            <Tooltip title={col.tooltip}>
                              <HelpOutlineIcon sx={{ fontSize: 16 }} />
                            </Tooltip>
                          )}
                          {col.sortable && (
                            <SortIconWrapper
                              color={
                                sorts && sorts.find((f) => f.id === col.id)
                                  ? "blue"
                                  : "default"
                              }
                              onClick={() => {
                                onChangeSorts &&
                                  onChangeSorts(
                                    sorts && sorts.find((f) => f.id === col.id)
                                      ? sorts.find((f) => f.id === col.id)
                                      : { id: col.id, value: "desc" }
                                  );
                              }}
                            >
                              <Icon variant="sort_arrow" />
                            </SortIconWrapper>
                          )}
                        </Box>
                      </ThCell>
                      {resizable && col.isLeaf && (
                        <ResizeButton
                          active={
                            columnsRef.find((f: any) => f.text === col.id)
                              .active
                          }
                          onMouseEnter={(e) => {
                            e.stopPropagation();
                            if (!isDropDown) {
                              startResizeDragging(col);
                            }
                          }}
                          onMouseLeave={(e) => {
                            e.stopPropagation();
                            if (!isDropDown) {
                              endResizeDragging(col);
                            }
                          }}
                          onMouseDown={(e) => {
                            setIsDropDown(true);
                            e.stopPropagation();
                            startResizeDragging(col);
                            resizer(
                              e,
                              columnsRef.find((f: any) => f.text === col.id),
                              col,
                              colIndex,
                              col?.headerStyle?.maxWidth,
                              col?.headerStyle?.minWidth
                            );
                          }}
                        />
                      )}
                    </span>
                  </Th>
                )
            )}
          </tr>
        ))}
      </thead>
    </>
  );
};
