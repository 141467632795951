import {
  Box as MuiBox,
  styled,
  Avatar as MuiAvatar,
  IconButton as MuiIconButton,
} from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  () => `
  width: 50px;
  height: 50px;
  margin: 5px 10px;
`
);

export const Box = styled(MuiBox)`
  &.customer-name {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &.header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 68px;
    font-weight: 500;
  }
`;

export const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  background: ${theme.palette.bluegrey["500"]};
  svg {
    width: 25px;
    fill: #A4BCF2;
  }
  margin-right: 10px;
  margin-left: 8px;
  
  &.female {
    background: rgb(253, 238, 238);
    svg {
      fill: rgb(235, 87, 87);
      fill-opacity: 0.2;
    }
  }
`
);
