import { ApiResource } from "./apiResource";
import axios from "axios";
import {
  ApiEmptyResponse,
  ApiDataResponse,
  PaginationResponse,
} from "../apiClient";

type TemplateListItem = {
  createdAt: string;
  editable: boolean;
  expirationDay: number;
  id: number;
  questionCount: number;
  title: string;
};

type TemplateItem = {
  contents: string;
  createdAt: string;
  editable: boolean;
  expirationDay: number;
  id: number;
  questionCount: number;
  resultInfo: string;
  title: string;
  topInfo: string;
  registeredDate?: string;
  status: "temporary" | "complete";
};

type CreateTemplateBodyParams = {
  contents?: string;
  expirationDay: number;
  resultInfo: string;
  title: string;
  topInfo?: string;
};

type SurveyResultsParams = {
  registeredDateStart: string;
  registeredDateEnd: string;
  page: number;
  limit: number;
  orderBy?: string;
  titleIds?: (number | null)[];
  status?: string;
};

type SurveyResultListItem = {
  completeCount: number;
  id: number;
  temporaryCount: number;
  title: string;
  totalCount: number;
};

export type SurveyListParams = {
  page?: number;
  limit?: number;
  orderBy?: string;
  customerId: number;
};

type SurveyListItem = {
  createdAt: string;
  creatorName: string;
  id: number;
  status: string;
  title: string;
};

type Status = "complete" | "temporary";

type CodeSurveyRequestParams = {
  contents: string;
  registeredDate: string;
  status: Status;
};

type CodeSurveyResponseData = {
  contents: string;
  id: number;
  registeredDate: string;
  resultInfo: string;
  status: Status;
  title: string;
  topInfo: string;
  isExpired: boolean;
};

type CreateSurveyRequestParams = {
  contents: string;
  customerId: number;
  registeredDate: string;
  status: Status;
  templateId: number;
};

type UpdateSurveyRequestParams = {
  contents: string;
  registeredDate: string;
  status: Status;
};

export class SurveyApi extends ApiResource {
  async list(params: SurveyListParams) {
    return axios.get<PaginationResponse<SurveyListItem[]>>(
      `${this.endpoint}/questionnaires`,
      {
        params,
        headers: this.buildHeaders(),
      }
    );
  }

  async delete(id: number) {
    return axios.delete<ApiEmptyResponse>(
      `${this.endpoint}/questionnaire/${id}`,
      {
        headers: this.buildHeaders(),
      }
    );
  }

  async deleteBatch(ids: number[]) {
    return axios.delete<ApiEmptyResponse>(
      `${this.endpoint}/batch/questionnaires`,
      {
        data: { ids },
        headers: this.buildHeaders(),
      }
    );
  }

  async templateList() {
    return axios.get<PaginationResponse<TemplateListItem[]>>(
      `${this.endpoint}/questionnaire/templates`,
      {
        headers: this.buildHeaders(),
      }
    );
  }

  async templateDetail(id: number) {
    return axios.get<ApiDataResponse<TemplateItem>>(
      `${this.endpoint}/questionnaire/template/${id}`,
      {
        headers: this.buildHeaders(),
      }
    );
  }

  async getSurvey(id: number) {
    return axios.get<ApiDataResponse<TemplateItem>>(
      `${this.endpoint}/questionnaire/${id}`,
      {
        headers: this.buildHeaders(),
      }
    );
  }

  async createSurvey(params: CreateSurveyRequestParams) {
    return axios.post(`${this.endpoint}/questionnaires`, params, {
      headers: this.buildHeaders(),
    });
  }

  async updateSurvey(id: number, params: UpdateSurveyRequestParams) {
    return axios.put(`${this.endpoint}/questionnaire/${id}`, params, {
      headers: this.buildHeaders(),
    });
  }

  async getSurveyByCode(key?: string) {
    if (key) {
      return axios.get<ApiDataResponse<CodeSurveyResponseData>>(
        `${this.endpoint}/questionnaire/code/${key}`,
        {
          headers: this.buildHeaders(),
        }
      );
    }
  }

  async updateSurveyByCode(key: string, params: CodeSurveyRequestParams) {
    return axios.put<ApiDataResponse<CodeSurveyResponseData>>(
      `${this.endpoint}/questionnaire/code/${key}`,
      params,
      {
        headers: this.buildHeaders(),
      }
    );
  }

  async getSurveyResults(params: SurveyResultsParams) {
    return axios.get<PaginationResponse<SurveyResultListItem[]>>(
      `${this.endpoint}/questionnaires/results`,
      { params, headers: this.buildHeaders() }
    );
  }

  async createTemplate(params: CreateTemplateBodyParams) {
    return axios.post(`${this.endpoint}/questionnaire/templates`, params, {
      headers: this.buildHeaders(),
    });
  }

  async updateTemplate(id: number, params: CreateTemplateBodyParams) {
    return axios.put(`${this.endpoint}/questionnaire/template/${id}`, params, {
      headers: this.buildHeaders(),
    });
  }

  async deleteTemplate(id: number) {
    return axios.delete<ApiEmptyResponse>(
      `${this.endpoint}/questionnaire/template/${id}`,
      {
        headers: this.buildHeaders(),
      }
    );
  }
}
