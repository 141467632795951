import { makeAutoObservable } from "mobx";

class Appointments {
  public departments: any[] = [];
  public departmentId: null | number = null;

  constructor() {
    makeAutoObservable(this);
  }

  setDepartmentId = (departmentId: null | number) => {
    this.departmentId = departmentId;
  };

  setDepartments = (departments: any[]) => {
    this.departments = departments;
  };
}

export default new Appointments();
