import { styled } from "@mui/material";

export const Label = styled("span")`
  display: inline-flex;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d2d2d;
  min-width: 15%;
`;

export const Value = styled("span")`
  display: inline-flex;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d2d2d;
  min-width: 35%;
`;

export const Wrapper = styled("div")`
  font-size: 14px;

  &.list-wrapper {
    overflow: auto;
    height: 100%;
  }

  & > div {
    min-height: 39px;
    align-items: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  > *:nth-of-type(odd) {
    background: #f9fbff;
  }
  > *:nth-of-type(even) {
    background: #fff;
  }
  > * {
    display: flex;
    column-gap: 10px;
    padding: 10px 30px;
  }
`;
