import { observer } from "mobx-react";
import { Popper } from "@mui/material";
import { Content } from "~/modals/AddRemainingSurgeryModal";

const AddRemainingSurgeryModal = ({
  anchorEl,
  data,
  open,
  onClose,
  onAddSurgeries,
}: {
  anchorEl: any;
  data: Array<any>;
  open: any;
  onClose: any;
  onAddSurgeries: any;
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ]}
      placement="auto"
      sx={{
        background: "white",
        borderRadius: 1,
        boxShadow: "0px 2px 8px #a1b1ca",
        zIndex: 1300,
        maxHeight: "90%",
        minWidth: "640px",
        overflow: "auto",
        position: "fixed !important",
        top: "50% !important", // 화면 상단에서 중앙으로 이동
        left: "50% !important", // 화면 좌측에서 중앙으로 이동
        transform: "translate(-50%, -50%) !important", // 화면 중앙에 위치
      }}
    >
      <Content data={data} onClose={onClose} onAddSurgeries={onAddSurgeries} />
    </Popper>
  );
};

export default observer(AddRemainingSurgeryModal);
