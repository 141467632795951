import { styled } from "@mui/material/styles";

export const TitleContentsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;

export const TitleContentsOverflowWrapper = styled(TitleContentsWrapper)`
  max-height: 82px;
  overflow: hidden;
`;

export const Image = styled("img")`
  width: 64px;
  height: 64px;
  display: block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
`;

export const ImageWrapper = styled("div")`
  display: flex;
  justify-content: center;
`;
