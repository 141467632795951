import { DriveView, DriveViewProvider } from "components/DriveView/DriveView";
import { useApi } from "providers/api";
import { useUser } from "providers/user";
import { useCallback, useEffect, useState } from "react";
import { NavPage } from "./Nav/NavPage";
import { useNavigate } from "react-router-dom";

export function PenchartPage() {
  const [directoryId, setDirectoryId] = useState(null);
  const api = useApi();
  const nav = useNavigate();
  const { user } = useUser();

  const loadDrive = useCallback(
    async (clinicId: string) => {
      const res = await api.getClinicDrive(clinicId);
      const payload = await res.json();
      setDirectoryId(payload.data.root.id);
    },
    [api]
  );

  useEffect(() => {
    loadDrive(user.clinic.id);
  }, [loadDrive, user]);

  const onEnterDirectory = (directoryId: string) => {
    nav({
      pathname: `/directories/${directoryId}`,
      search: "penchartDrive=true",
    });
  };

  const onEnterFile = (fileId: string, siblingIds: string[]) => {
    nav(`/files/${fileId}/edit?files=${siblingIds.join(",")}`);
  };

  return (
    <NavPage title="차트샘플함">
      {directoryId && (
        <DriveViewProvider directoryId={directoryId} penchartDrive={true}>
          <DriveView
            onEnterDirectory={onEnterDirectory}
            onEnterFile={onEnterFile}
          />
        </DriveViewProvider>
      )}
    </NavPage>
  );
}
