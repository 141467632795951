import { ChangePasswordPage } from "pages/ChangePassword/ChangePasswordPage";
import { useAuth } from "providers/auth";
import { useUser } from "providers/user";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { StatusSettingsProvider } from "providers/StatusSettingsProvider";

export const AuthedLayout = () => {
  const { authed }: any = useAuth();
  const { user } = useUser();
  const location = useLocation();

  if (!authed) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!user) {
    return null;
  }

  if (!user.passwordChanged) {
    return <ChangePasswordPage />;
  }

  if (
    user.type === "registration" &&
    location.pathname !== "/simple-registrations"
  ) {
    return <Navigate to="/simple-registrations" />;
  } else if (
    user.type !== "registration" &&
    location.pathname === "/simple-registrations"
  ) {
    return <Navigate to="/" />;
  }

  return (
    <StatusSettingsProvider>
      <Outlet />
    </StatusSettingsProvider>
  );
};
