import { ConfirmModal } from "components/Modal/ConfirmModal";

export const WorkTimeConfirmModal = ({
  close,
}: {
  close: (flag: boolean) => void;
}) => {
  return (
    <ConfirmModal
      body={<p>진료 시간 외 예약입니다. 접수 처리를 하시겠습니까?</p>}
      confirmText="확인"
      onClose={() => close(false)}
      onConfirm={() => close(true)}
      open={true}
    />
  );
};
