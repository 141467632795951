import { Box as MuiBox, styled } from "@mui/material";

export const Box = styled(MuiBox)`
  &.icon-wrapper {
    svg {
      width: 7vw;
      height: 4vw;
    }
  }

  &.bottom-wrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    padding: 5%;
    overflow: hidden;

    .display-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .display-phone-number {
        width: 100%;
        font-size: 7vw;
        font-weight: bold;
        white-space: nowrap;

        span.place-holder {
          color: #f1f1f1;
        }
        span.display-text {
          color: black;
        }
      }
    }

    .dial-container {
      width: 100%;
      height: 100%;
      display: grid;
      grid-gap: 2%;
      padding: 10% 15%;

      .dial-column {
        display: grid;
        grid-gap: 2%;
        grid-template-columns: repeat(3, 1fr);

        .dial-button {
          font-size: 6vw;
        }
        .next-button {
          font-size: 40px;
        }
        .next-button.able-button {
          color: white;
          background: #0060ff;
        }
        .next-button.disable-button {
          background: #bbbbbb;
        }
      }
    }
  }
`;
