import { useParams } from "react-router-dom";

export const keyed = (Comp, keys) => {
  return ({ ...props }) => {
    const params = useParams();

    const key = keys.map((k) => params[k]).join("-");

    return (
      <>
        <Comp key={key} {...props} />
      </>
    );
  };
};
