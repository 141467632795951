import {
  ClickAwayListener,
  InputAdornment as MuiInputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef, useRef, useState } from "react";
import { useElementSize } from "../../hooks/useElementSize";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { Paper, StyledTextField, IconButton } from "./TimeDurationField.styled";

const InputAdornment = styled(MuiInputAdornment)(
  () => `
  
  .MuiTypography-root {
    font-size: 14px;
  }
`
);

type TimeDurationFieldProps = {
  value: any;
  onChange: (value: any) => void;
  options: any;
  fullWidth?: any;
  sx?: any;
};

export const TimeDurationField = forwardRef(
  (
    { value, onChange, options, fullWidth, sx }: TimeDurationFieldProps,
    ref
  ) => {
    const [uncontrolledValue, setUncontrolledValue] = useState({
      hours: value?.hours ?? 0,
      minutes: value?.minutes ?? 0,
    });

    const [openOptions, setOpenOptions] = useState(false);
    const wrapperRef: any = useRef(null);
    const hourFieldRef: any = useRef(null);
    const minuteFieldRef: any = useRef(null);

    const [focused, setFocused] = useState(null);

    const rect: any = useElementSize(wrapperRef);

    const controlledValue = value ?? uncontrolledValue;
    const controlledOnChange = onChange ?? setUncontrolledValue;

    const onChangeHour = (e: any) => {
      if (!/^\d*$/.test(e.target.value)) {
        return;
      }

      setUncontrolledValue({
        ...controlledValue,
        hours: Math.min(24, Number(e.target.value)),
      });

      controlledOnChange({
        ...controlledValue,
        hours: Math.min(24, Number(e.target.value)),
      });
    };

    const onChangeMinute = (e: any) => {
      if (!/^\d*$/.test(e.target.value)) {
        return;
      }

      setUncontrolledValue({
        ...controlledValue,
        minutes: Math.min(60, Number(e.target.value)),
      });

      controlledOnChange({
        ...controlledValue,
        minutes: Math.min(60, Number(e.target.value)),
      });
    };

    const onClickWrapper = (e: any) => {
      if (e.target === wrapperRef.current) {
        minuteFieldRef.current?.focus();
      }

      setOpenOptions(true);
    };

    const onClickOption = (o: any) => {
      onChange(o);
      setOpenOptions(false);
    };

    const onClickAway = (e: any) => {
      if (wrapperRef.current?.contains(e.target)) {
        return;
      }

      setOpenOptions(false);
    };

    const onFocusHourField = () => {
      setFocused(hourFieldRef.current);
    };

    const onFocusMinuteField = () => {
      setFocused(minuteFieldRef.current);
    };

    const onBlurHourField = () => {
      setFocused(null);
    };

    const onBlurMinuteField = () => {
      setFocused(null);
    };

    const onClickToggleOptions = (e: any) => {
      e.stopPropagation();
      setOpenOptions((v) => !v);
    };

    const active = Boolean(focused) || openOptions;

    return (
      <>
        <Box
          ref={(node) => {
            wrapperRef.current = node;
            if (typeof ref === "function") {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          sx={{
            display: "flex",
            border: "1px solid",
            borderColor: active ? "primary.main" : "grey.main",
            borderRadius: 1,
            alignItems: "center",
            height: 34,
            width: fullWidth ? "100%" : undefined,
            ...sx,
          }}
          onClick={onClickWrapper}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridAutoColumns: "minmax(0, 1fr)",
                gridAutoFlow: "column",
              }}
            >
              <StyledTextField
                inputRef={hourFieldRef}
                value={controlledValue.hours}
                onChange={onChangeHour}
                onFocus={onFocusHourField}
                onBlur={onBlurHourField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => hourFieldRef.current?.focus()}
                      position="end"
                    >
                      시간
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <StyledTextField
                inputRef={minuteFieldRef}
                value={controlledValue.minutes}
                onChange={onChangeMinute}
                onFocus={onFocusMinuteField}
                onBlur={onBlurMinuteField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => minuteFieldRef.current?.focus()}
                      position="end"
                    >
                      분
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Box>
          </Box>
          <IconButton onClick={onClickToggleOptions}>
            {openOptions ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </Box>
        <Popper
          open={openOptions}
          anchorEl={() => wrapperRef.current}
          sx={{ zIndex: 1300 }}
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <Paper
              sx={{
                width: rect?.width,
                maxHeight: 240,
              }}
            >
              <MenuList>
                {options.map((o: any) => (
                  <MenuItem
                    key={`${o.hours}-${o.minutes}`}
                    onClick={() => onClickOption(o)}
                  >
                    <ListItemText
                      sx={{
                        ".MuiTypography-root": {
                          fontSize: 12,
                        },
                      }}
                      primary={`${o.hours}시간 ${o.minutes}분`}
                    />
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
);
