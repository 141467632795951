import { styled, Box } from "@mui/material";
import searchIcon from "assets/icons/ic-search-bold.svg";

export const LeftSection = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TextInputWrapper = styled("div")`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: 8px;
  width: 225px;
  min-width: 235px;
  height: 30px;
  overflow: hidden;

  &.MuiOutlinedInput-root:focus {
    border-color: #dee2ec !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #dee2ec !important;
  }

  input {
    padding-left: 35px !important;
    background-image: url(${searchIcon});
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left 8px center;
    width: 100%;
    &::placeholder {
      color: #a1b1ca !important;
      font-weight: 500;
    }
  }

  &&& button {
    min-width: 70px;
    height: 100%;
  }
`;

export const DriveItemGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;
