import styled from "@emotion/styled";
import { IconButton, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SlideModal } from "components/Modal/SlideModal";

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  color: #292929;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;
  font-weight: 500;
`;

const Body = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  padding: 3%;
  height: 100%;
  overflow: hidden;
`;

const Footer = styled.div`
  flex: 2;
  margin-bottom: 1%;
  padding: 0 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Text = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  text-align: center;

  th {
    font-weight: 500;
    border: 1px solid #ccc;
    padding: 5px;
  }

  td {
    border: 1px solid #ccc;
    padding: 5px;
  }
`;

const iconButtonStyle = { width: "50px", height: "50px", margin: "5px 10px" };

export function TermsModal({ open, onClose }) {
  return (
    <>
      <SlideModal open={open}>
        <Container>
          <Header>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "3vw",
                width: "100%",
                height: "68px",
              }}
            >
              <Box sx={{ marginLeft: "70px" }}>전문보기</Box>
            </Box>
            <Box sx={{ marginLeft: "auto", height: "100%" }}>
              <IconButton
                sx={{
                  ...iconButtonStyle,
                  color: "#ADAEBC",
                }}
                onClick={() => onClose()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Header>
          <Body>
            <Box sx={{ overflow: "auto" }}>
              <Title>[필수] 개인정보 수집 및 활용 동의</Title>
              <br />
              <Text>
                본원은 귀하의 소중한 개인정보(및 민감정보)는 의료법 및
                개인정보보호법의 관련규정에 의하여 진료 및 진료지원 등 아래의
                목적으로 수집 및 이용됩니다.
              </Text>
              <br />
              <Title>1. 개인 정보의 수집 및 이용 목적</Title>
              <Text>
                - 진료/건강검진 예약, 조회 및 진료를 위한 본인확인절차
              </Text>
              <Text>
                - 진단 및 치료를 위한 서비스(협의진료에 필요한 개인정보 및
                진료정보공유)
              </Text>
              <Text>- 진료비 청구, 수납, 환불 등의 원무 서비스</Text>
              <Text>
                - 진료비계산서, 내역서, 제증명서 발송 및 약품/건강검진 물품 및
                결과발송
              </Text>
              <Text>- 온라인/오프라인 검사 수탁, 외부검사 의뢰</Text>
              <Text>- 민원/고충 처리 등을 돕기 위한 의사소통의 경로 확보</Text>
              <Text>
                - 의료의 질관리, 의료기관 인증평가, 병원운영을 위한 법적, 행정적
                대응 및 조치
              </Text>
              <Text>- 교육, 연구에 필요한 최소한의 분석 자료</Text>
              <Text>
                - 가족 등에게 병의 증세, 환자상태 설명, 병실조회 및 면회
              </Text>
              <br />
              <Title>2. 개인정보 수집항목</Title>
              <Text>
                - 이름, 주민등록번호, 주소 등 의료법 제22조에 따라 의무기록에
                명시되는 항목
              </Text>
              <Text>- 전화번호, E-mail 등</Text>
              <Text>- 문진표 및 진료신청서 내 기재항목</Text>
              <br />
              <Title>3. 개인정보보유 . 이용기간</Title>
              <Text>
                - 의료법 및 기타 관련 법령기준에 따라 보유하며 환자의 건강을
                위한 의료서비스에 필요성이 있는 경우에는 영구 보관 할 수
                있습니다.
              </Text>
              <br />
              <Title>4. 고유 식별정보의 수집∙이용</Title>
              <Text>
                상기 항목을 포함한 개인정보 수집 ∙ 이용을 위하여 아래와 같이
                고유 식별정보를 수집 ∙ 이용할 수 있습니다. - 고유 식별정보 :
                외국인등록번호, 여권번호, 운전면허번호
              </Text>
              <br />
              <Title>5. 민감정보(건강정보)의 수집∙이용</Title>
              <Text>
                상기 항목을 포함한 진료서비스를 위하여 아래와 같이 민감
                정보(건강정보)를 수집 ∙ 이용할 수 있습니다. - - 병력 및 가족력,
                종교 등 의료진이 필요하다고 판단되는 개인 건강정보 등
              </Text>
              <br />
              <Title>6. 개인정보의 제3자 제공</Title>
              <Text>
                본원은 의료법 제21조 및 기타 다른 법률에서 개인정보의 제공
                규정이 있는 경우 이외에 개인정보를 제공하는 경우에는 별도 동의를
                받고 있습니다.
              </Text>
              <br />
              <Title>7. 개인정보처리 위탁</Title>
              <Text>
                <Table>
                  <thead>
                    <th>업체명</th>
                    <th>위탁 업무 내용</th>
                    <th>보유기간</th>
                  </thead>
                  <tbody>
                    <td>(주)케어랩스</td>
                    <td>원내 고객관리 시스템 유지보수</td>
                    <td>위탁계약 종료시까지</td>
                  </tbody>
                </Table>
              </Text>
              <br />
              <Text>
                개인정보수집이용 제공 동의서 작성 후에도 의료법 및
                개인정보보호법 등 관계법령에 특히 규정 된 경우를 제외 하고는
                동의자가 원하는 경우에는 동의 여부를 언제든지 변경 또는 철회 할
                수 있음을 알려 드립니다. [개인정보 보호법]에 의거 위와 같이
                개인정보 수집 및 이용에 동의합니다.
              </Text>
            </Box>
          </Body>
          <Footer>
            <Box>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: "10vh",
                  fontSize: "3vw",
                }}
                onClick={onClose}
              >
                확인
              </Button>
            </Box>
          </Footer>
        </Container>
      </SlideModal>
    </>
  );
}
