import {
  styled,
  css,
  Box as MuiBox,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";

export const Box = styled(MuiBox)(
  () => css`
    &.spinner-wrapper {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  `
);

export const CircularProgress = styled(MuiCircularProgress)(() => css``);
