import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey[700]};

  button {
    width: 24px;
    height: 24px;
    color: #202020;
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const Body = styled.div`
  font-size: 12px;
  white-space: pre-wrap;
`;
