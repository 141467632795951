import { Button as MuiButton, Dialog, Paper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ServiceWorkerConfig } from "serviceWorkerConfig";
import { FirebaseClient } from "firebaseClient";
import { extractSemanticVersions } from "utils/versionUtil";
import { Box, Stack } from "./Updater.styled";
import { Button } from "components/Button";

const firebaseClient = new FirebaseClient();

const updateWorker = ServiceWorkerConfig.onUpdate;

type OptionalUpdateDialogProps = {
  nextVersion: any;
  onUpdate: () => void;
};

const OptionalUpdateDialog = ({ onUpdate }: OptionalUpdateDialogProps) => {
  const onClickUpdate = (e: any) => {
    e.preventDefault();
    onUpdate();
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        zIndex: 1,
        bottom: 32,
        right: 32,
        border: "solid 1px #EB5757",
        width: 353,
        height: 84,
        display: "flex",
        alignItems: "center",
        p: 1,
        padding: "20px",
        fontSize: "12px",
        justifyContent: "space-between",
        boxShadow: "none",
      }}
    >
      <Box>
        <Box sx={{ fontSize: "15px", fontWeight: "bold" }}>🔔 알림</Box>
        <Box>
          새로운 버전이 출시되었습니다. <br />
          업데이트 후 이용해주시기 바랍니다.
        </Box>
      </Box>
      <MuiButton
        sx={{
          width: 78,
          height: 28,
          backgroundColor: "#EB5757",
          color: "white",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#EB5757",
          },
        }}
        onClick={onClickUpdate}
      >
        업데이트
      </MuiButton>
    </Paper>
  );
};

const BlockingUpdateDialog = ({
  nextVersion,
  onUpdate,
}: OptionalUpdateDialogProps) => {
  const onClickUpdate = (e: any) => {
    e.preventDefault();
    onUpdate();
  };

  return (
    <Dialog open={true}>
      <Stack className="dialog-wrapper" justifyContent="space-between">
        <Stack flexDirection="column" gap="10px">
          <Box className="title">
            <Box className="icon-box" />
            <span>필수 업데이트 안내</span>
          </Box>
          <Box className="contents">
            더욱 향상된 기능을 제공합니다.
            <br />
            최신 버전({nextVersion})으로 업데이트해 주세요.
          </Box>
        </Stack>
        <Stack display="flex" flexDirection="row" justifyContent="flex-end">
          <Button onClick={onClickUpdate} autoFocus={true}>
            업데이트
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export function Updater() {
  const [hasUpdate, setHasUpdate] = useState(false);
  const [nextVersion, setNextVersion] = useState(null);
  const reloadFunc: any = useRef(null);
  const [stableVersion, setStableVersion] = useState<string | null>(null);

  const isLowerVersion = () => {
    const currentVersion = process.env.REACT_APP_VERSION || "";

    if (!stableVersion) return false;

    const currentSemanticVersion = extractSemanticVersions(currentVersion);
    const stableSemanticVersion = extractSemanticVersions(stableVersion);

    if (currentSemanticVersion == null || stableSemanticVersion == null) {
      return false;
    }

    if (
      currentSemanticVersion.major > stableSemanticVersion.major ||
      currentSemanticVersion.minor > stableSemanticVersion.minor ||
      currentSemanticVersion.patch > stableSemanticVersion.patch
    ) {
      return false;
    }

    return true;
  };

  const loadStableVersion = async () => {
    setStableVersion(await firebaseClient.getStableVersion());
  };

  useEffect(() => {
    ServiceWorkerConfig.onUpdate = (registration) => {
      loadStableVersion();
      const waitingServiceWorker = registration.waiting;

      const messageChannel = new MessageChannel();

      waitingServiceWorker.postMessage(
        {
          type: "VERSION",
        },
        [messageChannel.port2]
      );

      // Listen to the response
      messageChannel.port1.onmessage = (event) => {
        setNextVersion(event.data.payload);
      };

      setHasUpdate(true);
      reloadFunc.current = () => updateWorker(registration);
    };
  }, []);

  const onUpdate = () => {
    reloadFunc.current();
  };

  if (!hasUpdate) return null;

  return isLowerVersion() ? (
    <BlockingUpdateDialog nextVersion={nextVersion} onUpdate={onUpdate} />
  ) : (
    <OptionalUpdateDialog nextVersion={nextVersion} onUpdate={onUpdate} />
  );
}
