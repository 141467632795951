import { styled, Box as MuiBox } from "@mui/material";
import _Button from "components/Button";

export const Button = styled(_Button)(
  () => `
  width: 50%;
  height: 54px;
`
);

export const Box = styled(MuiBox)(
  () => `
  &.action-btn-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 7px 34px;
    gap: 20px;
  }
  &.action-btn-wrapper.registration {
    button {
      width: calc((100% - 38px) / 3);
    }
  }
  `
);
