import {
  Box as MuiBox,
  Stack as MuiStack,
  styled,
  Typography as MuiTypography,
  typographyClasses,
  buttonBaseClasses,
  buttonClasses,
  inputBaseClasses,
} from "@mui/material";
import { disabledBgColor } from "utils/surveyHelper";

export const Typography = styled(MuiTypography)(
  () => `
  &.${typographyClasses.h1} {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: center;
  }
  &.${typographyClasses.h2} {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  &.${typographyClasses.h3} {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px;
  }
  &.${typographyClasses.body1} {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &.mt-20 {
    margin-top:20px;
  }

  &.mb-20 {
    margin-bottom:20px;
  }
`
);

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.content {
    background-color: ${theme.palette.bluegrey["100"]};
    padding: 16px 20px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid ${theme.palette.bluegrey["100"]};

    .required {
      color: ${theme.palette.error["main"]};
      margin-left: 4px;
    }

    &.focused {
      border: 1px solid ${theme.palette.primary["main"]};
    }
  }

  &.description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &.mt-20 {
    margin-top: 20px;
  }

  &.mb-20 {
    margin-bottom: 20px;
  }

  &.center-pos-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 64px);
    max-width: calc(100vw - 64px);
    z-index: 1301;
    display: flex;
    flex-direction: column;
  }
  
  &.ht-popup-container {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px #a1b1ca;
    width: 598px; 

    .required {
      color: ${theme.palette.error};
      margin-left: 4px;
    }
  }

   &.survey-popup-body {
    background: rgb(249, 251, 255);
    height: calc(100% - 68px);
    overflow: auto;
    padding: 20px 0 96px;

    .required {
      color: ${theme.palette.error["main"]};
      margin-left: 4px;
    }
  }

  &.survey-popup-body-content {
    width: 100%;
    max-width: 598px; 
    margin: auto;
    border-radius: 4px;
    border: 1px solid ${theme.palette.layout.line};
    background: #ffffff;
    padding: 20px;
  }

  &.action-btn-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;

    .width-adjust-wrapper {
      width: 100%;
      height: 76px;
      padding: 14px 14px;
      display: flex;
      gap: 10px;
      justify-content: center;
    }

    .${buttonBaseClasses.root} {
      width: 100%;
      height: 100%;
    }

    .${buttonClasses.outlined} {
      border-color: ${theme.palette.grey["700"]};
      color: ${theme.palette.grey["700"]};
    }
  }

  &.result-text {
    white-space: pre-wrap;
  }
`
);

export const Stack = styled(MuiStack)(
  ({ theme }) => `

  &.btn-wrappers {
    width: 100%;
    gap: 10px;
    .${buttonBaseClasses.root} {
      width: 100%;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      padding: 14px;
      display: flex;
      align-items: center;
    }
    .${buttonClasses.outlined}{
      border-color: ${theme.palette.grey["700"]};
      color: ${theme.palette.grey["700"]};
    }
  }

  .date-selector {
    border: 1px solid ${theme.palette.layout.line};
    border-radius: 4px;
    width: 100% !important;
    input {
      width: 100%;
      background-image: unset !important;
      border:0 none;
      &:disabled {
        background-color: ${theme.palette.grey["200"]};
        color: ${theme.palette.grey["700"]};
      }
    }

    &:disabled {
      input {
        &:disabled {
          background-color: ${disabledBgColor};
        }
      }
    }
  }

  &.hours-selector, &.minutes-selector {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: 0 none !important;
    width: 100px;
    flex-shrink: 0;
    gap: 10px;

    .${inputBaseClasses.root} {
      background-color: #ffffff;
    }

    .${inputBaseClasses.disabled} {
      background-color: ${theme.palette.grey["200"]};
      input {
        /** -webkit-text-fill-color: ${theme.palette.grey["700"]}; */
      }
    }
  }
`
);

export const CustomerName = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;
