import { AppointmentStatus } from "type/appointmentStatus";

export const onChangeStatusCheck = (status: string, changeStatus: string) => {
  //예약상태 (예약/미방문/예약취소)
  const scheduledStatuses = ["SCHEDULED", "NO_SHOW", "CANCELED"];
  //접수상태 (상담대기/상담중/진료대기/진료중/시수술대기/시수술중/수납대기/퇴원/완료)
  //접수상태 -> 예약상태로 변경 불가
  //예약상태-예약취소 -> 접수 상태로 변경 불가
  if (scheduledStatuses.findIndex((f) => f === status) === -1) {
    if (scheduledStatuses.findIndex((f) => f === changeStatus) > -1) {
      const text =
        status === AppointmentStatus.leave || status.indexOf("DONE") > -1
          ? "완료"
          : "접수";
      return `이미 ${text}된 건입니다. 상태를 확인하세요.`;
      // return "이미 접수 된 건으로 변경이 불가능합니다.";
    }
  }

  if (status === "CANCELED") {
    if (changeStatus !== "SCHEDULED" && changeStatus !== "NO_SHOW") {
      return "예약 취소 된 건으로 변경이 불가능합니다.";
    }
  }
};

export const onCheckAppointmentToRegistration = (
  status: string,
  changeStatus: string
) => {
  if (status === "CANCELED") {
    if (changeStatus !== "SCHEDULED" && changeStatus !== "NO_SHOW") {
      return "예약 취소 된 건으로 변경이 불가능합니다.";
    }
  }
  if (
    changeStatus === AppointmentStatus.leave ||
    changeStatus.indexOf("DONE") > -1
  ) {
    let message = "";
    if (status === AppointmentStatus.scheduled) {
      message = `접수되지 않은 예약 건입니다. 상태를 확인하세요.`;
    }
    if (status === AppointmentStatus.noShow) {
      message = `미방문 건입니다. 상태를 확인하세요.`;
    }
    if (status === AppointmentStatus.canceled) {
      message = `예약 취소된 건입니다. 상태를 확인하세요.`;
    }
    return message;
  }
  if (changeStatus === AppointmentStatus.registrationCanceled) {
    return "접수되지 않은 예약 건입니다. 상태를 확인하세요.";
  }
};
