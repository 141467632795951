import { useMemo } from "react";
import { DataTable } from "~/components/DataTable/DataTable";
import { translate } from "~/utils/filters";
import { ContentsTooltip } from "~/components/ContentsTooltip";
import Icon from "components/Icon";
import {
  TitleContentsWrapper,
  TitleContentsOverflowWrapper,
  Image,
  ImageWrapper,
} from "~/modals/ScheduledSmsPreviewTable.styled";

type ScheduledSmsPreviewTableProps = {
  data: any[];
  loading: boolean;
};

export const ScheduledSmsPreviewTable = ({
  data,
  loading,
}: ScheduledSmsPreviewTableProps) => {
  const translateScheduledAt = (item: any) => {
    if (item.smsScheduleType === "immediate") {
      return translate("SMS_SCHEDULE_TYPE_IMMEDIATE");
    }
    if (item.smsScheduleType === "scheduled") {
      const days = Math.abs(item.daysOffset) === 1 ? "ONE_DAY" : "N_DAYS";
      const direction = item.daysOffset < 0 ? "AGO" : "LATER";
      const date = translate(`${days}_${direction}`).replace(
        /%s/,
        Math.abs(item.daysOffset)
      );
      const time = item.scheduleTime;
      if (item.daysOffset === 0) return `예약 0일 ${time}`;
      return `예약 ${date} ${time}`;
    }
  };

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: "scheuledAt",
          name: "전송시간",
          value: (item: any) => translateScheduledAt(item),
          style: {
            minWidth: "100px",
          },
        },
        {
          id: "titleContents",
          name: "제목/내용",
          component: (attr: any) => {
            const { item } = attr;
            return (
              <ContentsTooltip
                title={
                  <TitleContentsWrapper>
                    <div>{item.title}</div>
                    <div>{item.contents}</div>
                  </TitleContentsWrapper>
                }
              >
                <TitleContentsOverflowWrapper>
                  <div>{item.title}</div>
                  <div>{item.contents}</div>
                </TitleContentsOverflowWrapper>
              </ContentsTooltip>
            );
          },
          grow: true,
        },
        {
          id: "imageUrl",
          name: "이미지",
          component: (attr: any) => {
            const { item } = attr;
            return item.imageUrl ? (
              <ImageWrapper>
                <Image src={item.imageUrl} />
              </ImageWrapper>
            ) : (
              <ImageWrapper>
                <Icon variant="sms_null" />
              </ImageWrapper>
            );
          },
          style: {
            minWidth: "100px",
          },
        },
      ],
    }),
    []
  );

  return <DataTable data={data ?? []} schema={schema} loading={loading} />;
};
