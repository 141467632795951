import {
  Footer as StyledFooter,
  P,
  Title,
  Contents,
  Box,
} from "./Footer.styled";

export const Footer = () => {
  return (
    <Box className="footer">
      <StyledFooter>
        <Box sx={{ display: "flex" }}>
          <P>
            <Title>주식회사 케어랩스 (Carelabs Co.,Ltd)</Title>
          </P>
          <P>
            <Title>대표자</Title>
            <Contents>신종현</Contents>
          </P>
        </Box>
        <Box>
          <P>
            <Title>주소</Title>
            <Contents>서울특별시 강남구 역삼로 2길 16(에스타워), 6층</Contents>
          </P>
        </Box>
        <Box sx={{ display: "flex" }}>
          <P>
            <Title>사업자등록번호</Title>
            <Contents>220-88-36643</Contents>
          </P>
        </Box>
        <P>
          <Title>통신판매업신고번호</Title>
          <Contents>제 2019-서울강남-01787호</Contents>
        </P>
        <P>
          <Title>문의하기</Title>
          <Contents>
            <a href="mailto:cs@carecrm.co.kr">cs@carecrm.co.kr</a>
          </Contents>
        </P>
      </StyledFooter>
      <Box
        sx={{
          color: "#A1B1CA",
        }}
      >
        COPYRIGHT © carelabs All rights reserved
      </Box>
    </Box>
  );
};

export default Footer;
