import { Box, IconButton } from "@mui/material";
import { CenterAlignedTAB } from "components/CenterAlignedTAB/CenterAlignedTAB";
import { useNavigate } from "react-router-dom";
import { myTheme } from "styles/theme";
import Icon from "components/Icon";

export function InnerPage({
  title,
  children,
  trailingButton = null,
}: {
  title?: any;
  children?: any;
  trailingButton?: any;
}) {
  const nav = useNavigate();

  const onClickBack = () => {
    nav(-1);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "grid",
        gridTemplateRows: `max-content 1fr`,
      }}
    >
      <CenterAlignedTAB
        leadingButton={
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onClickBack}
            sx={{ width: "40px" }}
          >
            <Icon variant="back_arrow" />
          </IconButton>
        }
        title={title}
        trailingButton={trailingButton}
      />
      <Box
        sx={{
          overflow: "auto",
          backgroundColor: myTheme.palette.bluegrey["100"],
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
