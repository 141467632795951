import {
  Box as MuiBox,
  Button as MuiButton,
  styled as muiStyled,
  TextField,
  Divider as MuiDivider,
} from "@mui/material";
import styled from "styled-components";

export const Button = muiStyled(MuiButton)`
  &.login-btn {
    height: 54px;
    font-size: 16px;
  }
`;

export const Divider = muiStyled(MuiDivider)`
  margin: 3px 0;
`;

export const Box = muiStyled(MuiBox)(
  ({ theme }) => `
  &.logo-image-wrapper {
    width: 100%;
    margin: 16px auto 16px;

    img {
      width: 100%;
    }
  }

  &.company-info-wrapper {
    font-size: 14px;
    font-weight: 700;
    line-height: 17.38px;
    display: flex;
    margin: 20px auto 0;
    flex-direction: row;
    color: ${theme.palette.grey["700"]};
    gap: 13px;
    white-space: nowrap;

    a {
      color: ${theme.palette.grey["700"]};
      font-weight: 500;
    }
  }

  &.auto-login-option {
    text-align: right;
    margin: 10px 0 20px;
  }
`
);

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0 auto;
  color: #adaebc;
  position: relative;
  top: 0;
  width: 336px;
  height: 100%;

  &.login-form-wrapper {
    @media (hover: none) and (pointer: coarse) {
      @media (orientation: landscape) {
        width: 660px;
        padding: 0 16px;
        gap: 48px;
        flex-direction: row;
      }
    }
    &.mobile {
      @media (orientation: landscape) {
        width: 660px;
        padding: 0 16px;
        gap: 48px;
        flex-direction: row;
      }
    }
  }
`;

export const Form = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
  color: #282828;
`;

export const FieldWrapper = muiStyled(MuiBox)`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 500;

  &.mt-24 {
    margin-top: 24px;
  }
`;

export const Input = muiStyled(TextField)(
  ({ theme }) => `
  fieldset {
    border: 0 none;
  }

  input {
    height: 52px;
    box-sizing: border-box;
    background: #f9fbff;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    border: 0 none;

    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
    border-bottom: 1px solid rgb(173 174 188 / 10%);
    border-radius: 0;
  }
`
);

export const WarningWrapper = styled("div")`
  margin-top: 10px;
`;

export const WarningText = styled("div")`
  color: #eb5757;
  display: flex;
  justify-content: left;
  font-size: 12px;
  font-weight: bold;
`;
