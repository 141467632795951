import { useState } from "react";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/system";
import { FindFamilyInput as _FindFamilyInput } from "./FindFamilyInput";
import { returnSex } from "./FindCustomerAutoCompleteRow";
import { phoneNumberFormatHyphen } from "~/utils/filters";
import { Label as _Label, LabelWrapper } from "components/Form/Label";
import Button from "components/Button";

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Label = styled(_Label)`
  div {
    gap: 5px;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const FindFamilyInput = muiStyled(_FindFamilyInput)(
  ({ theme }) => `
  height: 34px;
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 34px;
    font-size: 14px !important;
    padding: 0 20px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

const AddButton = styled(Button)`
  background: #293142 !important;
  width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  border: 0 none !important;
  margin-left: 8px;
`;

const RemoveButton = styled(Button)`
  background: #eb5757 !important;
  width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  border: 0 none !important;
  margin-left: 8px;
`;

const returnText = (customer: any) => {
  let { birthday, phoneNumber } = customer;
  const { name } = customer;
  if (birthday) birthday = birthday.replace(/-/g, ".");
  if (phoneNumber) phoneNumber = phoneNumberFormatHyphen(phoneNumber);

  return `${name ?? "-"}/${birthday ?? "-"}/${returnSex(customer.sex) ?? "-"}/${
    phoneNumber ?? "-"
  }`;
};

type FamilyInfoProps = {
  familyRelationships: any;
  setFamilyRelationships: any;
  disabled?: boolean;
};

const FamilyInfo = ({
  familyRelationships,
  setFamilyRelationships,
  disabled,
}: FamilyInfoProps) => {
  const [items, setItems] = useState(
    familyRelationships && familyRelationships.length > 0
      ? familyRelationships.map((v: any) => ({ ...v, name: returnText(v) }))
      : [{ name: "", id: "" }]
  );
  const addList = () => {
    const update = [...items];
    update.push({ name: "", id: "" });
    if (items.length === 0) {
      update.push({ name: "", id: "" });
    }
    setItems([...update]);
  };
  const removeList = (i: number) => {
    const update = [...items];
    update.splice(i, 1);
    setItems([...update]);
    setFamilyRelationships(update);
  };

  return (
    <LabelWrapper column={3}>
      <Label text="가족관계" style={{ gridColumn: "1 / 3" }}>
        {items.map((v: any, i: number) => (
          <FlexWrapper key={i}>
            <InputWrapper>
              <FindFamilyInput
                index={i}
                items={items}
                setItems={setItems}
                obj={familyRelationships}
                setObj={setFamilyRelationships}
                disabled={disabled}
              />
            </InputWrapper>
            {!disabled &&
              (items.length === 0 || items.length - 1 === i ? (
                <AddButton onClick={addList}>+</AddButton>
              ) : (
                <RemoveButton onClick={() => removeList(i)}>-</RemoveButton>
              ))}
          </FlexWrapper>
        ))}
      </Label>
    </LabelWrapper>
  );
};

export default FamilyInfo;
