import { useState } from "react";
import { DefaultModal } from "components/Modal/DefaultModal";
import { Iframe, P } from "pages/Nav/button/CertificationButton.styled";
import Icon from "components/Icon";

export function CertificationButton({
  certification,
}: {
  certification?: string;
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <P
        color={certification === "active" ? "#0060ff" : "#adaebc"}
        onClick={() => setOpenModal(true)}
      >
        {certification === "active" ? "전자서명 등록완료" : "전자서명 미등록"}
        <Icon variant="notice" />
      </P>
      <DefaultModal
        open={openModal}
        title="전자서명"
        body={<Iframe src="https://assets.unocare.co.kr/certifications.html" />}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}
