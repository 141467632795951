import sanitizeHtml from "sanitize-html";
import { Popover } from "@mui/material";
import Icon from "components/Icon";
import { Header, Body } from "./ShowMorePopover.styled";

type ShowMorePopoverProps = {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  value: any;
};

export default function ShowMorePopover({
  anchorEl,
  open,
  onClose,
  value,
}: ShowMorePopoverProps) {
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          minWidth: "150px",
          maxWidth: "300px",
          padding: "8px",
          marginLeft: "10px",
        },
      }}
    >
      <Header>
        [메모]
        <button onClick={onClose}>
          <Icon variant="close" />
        </button>
      </Header>
      <Body
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(value, {
            allowedAttributes: false,
          }),
        }}
      />
    </Popover>
  );
}
