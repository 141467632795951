import { useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SlideModal, Wrapper, Body } from "components/Modal/SlideModal";
import { Form } from "pages/AppointmentPage/form/Form";
import { getCustomerName } from "utils/getCustomerName";
import {
  Header,
  Avatar,
  CustomerName,
  iconButtonStyle,
} from "./CreateAppointmentModal.styled";
import { useApi } from "providers/api";

export function CreateAppointmentModal({
  open,
  onClose,
  customer,
  appointment,
  onSaveCallback,
  profileUrl: propsProfileUrl,
}: {
  open?: any;
  onClose?: () => void;
  customer?: any;
  appointment?: any;
  onSaveCallback?: (value?: any) => void;
  profileUrl?: string;
}) {
  const api = useApi();
  const [profileUrl, setProfileImage] = useState("");

  useEffect(() => {
    if (!propsProfileUrl) {
      customer?.profileImageId && loadProfileImage(customer.profileImageId);
    }
  }, [customer]);

  const loadProfileImage = useCallback(
    async (profileImageId: number) => {
      const res = await api.getImage(profileImageId);
      const payload = await res.json();
      if (payload?.data && payload.data.thumbnailUrl) {
        setProfileImage(payload.data.thumbnailUrl);
      }
    },
    [customer]
  );

  return (
    <SlideModal open={open}>
      <Wrapper>
        <Header>
          <IconButton sx={iconButtonStyle} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <span className="title">
            <Avatar
              src={propsProfileUrl || profileUrl}
              className={`${customer?.sex || ""}`}
            />
            <CustomerName>
              <b>{getCustomerName(customer)}</b>&nbsp;님의
              {appointment?.id ? " 예약내역 수정" : " 예약등록"}
            </CustomerName>
          </span>
        </Header>
        <Body>
          <Form
            appointment={appointment}
            customer={customer}
            onSaveCallback={() => {
              onSaveCallback && onSaveCallback();
              onClose && onClose();
            }}
          />
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
