import { useApi } from "providers/api";
import { useImperativeModal } from "ImperativeModalProvider";
import { useSnackbar } from "SnackbarProvider";
import { TemplateContentType } from "type/survey";
import { CustomerType } from "type/customer";
import SurveyPopupContainer from "modals/SurveyPopupContainer";
import TemplateSelector from "components/TemplateSelector";

function isValidDate(date: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(date)) return false;
  const parts = date.split("-").map(Number);
  const d = new Date(parts[0], parts[1] - 1, parts[2]);
  return (
    d.getFullYear() === parts[0] &&
    d.getMonth() === parts[1] - 1 &&
    d.getDate() === parts[2]
  );
}

function isValidHour(hour: string): boolean {
  const hourNum = parseInt(hour, 10);
  return !isNaN(hourNum) && hourNum >= 0 && hourNum <= 23;
}

function isValidMinute(minute: string): boolean {
  const minuteNum = parseInt(minute, 10);
  return !isNaN(minuteNum) && minuteNum >= 0 && minuteNum <= 50;
}

const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const useSurvey = (customer: CustomerType) => {
  const imperativeModal = useImperativeModal();
  const snackbar = useSnackbar();
  const { surveyApi } = useApi();

  const getTemplateSchema = async (): Promise<{ id: number } | null> => {
    return new Promise((resolve) => {
      imperativeModal.open((close) => (
        <TemplateSelector
          onClose={() => {
            close();
            resolve(null);
          }}
          onSelect={(selected: { id: number }) => {
            close();
            resolve(selected);
          }}
        />
      ));
    });
  };

  const handleCreateNewSurvey = async (callback?: () => void) => {
    const templateSchema = await getTemplateSchema();
    if (templateSchema) {
      try {
        const templateId = templateSchema.id;
        const response = await surveyApi.templateDetail(templateId);
        const data = response.data.data;
        await imperativeModal.open((close) => (
          <SurveyPopupContainer
            onClose={close}
            templateId={templateId}
            customer={customer}
            callback={callback}
            data={{
              subject: data.title,
              expiredDay: data.expirationDay,
              content: data.contents,
              description: data.topInfo,
              resultText: data.resultInfo,
            }}
          />
        ));
      } catch (error) {
        snackbar.open(
          "데이터 로딩중 에러가 발생했습니다. 잠시 후 다시 시도해주세요."
        );
      }
    }
  };

  const handleUpdateSurvey = async (
    surveyId: number,
    callback?: () => void
  ) => {
    try {
      const response = await surveyApi.getSurvey(surveyId);
      const data = response.data.data;
      await imperativeModal.open((close) => (
        <SurveyPopupContainer
          onClose={close}
          title={"문진 수정"}
          mode={"edit"}
          surveyId={surveyId}
          customer={customer}
          callback={callback}
          status={data.status}
          data={{
            subject: data.title,
            expiredDay: data.expirationDay,
            content: data.contents,
            registeredDate: data.registeredDate,
            description: data.topInfo,
            resultText: data.resultInfo,
          }}
        />
      ));
    } catch (error) {
      snackbar.open(
        "데이터 로딩중 에러가 발생했습니다. 잠시 후 다시 시도해주세요."
      );
    }
  };

  const handleViewSurvey = async (surveyId: number) => {
    const response = await surveyApi.getSurvey(surveyId);
    const data = response.data.data;
    await imperativeModal.open((close) => (
      <SurveyPopupContainer
        onClose={close}
        title={"문진 열람"}
        mode={"read"}
        surveyId={surveyId}
        customer={customer}
        data={{
          subject: data.title,
          expiredDay: data.expirationDay,
          registeredDate: data.registeredDate,
          content: data.contents,
          description: data.topInfo,
          resultText: data.resultInfo,
        }}
      />
    ));
  };

  const validateSurveyContent = (
    data: TemplateContentType[]
  ): { isValid: boolean; invalidIndexes: number[] } => {
    const invalidIndexes: number[] = [];

    data.forEach((item, index) => {
      if (item.required === true) {
        switch (item.type) {
          case "TEXT-1":
            if (!item.result.text.trim()) {
              invalidIndexes.push(index);
            }
            break;
          case "RADIO-1":
            if (
              !item.result.values.some((value: string) =>
                item.content.values.includes(value)
              )
            ) {
              invalidIndexes.push(index);
            }
            break;
          case "CHECKBOX-1":
            if (
              !item.result.values.some((value: string) =>
                item.content.values.includes(value)
              )
            ) {
              invalidIndexes.push(index);
            }
            break;
          case "DATE-1":
            if (!isValidDate(item.result.values[0])) {
              invalidIndexes.push(index);
            }
            break;
          case "DATE-2":
            if (
              !isValidDate(item.result.values[0]) ||
              !isValidHour(item.result.values[1]) ||
              !isValidMinute(item.result.values[2])
            ) {
              invalidIndexes.push(index);
            }
            break;
          case "AGREEMENT-1":
            if (!arraysEqual(item.content.values, item.result.values)) {
              invalidIndexes.push(index);
            }
            break;
        }
      }
    });

    return {
      isValid: invalidIndexes.length === 0,
      invalidIndexes,
    };
  };

  return {
    createSurvey: handleCreateNewSurvey,
    updateSurvey: handleUpdateSurvey,
    viewSurvey: handleViewSurvey,
    getTemplateSchema,
    validateSurveyContent,
  };
};
