import { Typography } from "@mui/material";

export function TitleTypo(props: any) {
  return (
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: "500",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
      {...props}
    />
  );
}
