import CloseIcon from "@mui/icons-material/Close";
import { IconWrapper } from "./ClearButton.styled";

type ClearButtonProps = {
  onClick: (params?: any) => void;
  className?: string;
};

export const ClearButton = ({ onClick, ...rest }: ClearButtonProps) => {
  return (
    <IconWrapper onClick={onClick} {...rest}>
      <CloseIcon />
    </IconWrapper>
  );
};
