import LogoutIcon from "@mui/icons-material/Logout";
import { observer } from "mobx-react";
import { useUser } from "providers/user";
import { useAuth } from "providers/auth";
import { ReactComponent as UnocrmLogo } from "assets/icons/ic-unocrm-logo.svg";
import { useSimpleRegistration } from "hooks/useStore";
import { Box, IconButton } from "./LeftSection.styled";

export const LeftSection = observer(() => {
  const { getHiddenCondition } = useSimpleRegistration();
  const { user } = useUser();
  const { signout } = useAuth();

  const handleClick = () => {
    signout();
  };

  return (
    <Box className="left-wrapper">
      <Box className="background-image-wrapper">
        <Box className="title-wrapper">
          {getHiddenCondition ? (
            <IconButton onClick={handleClick}>
              <LogoutIcon />
            </IconButton>
          ) : (
            user.organization_top.name
          )}
        </Box>
        <Box className="description-wrapper">
          <p className="description font-size-5vw">
            반갑습니다 {getHiddenCondition ? "🤫" : ";)"} <br />
          </p>
          <p className="description font-size-3vw">
            전화번호로 <br />
            접수를 시작합니다.
          </p>
        </Box>
        <UnocrmLogo />
      </Box>
    </Box>
  );
});
