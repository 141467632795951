// import { css } from "styled-components";
import { Select, FormControl, styled as muiStyled } from "@mui/material";
import { ComponentType } from "react";
import { InnerBoxStyle } from "./Label.styled";
import styled from "styled-components";

// export const InnerBoxStyle = css`
//   width: 100%;
//   height: 29px;
//   border: 1px solid #dee2ec;
//   box-sizing: border-box;
//   border-radius: 3px;
//   padding: 6px 8px;
//   font-size: 12px;
//   transition: border-color 0.1s;
//   &:focus {
//     border-color: #2c62f6;
//   }
// `;

export const SmallWrapper = muiStyled("div")`
  &&& {
    select {
      height: 23px;
      padding: 4px;
      padding-right: 16px;
      line-height: 1;
    }
    svg {
      right: -3px;
      transform: scale(0.7);
    }
  }
`;

export const Wrapper = muiStyled(FormControl)`
  width: 100%;
  background: #ffffff;
  & select {
    padding: 0 8px;
  }
  .MuiNativeSelect-select {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const SelectBox = styled(Select)(
  ({ $invalid, optioncolor, theme }: any) => `
    // ${InnerBoxStyle}
    width: 100%;
    height: 29px;
    border: 1px solid #dee2ec;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 6px 8px;
    font-size: 12px;
    transition: border-color 0.1s;
    &:focus {
      border-color: #2c62f6;
    }  

    border: 0;
    color: ${$invalid ? "#a1b1ca" : "inherit"};
    & option {
      color: ${!optioncolor ? "#2D2D2D !important" : ""};
    }

    &.MuiInputBase-formControl.MuiInputBase-root {
      .MuiNativeSelect-select.MuiNativeSelect-outlined {
        padding-right: 20px;
      }
    }

    select {
      overflow:hidden; 
      white-space:nowrap; 
      text-overflow:ellipsis;
    }

    svg,path {
      fill: ${theme.palette.grey["700"]};
    }
`
) as ComponentType<any>;
