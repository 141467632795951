import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import { useState } from "react";
import { useApi } from "providers/api";
import { useSnackbar } from "SnackbarProvider";
import { useModal } from "hooks/useModal";
import { useDriveView } from "./DriveView";
import { ChangeNameDialog } from "./ChangeNameDialog";
import CheckIcon from "@mui/icons-material/Check";
import { DeleteConfirmDialog } from "./DeleteConfirmDialog";
import { TitleTypo } from "./TitleTypo";
import { ApiException } from "core/apiClient";
import { useUser } from "providers/user";
import { IconWrapper } from "components/DriveView/Directory.styled";
import Icon from "components/Icon";

export function Directory({
  directory,
  onEnter,
  selecting,
  onChangeChecked,
  checked,
  readonly = false,
}: {
  directory?: any;
  onEnter?: any;
  selecting?: any;
  onChangeChecked?: any;
  checked?: any;
  readonly?: any;
}) {
  const api = useApi();
  const sb = useSnackbar();
  const { hasPermission } = useUser();
  const driveView: any = useDriveView();
  const changeNameModal = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteConfirmDialog = useModal();
  const permission =
    driveView.penchartDrive === true
      ? hasPermission("PENCHART_SAMPLE_DELETE")
      : hasPermission("PENCHART_DELETE");

  const onClickMenu = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onClickCopy = async () => {
    try {
      await api.copyDirectory(directory.id, directory.parent.id);
      sb.open("폴더를 복사했습니다.");
    } catch (e: any) {
      if (e instanceof ApiException && e.code === 404) {
        sb.open("작업에 실패했습니다. 삭제되었거나 없는 항목입니다.");
        return;
      }
      sb.open("작업에 실패했습니다.");
      throw e;
    } finally {
      driveView.reload();
      closeMenu();
    }
  };

  const onClickChangeName = async () => {
    changeNameModal.open();
    closeMenu();
  };

  const onClickDelete = async () => {
    deleteConfirmDialog.open();
    closeMenu();
  };

  const onDelete = async () => {
    try {
      await api.deleteDirectory(directory.id);
      sb.open("폴더를 삭제했습니다.");
    } catch (e) {
      if (e instanceof ApiException && e.code === 404) {
        sb.open("작업에 실패했습니다. 삭제되었거나 없는 항목입니다.");
        return;
      }

      sb.open("작업에 실패했습니다.");
      throw e;
    } finally {
      driveView.reload();
    }
  };

  const onConfirmChangeName = async (name: string) => {
    await api.updateDirectory(directory.id, {
      name,
    });
    driveView.reload();
    sb.open("폴더 이름을 변경했습니다.");
    changeNameModal.close();
  };

  const onClick = (e: any) => {
    e.stopPropagation();

    if (selecting) {
      onChangeChecked(!checked);
    } else {
      onEnter();
    }
  };

  return (
    <>
      <Paper
        sx={{
          position: "relative",
          borderRadius: "6px",
          border: `1px solid #efeff4`,
        }}
        elevation={0}
        onClick={onClick}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            p: "12px 12px 12px 20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <IconWrapper>
              <Icon variant="folder" />
            </IconWrapper>
            <TitleTypo>{directory.name}</TitleTypo>
          </Box>
          {!readonly && !selecting && (
            <IconButton onClick={onClickMenu} sx={{ p: 0 }}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
        {checked && (
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 28,
              height: 28,
              transform: "translate(50%, -50%)",
              color: "white",
              backgroundColor: "primary.main",
            }}
          >
            <CheckIcon color="inherit" />
          </IconButton>
        )}
      </Paper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={onClickCopy}>복사</MenuItem>
        <MenuItem onClick={onClickChangeName}>이름 변경</MenuItem>
        {permission && <MenuItem onClick={onClickDelete}>삭제</MenuItem>}
      </Menu>
      <ChangeNameDialog
        label="폴더 이름"
        defaultName={directory.name}
        open={changeNameModal.opened}
        onClose={changeNameModal.close}
        onConfirm={onConfirmChangeName}
      />
      <DeleteConfirmDialog
        open={deleteConfirmDialog.opened}
        onClose={deleteConfirmDialog.close}
        onConfirm={onDelete}
      />
    </>
  );
}
