import { TextField } from "@mui/material";
import { useState } from "react";
import { ConfirmModal } from "components/Modal/ConfirmModal";

type ChangeNameDialogProps = {
  defaultName?: string;
  label?: string;
  open?: boolean;
  onClose: () => void;
  onConfirm: (value: any) => void;
};

export function ChangeNameDialog({
  defaultName,
  label,
  open,
  onClose,
  onConfirm,
}: ChangeNameDialogProps) {
  const [name, setName] = useState(defaultName);

  function onClickCancel(e: any) {
    e.stopPropagation();
    onClose();
  }

  const onClickConfirm = async (e: any) => {
    e.stopPropagation();
    onConfirm(name);
  };

  return (
    <>
      <ConfirmModal
        title="이름변경"
        body={
          <TextField
            autoFocus
            margin="dense"
            label={label}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        }
        confirmText="변경"
        onClose={onClickCancel}
        onConfirm={onClickConfirm}
        open={open}
      />
    </>
  );
}
