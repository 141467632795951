import { Box as MuiBox, styled, css } from "@mui/material";

export const Box = styled(MuiBox)`
  &.footer {
    width: 100%;
    font-size: 12px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
`;

export const Footer = styled(MuiBox)`
  font-size: 12px;
  color: #273142;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const P = styled("p")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  a {
    color: #4a4e70;
    text-decoration: none;
  }
`;

export const Title = styled("span")`
  color: #4a4e70;
  font-weight: bold;
  white-space: nowrap;

  ${({ underline }: any) =>
    underline === "active" &&
    css`
      text-decoration: underline;
    `}
`;

export const Contents = styled("span")`
  margin: 0 5px;
  color: #4a4e70;
  font-weight: 400;
  white-space: nowrap;
`;
