import { styled, Avatar as MuiAvatar } from "@mui/material";
import { Header as _Header } from "components/Modal/SlideModal";

export const Header = styled(_Header)(
  () => `
  justify-content: flex-start;

  .title {
    display: inline-flex;
    align-items: center;
  }
`
);

export const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  background: ${theme.palette.bluegrey["500"]};
  svg {
    width: 25px;
    fill: #A4BCF2;
  }
  margin-right: 10px;
  margin-left: 8px;

  &.female {
    background: rgb(253, 238, 238);
    svg {
      fill: rgb(235, 87, 87);
      fill-opacity: 0.2;
    }
  }
`
);

export const CustomerName = styled("div")`
  width: 100%;
`;

export const iconButtonStyle = {
  width: "50px",
  height: "50px",
  margin: "5px 10px",
};
