export const PageDotIndicator = ({ page, total }) => {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {Array.from({ length: total }, (_, i) => (
        <div
          key={i}
          style={{
            width: "10px",
            height: "10px",
            border: `solid 1px ${i === page - 1 ? "#2D2D2D" : "#DEE2EC"}`,
            backgroundColor: i === page - 1 ? "#2D2D2D" : "#F9FBFF",
            borderRadius: "50%",
          }}
        />
      ))}
    </div>
  );
};
