import { styled } from "@mui/material";

export const Wrapper = styled("div")`
  display: flex;
  gap: 20px;

  .count-wrapper {
    flex-shrink: 0;
    min-width: 40px;
    display: flex;
    justify-content: center;
  }
`;

export const CategoryRow = styled("div")`
  display: flex;
  white-space: normal;
`;

export const CategoryName = styled("div")`
  white-space: normal;
  word-wrap: break-word;
  max-width: 320px;
  flex-shrink: 0;

  &::before {
    content: "[";
  }
  &::after {
    content: "]";
  }
`;
