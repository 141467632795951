export function extractSemanticVersions(versionStr: string) {
  const matches = /.*(\d+)\.(\d+)\.(\d+).*/.exec(versionStr);

  if (!matches) {
    return null;
  }

  return {
    major: Number(matches[1]),
    minor: Number(matches[2]),
    patch: Number(matches[3]),
  };
}
