import { useState, useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import { Container } from "components/SimpleRegistrations/Container";
import { Header } from "components/SimpleRegistrations/Header";
import { Body } from "components/SimpleRegistrations/Body";
import { Footer } from "components/SimpleRegistrations/Footer";

import { SlideModal } from "components/Modal/SlideModal";
import { LandscapeBirthdayInput } from "components/SimpleRegistrations/landscape/BirthdayInput";
import { PortraitBirthdayInput } from "components/SimpleRegistrations/portrait/BirthdayInput";
import { LandscapeNameInput } from "components/SimpleRegistrations/landscape/NameInput";
import { PortraitNameInput } from "components/SimpleRegistrations/portrait/NameInput";
import { LandscapeAcquisitionChannelsSelect } from "components/SimpleRegistrations/landscape/AcquisitionChannelsSelect";
import { PortraitAcquisitionChannelsSelect } from "components/SimpleRegistrations/portrait/AcquisitionChannelsSelect";
import { InfoConfirm } from "components/SimpleRegistrations/landscape/InfoConfirm";

export function CreateNewRegistrationModal({ open, onClose }) {
  const {
    isLandscape,
    setLoading,
    smsEnabled,
    setBirthday,
    setName,
    setAcquisitionChannelsIds,
    setSmsEnabled,
    setEventSmsEnabled,
    page,
    setPage,
    setBirthdayCurrentIdx,
  } = useContext(SimpleRegistrationsContext);

  const [usePageIndicator, setUsePageIndicator] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  const onChangePrevPage = () => {
    const prevPage = page - 1;
    if (prevPage === 1) {
      setBirthday("");
      setName("");
      setBirthdayCurrentIdx(0);
    }
    if (prevPage === 2) {
      setName("");
      setAcquisitionChannelsIds([]);
    }
    if (prevPage === 3) {
      setAcquisitionChannelsIds([]);
      setSmsEnabled(false);
      setEventSmsEnabled(false);
    }
    if (prevPage === 4) {
      setSmsEnabled(false);
      setEventSmsEnabled(false);
    }
    if (page > 1) {
      setPage(prevPage);
    } else {
      onClose();
    }
    setOpenAlert(false);
  };

  return (
    <>
      {openAlert && (
        <ConfirmModal
          body={
            <div style={{ fontWeight: "bold" }}>
              <div style={{ fontSize: "2vw", marginBottom: "5%" }}>안내</div>
              <div>
                이전 단계로 이동할까요?
                <br />
                입력중인 정보는 저장되지 않습니다.
              </div>
            </div>
          }
          cancelText="아니오"
          confirmText="예"
          onClose={() => setOpenAlert(false)}
          onConfirm={() => onChangePrevPage()}
          open={openAlert}
        />
      )}
      <SlideModal open={open}>
        <Container>
          <Header
            onOpen={setOpenAlert}
            onClose={onClose}
            usePageIndicator={usePageIndicator}
            page={page}
            total={3}
          />
          <Body>
            {page === 1 &&
              (isLandscape ? (
                <LandscapeBirthdayInput onClickNext={() => setPage(2)} />
              ) : (
                <PortraitBirthdayInput onClickNext={() => setPage(2)} />
              ))}
            {page === 2 &&
              (isLandscape ? (
                <LandscapeNameInput onClickNext={() => setPage(3)} />
              ) : (
                <PortraitNameInput onClickNext={() => setPage(3)} />
              ))}
            {page === 3 &&
              (isLandscape ? (
                <LandscapeAcquisitionChannelsSelect />
              ) : (
                <PortraitAcquisitionChannelsSelect />
              ))}
            {page === 4 && <InfoConfirm />}
          </Body>
          {page === 3 && (
            <Footer
              text="다음"
              onClick={() => {
                setUsePageIndicator(false);
                setPage(4);
              }}
            />
          )}
          {page === 4 && (
            <Footer
              text="접수하기"
              disabled={!smsEnabled}
              onClick={() => {
                onClose(true);
                setLoading(true);
              }}
            />
          )}
        </Container>
      </SlideModal>
    </>
  );
}
