import { MenuItem, styled } from "@mui/material";

export const ContextMenuItem = styled(MenuItem)`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  line-height: 11px;
  text-align: center;
  color: #000000;
  padding: 12px 14px;
  width: 180px;
  & svg:first-of-type {
    width: 14px;
  }
  &:not(:last-of-type) {
    border-bottom: ${({ noneborder }: any) =>
      noneborder ? "none" : "1px solid #e9e9ed"};
  }
`;

export const ContextMenuItemSmall = styled(MenuItem)`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0;
  text-align: center;
  padding: 6px 8px;

  &:not(:last-of-type) {
    border-bottom: ${({ noneborder }: any) =>
      noneborder ? "none" : "1px solid #e9e9ed"};
  }
`;
