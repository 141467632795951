import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Icon from "components/Icon";

export function DefaultModal({ title, body, onClose, open, ...props }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: "350px" } }}
      {...props}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ marginLeft: "auto" }}
          onClick={onClose}
        >
          <Icon variant="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ fontSize: "14px" }}>{body}</DialogContent>
    </Dialog>
  );
}
