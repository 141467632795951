import { useCallback, useState } from "react";

export const useProgress = (defaultValue = false) => {
  const [progress, setProgress] = useState(defaultValue);

  const withProgress = useCallback(async (func: () => void) => {
    try {
      setProgress(true);
      return await func();
    } finally {
      setProgress(false);
    }
  }, []);

  return {
    progress,
    withProgress,
  };
};
