import { useCallback, useEffect, useState, useMemo } from "react";
import { debounce } from "lodash";
import { IconButton } from "@mui/material";
import Icon from "components/Icon";
import { useSnackbar } from "SnackbarProvider";
import { useApi } from "providers/api";
import { SlideModal, Wrapper, Header } from "components/Modal/SlideModal";
import { Detail } from "pages/AppointmentPage/detail/Detail";
import { RegistrationDetail } from "pages/AppointmentPage/detail/RegistrationDetail";
import { getCustomerName } from "utils/getCustomerName";
import {
  Body,
  CustomerName,
  iconButtonStyle,
  Avatar,
} from "./AppointmentInfoModal.styled";

type AppointmentInfoModalProps = {
  customerData?: any;
  appointmentData?: any;
  open: boolean;
  onClose: () => void;
  reload: any;
};

export function AppointmentInfoModal({
  customerData,
  appointmentData,
  open,
  onClose,
  reload,
}: AppointmentInfoModalProps) {
  const api = useApi();
  const sb = useSnackbar();
  const [appointment, setAppointment]: any = useState(null);
  const [customer, setCustomer]: any = useState(null);
  const [deleted, setDeleted] = useState<null | boolean>(null);
  const [profileImage, setProfileImage]: any = useState(null);

  const blobUrl = useMemo(() => {
    if (!profileImage) return null;
    return profileImage && profileImage.thumbnailUrl;
  }, [profileImage]);

  const loadProfileImage = useCallback(
    async (profileImageId: number) => {
      const res = await api.getImage(profileImageId);
      const payload = await res.json();
      setProfileImage(payload.data);
    },
    [api]
  );

  const loadAppointment = useCallback(
    debounce(async () => {
      if (!appointmentData?.id) return;
      try {
        const res = await api.getAppointment(appointmentData.id);
        const payload = await res.json();
        setAppointment(payload.data ?? appointmentData);
      } catch (e: any) {
        if (e.code === 404) {
          setDeleted(true);
          sb.open("삭제된 예약입니다.");
        } else {
          setDeleted(false);
        }
      }
    }, 500),
    [api, appointmentData]
  );

  const loadRegistration = useCallback(
    debounce(async () => {
      if (!appointmentData?.id) return;
      try {
        const res = await api.getRegistration(appointmentData.id);
        const payload = await res.json();
        setAppointment(payload.data ?? appointmentData);
      } catch (e: any) {
        if (e.code === 404) {
          setDeleted(true);
          sb.open("삭제된 접수입니다.");
        } else {
          setDeleted(false);
        }
      }
    }, 500),
    [api, appointmentData]
  );

  useEffect(() => {
    if (appointmentData?.type === "APPOINTMENT") {
      loadAppointment();
    }
    if (appointmentData?.type === "REGISTRATION") {
      loadRegistration();
    }
  }, [loadAppointment, loadRegistration, appointmentData]);

  const loadCustomer = useCallback(
    debounce(async () => {
      if (!customerData?.id) return;
      try {
        const res = await api.getCustomer(customerData.id);
        const payload = await res.json();
        setCustomer(payload.data ?? customerData);
        if (payload.data.profileImageId ?? customerData.profileImageId) {
          loadProfileImage(
            payload.data.profileImageId ?? customerData.profileImageId
          );
        }
      } catch (e: any) {
        if (e.code === 404) {
          setDeleted(true);
          sb.open("삭제된 고객입니다.");
        } else {
          setDeleted(false);
        }
      }
    }, 500),
    [api, customerData]
  );

  useEffect(() => {
    loadCustomer();
  }, [loadCustomer]);

  const data = useMemo(() => {
    if (appointment !== null && customer !== null) {
      return { ...appointment, customer };
    }
    return null;
  }, [appointment, customer]);

  return (
    <SlideModal open={open}>
      <Wrapper>
        <Header>
          <IconButton
            sx={iconButtonStyle}
            onClick={() => {
              setAppointment(null);
              onClose();
            }}
          >
            <Icon variant="back_arrow" />
          </IconButton>
          <CustomerName>
            <Avatar src={blobUrl} className={`${customerData?.sex || ""}`} />
            <b>{getCustomerName(customerData)}</b>
          </CustomerName>
        </Header>
        <Body>
          {appointmentData?.type === "APPOINTMENT" && (
            <Detail
              profileUrl={blobUrl}
              data={data}
              onClose={onClose}
              edit={!deleted}
              reload={() => {
                reload();
                loadAppointment();
              }}
            />
          )}
          {appointmentData?.type === "REGISTRATION" && (
            <RegistrationDetail
              profileUrl={blobUrl}
              data={data}
              onClose={onClose}
              edit={!deleted}
              reload={() => {
                reload();
                loadRegistration();
              }}
            />
          )}
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
