import { styled as muiStyled } from "@mui/material";

export const DefaultStatItem = muiStyled("div")`
  display: flex;
  column-gap: 2px;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 2px;
`;

export const StatItemWrapper = muiStyled("div")`
  display: flex;
  column-gap: 2px;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 2px;
`;

export const StatLabel = muiStyled("span")`
  font-weight: ${({ bold }: { bold?: boolean }) => (bold ? 700 : 400)};
  color: ${({ color, theme }) =>
    color === "red" ? theme.palette.error["main"] : theme.palette.grey["700"]};
`;

export const StatCount = muiStyled("span")`
  font-weight: 700;
  color: ${({ color, theme }) =>
    color === "red" ? theme.palette.error["main"] : theme.palette.grey["700"]};
`;

export const LayoutStatCount = muiStyled("span")`
  font-weight: 700;
  color: ${({ color, theme }) =>
    color === "red" ? theme.palette.error["main"] : theme.palette.grey["700"]};
`;

export const Wrapper = muiStyled("div")`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.palette.grey["700"]};
  margin-right: 16px;
`;

export const StatDivision = muiStyled("div")`
  padding: 0px 2px;
  font-size: 10px;
  color: #dee2ec;
`;

export const StatGroup = muiStyled("div")`
  display: flex;
  background: #E6EEF8;
  border-radius: 2px;
  margin-right: 5px;
  align-items: center;
  padding: 4px 6px;
`;
