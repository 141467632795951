import { styled, css } from "@mui/material";

export const Iframe = styled("iframe")(() => ({
  border: 0,
  width: "450px",
  height: "350px",
}));

export const P = styled("p")`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;
