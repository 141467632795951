import { readAndCompressImage } from "browser-image-resizer";
import { getMaxDimesionSize } from "./file";

const { width, height } = getMaxDimesionSize();

const compareMagicNumber = (header: string) => {
  switch (header) {
    case "89504e47":
      return "image/png";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    case "47494638": // image/gif
    default:
      return "unknown";
  }
};

export const readFileMime = (f: File) => {
  return new Promise<string>((res, rej) => {
    const fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      const arr = new Uint8Array((e.target as any).result).subarray(0, 4);
      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      res(compareMagicNumber(header));
    };

    fileReader.onerror = () => {
      rej(new Error("filereader error"));
    };

    fileReader.onabort = () => {
      rej(new Error("filereader abort"));
    };

    fileReader.readAsArrayBuffer(f);
  });
};

export const bytesToSize = (
  bytes: number,
  size?: "Bytes" | "KB" | "MB" | "GB" | "TB"
) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0)
    return {
      size: 0,
      format: "n/a",
      print: `0 n/a`,
    };
  const i = size
    ? sizes.indexOf(size)
    : parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
  if (i === 0)
    return {
      size: bytes,
      format: sizes[i],
      print: `${bytes} ${sizes[i]}`,
    };
  return {
    size: Number((bytes / 1024 ** i).toFixed(1)),
    format: sizes[i],
    print: `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`,
  };
};

export const getImageDimensions = (file: File) => {
  const dataURL = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.onabort = (error) => {
      reject(error);
    };
    img.src = dataURL;
  });
};

export const resizeImageFile = (
  file: File,
  _config?: {
    quality: number;
    maxWidth?: number;
    maxHeight?: number;
    debug?: boolean;
  }
) => {
  const config = _config || {
    quality: 0.9,
    maxWidth: width,
    maxHeight: height,
    debug: true,
  };
  return new Promise((resolve, reject) =>
    readAndCompressImage(file, config)
      .then((resizedImage) => {
        const newFile = new File([resizedImage], file.name, {
          type: file.type,
        });
        resolve(newFile);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getFileExtensionFromBlob = (blob: Blob) => {
  const mimeType = blob.type;
  const parts = mimeType.split("/");
  if (parts.length === 2) {
    return parts[1];
  } else {
    return null; // 확장자를 찾을 수 없는 경우
  }
};
