import { useState, useMemo } from "react";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { AppointmentInfoModal } from "modals/AppointmentInfoModal";
import { EmptyView } from "components/EmptyView";
import { StatusInfoBadge } from "components/StatusInfoBadge";
import { useAppointment } from "hooks/useAppointment";
import { InfiniteScroll } from "components/InfiniteScroll";
import { RoundFloat } from "components/Button/RoundFloat";
import { CreateAppointmentModal } from "modals/CreateAppointmentModal";
import Icon from "components/Icon";
import { useSearchParams } from "react-router-dom";
import {
  Memo,
  SortColumnWrapper,
  Box,
} from "pages/Customer/detail/AppointmentTable.styled";
import { useClinic } from "providers/clinic";

export function AppointmentTable({
  customer,
  deleted,
}: {
  customer?: any;
  deleted?: any;
}) {
  const clinic = useClinic();
  const [searchParams] = useSearchParams();
  const departmentId = useMemo(
    () =>
      searchParams.get("departmentId")
        ? searchParams.get("departmentId")
        : null,
    [searchParams]
  );

  const { appointments, updateOrder, updatePage, reload, onChangeStatus } =
    useAppointment({
      departmentId,
      customerId: customer?.id,
      initOrder: {
        order: "desc",
        target: "date",
        sub: "startHour desc",
      },
      clinic: clinic.clinic,
    });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [appointment, setAppointment] = useState(null);

  const openAppointmentInfoModal = (data: any) => {
    setOpenInfoModal(true);
    setAppointment(data);
  };

  const closeAppointmentInfoModal = () => {
    setOpenInfoModal(false);
    setAppointment(null);
  };

  return (
    <div style={{ height: "calc(100% - 40px)" }}>
      <InfiniteScroll action={updatePage}>
        <Table
          stickyHeader
          sx={{ background: "#fff", borderBottom: "1px solid #F9FBFF" }}
          className="appointment-table"
        >
          {!appointments?.length && (
            <caption>
              <EmptyView text="예약내역이 없습니다." />
            </caption>
          )}
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  padding: "13px 0",
                  color: "#282828",
                  fontWeight: 700,
                  borderBottom: "1px solid #F9FBFF",
                },
              }}
            >
              <TableCell align="center">상태</TableCell>
              <TableCell align="center">
                <SortColumnWrapper
                  onClick={() => updateOrder("date", "startHour desc")}
                >
                  일자
                  <Icon variant="sort" />
                </SortColumnWrapper>
              </TableCell>
              <TableCell align="center">방문시간</TableCell>
              <TableCell align="center" width="10%">
                부서
              </TableCell>
              <TableCell align="center" sx={{ width: "40%" }}>
                메모
              </TableCell>
            </TableRow>
          </TableHead>
          {Boolean(appointments?.length) && (
            <TableBody>
              {appointments.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "& td": {
                      padding: "10px 0",
                      color: "#282828",
                      borderColor: "#F9FBFF",
                    },
                  }}
                >
                  <TableCell align="center">
                    <StatusInfoBadge
                      appointment={row}
                      onClickStatus={(v: any) => {
                        onChangeStatus(row, v.status);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => openAppointmentInfoModal(row)}
                  >
                    {format(new Date(row.date), "yyyy-MM-dd")}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => openAppointmentInfoModal(row)}
                  >
                    {row.startHour} - {row.endHour}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => openAppointmentInfoModal(row)}
                  >
                    <Box className="department-name" component="span">
                      {row.department.name}
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => openAppointmentInfoModal(row)}>
                    <Memo dangerouslySetInnerHTML={{ __html: row.memo }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <AppointmentInfoModal
          open={openInfoModal}
          customerData={customer}
          appointmentData={appointment}
          onClose={closeAppointmentInfoModal}
          reload={reload}
        />
      </InfiniteScroll>
      {!deleted && (
        <RoundFloat open onClick={() => setOpenCreateModal(true)}>
          <CreateRoundedIcon />
        </RoundFloat>
      )}
      {openCreateModal && (
        <CreateAppointmentModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          customer={customer}
          onSaveCallback={reload}
        />
      )}
    </div>
  );
}
