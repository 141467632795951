import { styled, css } from "@mui/material";
import { hexToRgbaString } from "utils/colorUtil";
import { StatusConfig } from "../ChartCard";
import { AppointmentStatus } from "./appointmentStatus";

const ChartType = styled("div")`
  padding: 0 4px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;

  ${({ type }: { type: string }) => css`
    background-color: ${hexToRgbaString(StatusConfig[type].color, 0.6)};
  `}
`;

export const ChartTypeBadge = ({ item }: { item: any }) => {
  if (item.status === "NONE") return null;

  let type = "";
  if (item.type === "APPOINTMENT") {
    if (item.status === AppointmentStatus.canceled) {
      type = item.status;
    } else if (item.status === AppointmentStatus.noShow) {
      type = item.status;
    } else {
      type = item.type;
    }
  } else {
    if (item.type === "REGISTRATION") {
      type = item.type;
    }
  }

  return <ChartType type={type}>{StatusConfig[type].label}</ChartType>;
};
