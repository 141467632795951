import { styled, css } from "@mui/material";
import { TabType } from "constant/Tabs";

export const MemoWrapper = styled("div")`
  margin-top: 5px;
  ${({ type }: { type: string }) =>
    type === TabType.appointment &&
    css`
      background-color: rgba(251, 205, 177, 0.1);
      border-radius: 4px;
      padding: 8px 10px;
    `}
  ${({ type }: { type: string }) =>
    type === TabType.registration &&
    css`
      background-color: rgba(171, 192, 251, 0.1);
      border-radius: 4px;
      padding: 8px 10px;
    `}
    p {
    margin: 0;
  }
`;
