import { styled, Box } from "@mui/material";
import { Button as _Button } from "components/Button";

export const Button = styled(_Button)(
  () => `
  height: 54px !important;
`
);

export const ButtonWrapper = styled(Box)`
  width: 100%;
`;
