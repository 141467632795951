import { unusedCode } from "utils/unusedCodeUtil";

export const getOptions = (data = [], value?: any, name?: string) => {
  const options = [...data];

  if (!value) return options;

  if (
    (value.status === "inactive" || value.visible === false) &&
    !options.find((f: any) => f.id === value.id)
  ) {
    return [
      {
        ...value,
        [name || "name"]: unusedCode.getNameByUnusedValue({
          ...value,
          visible: false,
        }),
      },
      ...options,
    ];
  } else if (
    (value.status === "deleted" ||
      value.isDeleted === true ||
      value.deletedAt !== null) &&
    !options.find((f: any) => f.id === value.id)
  ) {
    return [
      {
        ...value,
        [name || "name"]: unusedCode.getNameByUnusedValue({
          ...value,
          isDeleted: true,
        }),
      },
      ...options,
    ];
  }
  return options;
};
