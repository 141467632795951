import { ReactNode, MouseEvent } from "react";
import { ToolbarButton as _ToolbarButton } from "components/ToolbarButton.styled";

type ToolbarButtonProps = {
  active?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  paddingSize?: number;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
};

export const ToolbarButton = (props: ToolbarButtonProps) => {
  return <_ToolbarButton {...props} />;
};
