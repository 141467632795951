import { styled, css } from "@mui/material";

export const ToolbarButton = styled("button")`
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  padding: 0 ${({ paddingSize }: any) => paddingSize || 8}px;
  height: 100%;
  min-width: 30px;

  border: 1px solid rgba(222, 226, 236, 1);
  background: rgba(255, 255, 255, 1);
  color: rgba(32, 32, 32, 1);

  ${({ active }: any) => {
    return active
      ? css`
          background: rgba(41, 49, 66, 1);
          color: rgba(255, 255, 255, 1);
        `
      : css`
          border: 1px solid rgba(222, 226, 236, 1);
          background: rgba(255, 255, 255, 1);
          color: rgba(32, 32, 32, 1);
        `;
  }}
`;
