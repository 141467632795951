export const RegistrationType = {
  consulting: "CONSULTING",
  treatment: "TREATMENT",
  surgery: "SURGERY",

  getName(type: any) {
    switch (type) {
      case RegistrationType.consulting:
        return "상담접수";
      case RegistrationType.treatment:
        return "진료접수";
      case RegistrationType.surgery:
        return "시/수술접수";
    }

    return "유효하지 않은 접수 종류";
  },
};

export const RegistrationTypeGroup = [
  RegistrationType.consulting,
  RegistrationType.treatment,
  RegistrationType.surgery,
];
