import { ConfirmModal } from "components/Modal/ConfirmModal";
import { ImmediateSmsConfirmPopup } from "modals/ImmediateSmsConfirmPopup";

export const ImmediateSmsConfirmModal = ({
  smsRules,
  onConfirm,
  onCancel,
}: {
  smsRules: Array<any>;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <ConfirmModal
      open={true}
      onConfirm={() => onConfirm()}
      onClose={() => onCancel()}
      cancelText="미전송"
      confirmText="즉시전송"
      body={<ImmediateSmsConfirmPopup smsRules={smsRules} />}
    />
  );
};
