import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  width: 100%;
`;

export const StyledInput = styled(TextField)`
  border: 0;
  width: 100%;
  height: 100%;
  > * {
    padding-right: 0 !important;
  }
  input {
    font-size: 12px;
    height: 29px;
    padding: 0 8px;
    background-color: white;
  }
`;

export const ResetButton = styled("button")`
  svg {
    font-size: 11px;
  }
  position: absolute;
  right: 10px;
  color: #a1b1ca;
  height: 100%;
  &:hover {
    color: #4a4e70;
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  p {
    font-size: 11px;
    padding-right: 8px;
  }
`;
