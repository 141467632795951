import { useMemo } from "react";
import { useApi } from "providers/api";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "components/Button";
import { Stack, Dialog, IconButton } from "./TemplateSelector.styled";
import useSWR from "swr";
import Icon from "./Icon";

const TemplateSelector = ({
  onClose,
  onSelect,
}: {
  onClose: () => void;
  onSelect: (item: { id: number }) => void;
}) => {
  const { surveyApi } = useApi();
  const { data } = useSWR("survey-templates", async () => {
    const response = await surveyApi.templateList();
    return response.data;
  });

  const handleClick = (item: { id: number }) => {
    onSelect(item);
  };

  const templateList = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: "380px" } }}
    >
      <DialogTitle>
        {"문진 선택"}
        <IconButton className="m-l close-btn" onClick={onClose}>
          <Icon variant="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection={"column"} className="template-list">
          {templateList.map((item, index) => {
            return (
              <Button
                styled="text"
                key={`template-${index}`}
                onClick={() => handleClick(item)}
              >
                {item.title}
              </Button>
            );
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TemplateSelector;
