import { Snackbar } from "@mui/material";
import { IconButton } from "./SnackbarProvider.styled";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarContextValue {
  open: (message: ReactNode) => void;
}

const SnackbarContext = createContext<SnackbarContextValue | null>(null);

export function useSnackbar() {
  return useContext(SnackbarContext) as SnackbarContextValue;
}

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [snackPack, setSnackPack] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [messageInfo, setMessageInfo] = useState<any>(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev: any) => prev.slice(1));
      setShow(true);
    } else if (snackPack.length && messageInfo && show) {
      setShow(false);
    }
  }, [snackPack, messageInfo, show]);

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const open = useCallback((message: ReactNode) => {
    setSnackPack((prev: any) => [
      ...prev,
      { message, key: new Date().getTime() },
    ]);
  }, []);

  const sb = useMemo(
    () => ({
      open,
    }),
    [open]
  );

  return (
    <SnackbarContext.Provider value={sb}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={show}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </SnackbarContext.Provider>
  );
}
