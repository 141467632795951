import { Tab as MuiTab, Tabs as MuiTabs, styled } from "@mui/material";

export const Tabs = styled(MuiTabs)(
  () => `
  min-height: 29px;

  .MuiTabs-flexContainer {
    gap: 32px;
  }
`
);

export const Tab = styled(MuiTab)(
  () => `
  padding:0;
  min-width: 30px;
  min-height: 29px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  padding-bottom: 10px;
  color: #2D2D2D;

  &.Mui-selected {
    font-weight: 700;
  }
`
);
