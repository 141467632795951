import styled from "styled-components";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

const Head = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 16px;
  border-bottom: 1px solid #dee2ec;
  top: 0;
  background: #fff;
  h1 {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
`;

const CloseButton = styled(Button)`
  padding: 0;
  min-width: 24px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  color: #202020;
  margin-left: auto;

  svg,
  path {
    width: 24px;
    height: 24px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

type ModalHeaderProps = {
  title?: string;
  titleNode?: ReactNode;
  onClose: () => void;
};

export const ModalHeader = ({
  title,
  titleNode,
  onClose,
}: ModalHeaderProps) => {
  return (
    <Head>
      <TitleWrapper>
        <h1>{title}</h1>
        {titleNode}
      </TitleWrapper>
      <CloseButton onClick={() => onClose()}>
        <CloseIcon />
      </CloseButton>
    </Head>
  );
};
