import { ConfirmModal } from "components/Modal/ConfirmModal";

type DeleteConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function DeleteConfirmDialog({
  open,
  onClose,
  onConfirm,
}: DeleteConfirmDialogProps) {
  function onClickConfirm(e: any) {
    e.stopPropagation();
    onConfirm();
    onClose();
  }

  function onClickCancel(e: any) {
    e.stopPropagation();
    onClose();
  }

  return (
    <ConfirmModal
      body="삭제하시겠습니까?"
      confirmText="삭제"
      onClose={onClickCancel}
      onConfirm={onClickConfirm}
      open={open}
    />
  );
}
