import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { ReactElement } from "react";
import {
  Wrapper as _Wrapper,
  Header as _Header,
  Body as _Body,
} from "./SlideModal.styled";

export const Wrapper = _Wrapper;

export const Header = _Header;

export const Body = _Body;

type SlideModalProps = {
  open: boolean;
  children: ReactElement<any, any>;
};

export function SlideModal({ open, children }: SlideModalProps) {
  return (
    <Modal open={open} closeAfterTransition hideBackdrop>
      <Slide in={open} direction="up">
        {children}
      </Slide>
    </Modal>
  );
}
