import styled from "styled-components";
import { TdStyle } from "./DataTableUtils.styled";
import { DataCell } from "./DataTable";

const Td = styled.td`
  ${TdStyle}
  border-bottom: unset;
`;

type RowGroupProps = {
  item: any;
  column: any;
};

export const RowGroup = ({ item, column }: RowGroupProps) => {
  return (
    <Td key={`td-${item.id}`} colSpan={100}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <DataCell item={item} column={column} index={0} />
      </div>
    </Td>
  );
};
