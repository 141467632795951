import styled, { css } from "styled-components";
import { IconButton } from "~/components/IconButton/IconButton";
import { styled as muiStyled } from "@mui/material";
import Icon from "./Icon";

export const Button = muiStyled(IconButton)(
  ({ theme }) => `
  width: 16px;
  height: 16px;
  padding: 2px;
  margin: 0 auto;
  color: #a1b1ca;
  svg {
    width: 100%;
    height: 100%;
  }

  &.selected {
    svg,path {
      fill: ${theme.palette.grey["700"]};
    }
  }
`
);

export const EditIcon = styled((props) => <Icon variant="edit" {...props} />)<{
  disabled?: boolean;
}>`
  ${({ disabled }: any) =>
    disabled &&
    css`
      path {
        fill: currentColor;
        color: #d7e3f1;
      }
    `}
`;
