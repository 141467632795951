import { Button, EditIcon } from "./EditButton.styled";

type EditButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

export const EditButton = ({
  onClick,
  disabled = false,
  ...props
}: EditButtonProps) => {
  return (
    <Button
      variant="transparent"
      onClick={() => {
        !disabled && onClick();
      }}
      {...props}
    >
      <EditIcon disabled={disabled} />
    </Button>
  );
};
