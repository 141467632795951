export const color = {
  primary: {
    unoblue: "#2C62F6",
    deepblue: "#293142",
  },
  bluegrey: {
    700: "#4A4E70",
    600: "#A1B1CA",
    500: "#D7E3F1",
    300: "#E6EEF8",
    200: "#F3F8FF",
    100: "#F9FBFF",
  },
  grey: {
    700: "#2D2D2D",
    600: "#3A3A3A",
    500: "#BBBBBB",
    300: "#E6E6E6",
    200: "#F1F1F1",
  },
  line: "#DEE2EC",
  alert: "#EB5757",
  background: {
    grey: "#EDEFF1",
    white: "#FBFCFC",
  },
};

export const typo = {
  fontFamily: "'Noto Sans KR', sans-serif",
  heading: {
    1: {
      fontWeight: "bold",
      fontSize: "15px",
    },
    2: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    3: {
      fontWeight: "bold",
      fontSize: "13px",
    },
    4: {
      fontWeight: "500",
      fontSize: "13px",
    },
  },
  title: {
    1: {
      fontWeight: "bold",
      fontSize: "12px",
    },
  },
  body: {
    1: {
      fontWeight: "500",
      fontSize: "12px",
    },
    2: {
      fontWeight: "500",
      fontSize: "11px",
    },
    3: {
      fontWeight: "bold",
      fontSize: "10px",
    },
    4: {
      fontWeight: "500",
      fontSize: "10px",
    },
    5: {
      fontWeight: "bold",
      fontSize: "8px",
    },
    6: {
      fontWeight: "500",
      fontSize: "8px",
    },
  },
};
