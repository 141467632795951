import { styled } from "@mui/material";

export const ListItemWrapper = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColorDot = styled("div")`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: 2px;
`;

export const StatusText = styled("span")`
  margin-left: 8px;
`;

export const StatusInfo = styled("div")`
  display: flex;
  align-items: center;
`;
