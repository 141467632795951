import {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Link,
} from "./ConfirmCertNumber.styled";
import { addMinutes, differenceInMilliseconds } from "date-fns";
import { useAuth } from "providers/auth";
import { useApi } from "providers/api";
import { useSnackbar } from "SnackbarProvider";
import { useNavigate } from "react-router-dom";
import { delay } from "utils/common";

type CinfirmCertNumberProps = {
  email: string;
  autoLogin: boolean;
};

const ConfirmCertNumber = (props: CinfirmCertNumberProps) => {
  const { email, autoLogin } = props;
  const [confirmNumber, setConfirmNumber] = useState("");
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [countTime, setCountTime] = useState<Date | null>(null);
  const [timeLimit, setTimeLimit] = useState(0);
  const numberRef = useRef<any>(null);
  const auth: any = useAuth();
  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    startCount();
    return () => {
      setTimeLimit(0);
      setCountTime(null);
      setDisabled(false);
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      resetCount();
    }
  }, [disabled]);

  useEffect(() => {
    if (countTime) {
      countDown(countTime);
    }
  }, [countTime]);

  useEffect(() => {
    if (timeLimit < 0) {
      setDisabled(true);
    }
  }, [timeLimit]);

  const startCount = (minute?: number) => {
    if (timeLimit > 0) {
      return;
    }
    const count = addMinutes(new Date(), minute || 3);
    setCountTime(count);
  };

  const resetCount = () => {
    setCountTime(null);
    setTimeLimit(0);
  };

  const countDown = useCallback(
    async (time: Date) => {
      const timeLimit = differenceInMilliseconds(time, new Date());
      if (timeLimit > 0) {
        await delay(1000);
        setTimeLimit(timeLimit - 1000);
        countDown(time);
      }
    },
    [countTime]
  );

  const leftTimeFormat = (time: number) => {
    const mins = Math.floor(time / (1000 * 60));
    const secs = Math.floor(time / 1000);
    return `${String(mins).padStart(2, "0")}:${String(
      secs - mins * 60
    ).padStart(2, "0")}`;
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleChangeConfirmNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setConfirmNumber(e.target.value);
  };

  const handleSendConfirmNumber = async () => {
    try {
      await api.sendCertificationEmail(email);
      snackbar.open(
        "인증번호가 재전송 되었습니다. 이메일로 전송한 인증번호를 입력해주세요."
      );
      setDisabled(false);
      startCount();
      numberRef.current && numberRef.current.focus();
    } catch (error) {
      console.error(error);
      snackbar.open("인증번호 재전송을 실패하였습니다.");
    }
  };

  const handleSubmit = async () => {
    if (confirmNumber === "") {
      setError(true);
      numberRef.current && numberRef.current.focus();
      return snackbar.open("인증번호를 입력해주세요.");
    }
    try {
      await auth.confirmCertification(email, confirmNumber, autoLogin);
      navigate("/", { replace: true });
    } catch (error) {
      setError(true);
      numberRef.current && numberRef.current.focus();
      snackbar.open("올바른 인증번호가 아닙니다. 다시 확인해주세요.");
    }
  };

  return (
    <Box className="confirm-page-wrapper">
      <Box className="confirm-certification-number-wrapper">
        <form className={`${disabled ? "disabled" : ""}`}>
          <Stack className={"form-wrapper"} direction={"column"} gap={"10px"}>
            <Typography variant="subtitle1">
              이메일(아이디)로 전송한 인증번호를 입력해주세요.
            </Typography>
            <TextField
              type="text"
              className={`confirm-input ${error ? "error" : ""}`}
              placeholder="인증번호를 입력하세요"
              value={confirmNumber}
              onKeyDown={handleKeyPress}
              onChange={handleChangeConfirmNumber}
              disabled={disabled}
              inputRef={numberRef}
              autoFocus
            />
            <Typography variant="body2" className="time-count">
              {leftTimeFormat(timeLimit)}
            </Typography>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={disabled}
            >
              확인
            </Button>
            {disabled && (
              <Button
                variant="text"
                className="resend-btn"
                onClick={handleSendConfirmNumber}
              >
                인증번호 재전송
              </Button>
            )}
          </Stack>
        </form>
      </Box>
      <Box className="footer">
        <Typography variant="body1">
          사용문의{" "}
          <Link href={"mailto:info@unobeauty.kr"} target="_blank">
            info@unobeauty.kr
          </Link>
        </Typography>
        <Typography variant="body1">
          Copyright ⓒ 2012-2022 UNOBEAUTY. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
export default ConfirmCertNumber;
