import {
  Box as MuiBox,
  Button as MuiButton,
  Stack as MuiStack,
  styled,
} from "@mui/material";

export const Stack = styled(MuiStack)(
  () => `

`
);

export const Box = styled(MuiBox)(() => ``);

export const Button = styled(MuiButton)(
  () => `
  white-space: nowrap;
  height: 34px;
`
);
