import { styled, css } from "@mui/material";

const Wrapper = styled("div")`
  font-size: 12px;

  ${({ theme }) =>
    css`
      color: ${theme.palette.bluegrey[600]};
    `}
`;

type NoOptionsTextProps = {
  value?: string;
};

export const NoOptionsText = ({ value }: NoOptionsTextProps) => {
  return <Wrapper>{value ?? "검색결과가 없습니다."}</Wrapper>;
};
