import { useState } from "react";
import styled from "styled-components";
import { Checkbox } from "~/components/Checkbox";
import { FormControlLabel } from "@mui/material";
import SMSPreviewButton from "./SMSPreviewButton";
import Radio from "~/components/Radio";
import { ScheduledSmsPreviewModal } from "~/modals/ScheduledSmsPreviewModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 12px;
  color: #2d2d2d;
`;

const Title = styled.div`
  height: 24px;
  align-items: center;
  display: flex;
`;

const Question = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
`;

const AutoSMSListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 4px;
  row-gap: 5px;
  font-size: 12px;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    > label {
      margin-right: 4px;
    }
  }
`;

const CheckboxButton = styled(FormControlLabel)`
  margin-left: 0;
  padding: 0;

  .MuiTypography-root {
    font-size: inherit;
    line-height: 1rem;
  }
`;

const CheckboxIcon = styled(Checkbox)`
  margin-left: 0px;
  margin-right: 2px;
  padding: 0;
  font-size: 18px;
`;

type SMSListCheckboxProps = {
  scheduledAt?: string;
  value?: any[];
  onChange: (value: any) => void;
  options?: any[];
};

export const SMSListCheckbox = ({
  value = [],
  onChange,
  options = [],
}: SMSListCheckboxProps) => {
  const toggleCheckBox = (e: any, id: any) => {
    if (e.target.checked) {
      onChange([...value, id]);
    } else {
      onChange(value.filter((v) => v !== id));
    }
  };

  const getLabel = (item: any) => {
    switch (item.smsScheduleType) {
      case "scheduled": {
        if (item.daysOffset === 0) {
          return `예약일 
            ${item.scheduleTime} 전송`;
        } else {
          return `예약일 ${Math.abs(item.daysOffset)}일 ${
            item.daysOffset < 0 ? "전" : "후"
          } ${item.scheduleTime} 전송`;
        }
      }

      case "immediate":
        return "예약완료 후 즉시전송";
    }
  };

  return (
    <AutoSMSListWrapper>
      <ul>
        {options.map((v) => (
          <li key={v.id}>
            <CheckboxButton
              control={
                <CheckboxIcon
                  checked={value.includes(v.id)}
                  onChange={(e) => toggleCheckBox(e, v.id)}
                  color="primary"
                />
              }
              label={getLabel(v)}
            />
          </li>
        ))}
      </ul>
    </AutoSMSListWrapper>
  );
};

type SMSRadioProps = {
  value?: boolean;
  onChange: (value?: any) => void;
  data: any[];
};

export const SMSRadio = ({ value = true, onChange, data }: SMSRadioProps) => {
  const [showScheduledSmsModal, setShowScheduledSmsModal] = useState(false);

  const onClickPreview = () => {
    setShowScheduledSmsModal(true);
  };

  return (
    <Wrapper>
      <Title>
        <Question>예약문자 전송 선택</Question>
        <SMSPreviewButton onClick={() => onClickPreview()} />
      </Title>
      <Radio
        value={String(value)}
        onChange={(v) => {
          onChange(JSON.parse(v));
        }}
        options={[
          { label: "전송", value: "true" },
          { label: "미전송", value: "false" },
        ]}
      />
      {showScheduledSmsModal && (
        <ScheduledSmsPreviewModal
          data={data}
          onClose={() => setShowScheduledSmsModal(false)}
        />
      )}
    </Wrapper>
  );
};
