import { useContext, useMemo } from "react";
import styled from "@emotion/styled";
import Icon from "components/Icon";
import { Box } from "@mui/material";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { DialButton } from "components/SimpleRegistrations/DialButton";

const IconWrapper = styled.div`
  svg {
    width: 7vw;
    height: 4vw;
  }
`;

const Span = styled.span`
  font-weight: 800;
`;

export const LandscapeBirthdayInput = ({ onClickNext }) => {
  const {
    birthday,
    setBirthday,
    birthdayCurrentIdx: currentIdx,
    setBirthdayCurrentIdx: setCurrentIdx,
  } = useContext(SimpleRegistrationsContext);

  const displayBirthday = useMemo(() => {
    return birthday.substring(0, 7).padEnd(7, "0");
  }, [birthday]);

  const onChangeBirthday = (value) => {
    if (birthday.length < 7) {
      setBirthday((number) => number + value);
      setCurrentIdx(currentIdx + 1);
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "50vw",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: "10%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              fontSize: "2.5vw",
              fontWeight: "bold",
            }}
          >
            <Span>생년월일</Span>을 입력하세요.
          </Box>
          <Box
            sx={{
              width: "100%",
              fontSize: "5.5vw",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {displayBirthday.split("").map((char, index) => (
              <span
                key={index}
                style={{
                  color: index >= currentIdx ? "#F1F1F1" : "black",
                }}
              >
                {char}
                {index === 5 && "-"}
              </span>
            ))}
            <span
              style={{
                color: birthday.length < 7 ? "#F1F1F1" : "black",
              }}
            >
              ******
            </span>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "50vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "3vw",
          padding: "5%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridGap: "2%",
            gridTemplateColumns: "repeat(3, 4)",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridGap: "2%",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <DialButton text={1} onClick={() => onChangeBirthday(1)} />
            <DialButton text={2} onClick={() => onChangeBirthday(2)} />
            <DialButton text={3} onClick={() => onChangeBirthday(3)} />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridGap: "2%",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <DialButton text={4} onClick={() => onChangeBirthday(4)} />
            <DialButton text={5} onClick={() => onChangeBirthday(5)} />
            <DialButton text={6} onClick={() => onChangeBirthday(6)} />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridGap: "2%",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <DialButton text={7} onClick={() => onChangeBirthday(7)} />
            <DialButton text={8} onClick={() => onChangeBirthday(8)} />
            <DialButton text={9} onClick={() => onChangeBirthday(9)} />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridGap: "2%",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            <DialButton
              text={
                <IconWrapper>
                  <Icon variant="backspace" />
                </IconWrapper>
              }
              onClick={() => {
                if (currentIdx !== 0) {
                  setBirthday((day) => day.slice(0, day.length - 1));
                  setCurrentIdx(currentIdx - 1);
                }
              }}
            />
            <DialButton text={0} onClick={() => onChangeBirthday(0)} />
            <DialButton
              text="다음"
              sx={{
                fontSize: "40px",
                color: "white",
                background:
                  birthday.length < 7 ||
                  (birthday.length === 7 &&
                    (birthday.slice(-1) < 1 || birthday.slice(-1) > 8))
                    ? "#BBBBBB"
                    : "#0060ff",
              }}
              disabled={
                birthday.length < 7 ||
                (birthday.length === 7 &&
                  (birthday.slice(-1) < 1 || birthday.slice(-1) > 8))
              }
              onClick={() => onClickNext()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
