import { styled, css } from "@mui/material";
import { ComponentType } from "react";

const Mark = styled("div")`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: solid 1px #2c62f6;
  text-align: center;
  border-radius: 10px;
  color: #2c62f6;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color, theme }) => {
    if (color === "primary") {
      return css`
        border: solid 1px #2c62f6;
        color: #2c62f6;
      `;
    }
    if (color === "black") {
      return css`
        border: solid 1px ${theme.palette.grey[700]};
        color: ${theme.palette.grey[700]};
      `;
    }
  }}

  ${({ size }: any) => {
    if (size === "small") {
      return css`
        width: 14px;
        height: 14px;
        font-size: 10px;
      `;
    }
    if (size === "medium") {
      return css`
        width: 16px;
        height: 16px;
        font-size: 10px;
      `;
    }
  }}
` as ComponentType<any>;

export const QuestionMark = ({
  color,
  size,
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <Mark color={color} size={size}>
      ?
    </Mark>
  );
};
