import Icon from "components/Icon";
import { TabType } from "constant/Tabs";
import { TypeWrapper } from "./TypeWrapper";
import { IconWrapper } from "./IconWrapper";
import { Type } from "./Type";
import { getName, getIconByType } from "utils/tabUtils";
import { getLinkedChannelIconVariant } from "~/utils/linkedChannelUtil";

export const TypeIcon = ({ item }: { item: any }) => {
  let registrationFormType: string | undefined;
  let iconSelector;

  if (item.progress === "OPEN") {
    if (item.type === TabType.registration) {
      if (item.externalLinkedChannel) {
        const variant = getLinkedChannelIconVariant(item.externalLinkedChannel);
        iconSelector = <Icon variant={variant} />;
      } else {
        iconSelector = <Icon variant="registration" />;
      }
      registrationFormType = TabType.registration;
    } else if (item.type === TabType.appointment) {
      if (item.externalLinkedChannel) {
        const variant = getLinkedChannelIconVariant(item.externalLinkedChannel);
        iconSelector = <Icon variant={variant} />;
      } else {
        iconSelector = <Icon variant="appointment" />;
      }
      registrationFormType = TabType.appointment;
    }
  } else {
    const { icon, type } = getIconByType(item);
    iconSelector = icon;
    registrationFormType = type;
  }

  return (
    <TypeWrapper>
      <IconWrapper>{iconSelector}</IconWrapper>
      <Type>{registrationFormType && getName(registrationFormType)}</Type>
    </TypeWrapper>
  );
};
