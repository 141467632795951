import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";
import * as Sentry from "@sentry/browser";

export class GlobalErrorBoundary extends React.Component<{
  children: ReactNode;
}> {
  public state: any;
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    console.error(error);
    Sentry.captureException(error);
  }

  onClickReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4">알 수 없는 에러가 발생했습니다.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onClickReload}
          >
            다시 불러오기
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
