import { ReactNode, useCallback } from "react";
import { CustomMenu } from "./ContextMenu.styled";

export type WithContextMenuProps = {
  anchorEl?: any;
  anchorPosition?: any;
  open: boolean;
  onClose: (params?: any) => void;
  position?: any;
  action?: any;
  node?: Node;
  openEditNameModal?: (entity: any) => void;
  customerDrive?: boolean;
  relocate?: any;
  sortDirection?: any;
  appointment?: any;
  setShowAddChartInfoModal?: (value: any) => void;
  onChange?: (value: any) => void;
};

export const withContextMenu = (Comp: any) => {
  const WrappedComp = ({
    anchorEl,
    open,
    onClose,
    position,
    action,
    ...props
  }: WithContextMenuProps) => {
    return (
      <ContextMenu
        anchorEl={anchorEl}
        open={open}
        action={action}
        onClose={onClose}
        position={position}
        {...props}
      >
        <div>
          <Comp onClose={onClose} {...props} />
        </div>
      </ContextMenu>
    );
  };

  return WrappedComp;
};

type ContextMenuProps = {
  anchorEl: ReactNode;
  open: boolean;
  onClose: (params?: any) => void;
  position?: any;
  children?: ReactNode;
  action?: any;
  anchorOrigin?: any;
  transformOrigin?: any;
  autoFocus?: boolean;
  disableAutoFocus?: any;
  disableEnforceFocus?: any;
};

export const ContextMenu = ({
  anchorEl,
  open,
  onClose,
  position,
  ...props
}: ContextMenuProps) => {
  const onContextMenu = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <CustomMenu
      anchorReference={position != null ? "anchorPosition" : "anchorEl"}
      anchorPosition={position}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      BackdropProps={{
        invisible: true,
        onContextMenu: (e: any) => {
          e.preventDefault();
          e.stopPropagation();

          onClose();
        },
      }}
      onContextMenu={onContextMenu}
      open={open}
      onClose={onClose}
      {...props}
    />
  );
};
