import { ApiClient } from "../apiClient";

export class ApiResource {
  protected endpoint: string;

  constructor(protected client: ApiClient) {
    this.endpoint = client.endpoint!;

    // 기존 메소드 바인딩 로직
    for (const prop of Object.getOwnPropertyNames(
      Object.getPrototypeOf(this)
    )) {
      if (prop === "constructor" || typeof (this as any)[prop] !== "function")
        continue;

      (this as any)[prop] = (this as any)[prop].bind(this);
      (this as any)[prop].apiKey = `${this.constructor.name}.${prop}`;
    }
  }

  protected buildHeaders() {
    return this.client.buildHeaders();
  }

  arrayToQueryParam(items?: any[]) {
    return items?.map((v) => String(v)).join(",");
  }
}
