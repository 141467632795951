import { styled } from "@mui/material";
import { EmptyView as _EmptyView } from "components/EmptyView";
import { Button } from "components/Button";
import { ComponentType } from "react";

export const EmptyView = styled(_EmptyView)(
  () => `
  min-height: calc(100vh - 262px);
`
);

export const ButtonWrapper = styled(Button)`
  padding: 0;
`;

export const Memos = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const Memo = styled("div")`
  display: flex;
  column-gap: 12px;
`;

export const MoreMemoButton = styled("span")`
  color: #bbbbbb;
  cursor: pointer;
  text-align: left;
`;

export const Label = styled("label")`
  font-weight: 700;
  white-space: nowrap;
`;

export const Contents = styled("div")`
  max-height: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  * {
    margin: 0;
  }
` as ComponentType<any>;

export const SortColumnWrapper = styled("div")`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Divider = styled("div")`
  margin-left: 60px;
  border-bottom: 1px solid #efeff4;
`;

export const CustomerName = styled("div")`
  word-break: break-all;
`;
