import { useState, useRef } from "react";
import { useStatusSettings } from "providers/StatusSettingsProvider";
import ChartContextMenu from "components/ChartContextMenu";
import { StatusColor } from "core/statusColor";
import { Wrapper, Status } from "components/StatusInfoBadge.styled";
import Icon from "./Icon";

export function StatusInfoBadge({
  appointment,
  onClickStatus,
}: {
  appointment: any;
  onClickStatus: any;
}) {
  const ref = useRef<any>();
  const statusSettings: any = useStatusSettings();
  const [openStatusMenu, setOpenStatusMenu] = useState(false);

  return (
    <Wrapper>
      <Status
        ref={ref}
        onClick={() => {
          setOpenStatusMenu(!openStatusMenu);
        }}
        onMouseLeave={() => {
          if (openStatusMenu) {
            setOpenStatusMenu(false);
          }
        }}
        color={
          appointment.status === "ADDITIONAL_STATUS"
            ? appointment.sessionStatusConfig?.color
            : statusSettings.getStatusSettings(
                StatusColor.fromStatus(appointment.status)
              ).color
        }
      >
        {appointment.status === StatusColor.additional_status
          ? appointment.sessionStatusConfig?.name
          : statusSettings.getStatusSettings(
              StatusColor.fromStatus(appointment.status)
            ).name}
        <Icon variant="chevron_down" height="10" />
        <ChartContextMenu
          anchorEl={ref.current}
          openStatusMenu={openStatusMenu || false}
          appointment={appointment}
          onClickStatus={onClickStatus}
        />
      </Status>
    </Wrapper>
  );
}
