import { useState, useEffect } from "react";
import { useSimpleRegistration } from "./useStore";

export const useHiddenFunction = (phoneNumber: string) => {
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const [preconditionMet, setPreconditionMet] = useState<boolean>(false);
  const { setHiddenCondition, getHiddenCondition } = useSimpleRegistration();

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (phoneNumber === "01099999999") {
      setPreconditionMet(true);
      const newTimer = setTimeout(() => {
        setPreconditionMet(false);
        setHiddenCondition(false);
      }, 10 * 1000);
      setTimer(newTimer);
    } else if (phoneNumber === "01000000000" && preconditionMet) {
      setHiddenCondition(true);
      const newTimer = setTimeout(() => {
        setPreconditionMet(false);
        setHiddenCondition(false);
      }, 30 * 1000);
      setTimer(newTimer);
    } else {
      setHiddenCondition(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [phoneNumber]);

  useEffect(() => {
    return () => {
      setTimer(null);
      setPreconditionMet(false);
    };
  }, []);

  return {
    condition: getHiddenCondition,
  };
};
