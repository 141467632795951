import {
  Box as MuiBox,
  styled,
  IconButton as MuiIconButton,
} from "@mui/material";
import landscapeImage from "assets/images/background-landscape.png";

export const Box = styled(MuiBox)`
  &.left-wrapper {
    width: 50vw;
    height: 100%;

    .background-image-wrapper {
      width: 100%;
      height: 100%;
      background-image: url(${landscapeImage});
      background-size: 100% 100%;
      padding: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .title-wrapper {
      font-size: 2vw;
      font-weight: bold;
      color: white;
    }

    .description-wrapper {
      display: flex
      flex-direction: column;
      padding: 5%;

      .description {
        font-weight: bold;
        color: white;
      }
      .font-size-5vw {
        font-size: 5vw;
        margin: 0;
      }
      .font-size-3vw {
        font-size: 3vw;
      }
    }
  }
`;

export const IconButton = styled(MuiIconButton)`
  color: #ffffff;
  width: 60px;
  height: 60px;
`;
