import {
  Typography,
  Box,
  CircularProgress,
  Avatar as MuiAvatar,
  styled,
} from "@mui/material";
import { ageText } from "utils/index";
import { format } from "date-fns";
import { ProfileWrapper, Bold } from "components/CustomerHeaderLabel.styled";

export const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  background: ${theme.palette.bluegrey["500"]};
  &:not(.loading) {
    svg {
      width: 25px;
      fill: #A4BCF2;
    }
  }
  
  &.female {
    background: rgb(253, 238, 238);
    svg {
      fill: rgb(235, 87, 87);
      fill-opacity: 0.2;
    }
  }
`
);

export const CustomerHeaderLabel = ({
  label,
  profileImage,
  customer,
}: {
  label?: string;
  profileImage?: any;
  customer?: any;
}) => {
  const loading = profileImage?.thumbnailStatus === "CREATING" ? true : false;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        marginLeft: "8px",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <ProfileWrapper>
        {loading ? (
          <Avatar className="loading">
            <CircularProgress />
          </Avatar>
        ) : profileImage?.thumbnailUrl ? (
          <Avatar src={profileImage.thumbnailUrl} />
        ) : (
          <Avatar className={`${customer?.sex || ""}`} />
        )}
      </ProfileWrapper>
      <Typography
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: "510px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Bold>{`${customer?.name ?? ""}(${
          customer?.sex === "male" ? "M" : "F"
        }/${ageText(customer)}/${
          customer?.birthday
            ? format(new Date(customer?.birthday), "yyMMdd")
            : "-"
        })`}</Bold>
        <span>{label}</span>
      </Typography>
    </Box>
  );
};
