import { useState, useContext } from "react";
import styled from "@emotion/styled";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { phoneNumberFormatHyphen } from "utils/phoneNumber";
import { getBirthday } from "utils/birthdayUtil";
import { TermsModal } from "modals/TermsModal";

const CustomCheckbox = styled(Checkbox)(({ islandscape }) => ({
  padding: 0,
  margin: 0,
  svg: {
    fontSize: islandscape === "true" ? "2vw" : "2.5vw",
  },
}));

const Span = styled.span`
  font-weight: 800;
`;

const Label = styled(FormControlLabel)(({ islandscape, fontWeight }) => ({
  margin: "0 !important",
  gap: "30px",

  "& .MuiFormControlLabel-label": {
    fontSize: islandscape === "true" ? "2vw" : "2.5vw",
    fontWeight: fontWeight,
  },
}));

export const InfoConfirm = () => {
  const {
    isLandscape,
    name,
    birthday,
    phoneNumber,
    smsEnabled,
    setSmsEnabled,
    eventSmsEnabled,
    setEventSmsEnabled,
  } = useContext(SimpleRegistrationsContext);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const getSex = () => {
    const ssnHead = birthday.charAt(birthday.length - 1);
    if (ssnHead === "2" || ssnHead === "4") {
      return "여";
    } else if (ssnHead === "1" || ssnHead === "3") {
      return "남";
    } else {
      return "";
    }
  };

  return (
    <>
      {openTermsModal && (
        <TermsModal
          open={openTermsModal}
          onClose={() => setOpenTermsModal(false)}
        />
      )}
      <Box
        sx={{
          padding: isLandscape ? 0 : "3%",
        }}
      >
        <Box
          sx={{
            fontSize: isLandscape ? "3vw" : "4vw",
            fontWeight: "bold",
          }}
        >
          입력한 <Span>정보 확인 후 약관에 동의</Span>해주세요.
        </Box>
        <Box
          sx={{
            border: "solid 1px #DEE2EC",
            background: "#F3F8FF",
            borderRadius: "4px",
            height: isLandscape ? "10vh" : "7vh",
            padding: "2%",
            fontSize: "3vw",
            display: "flex",
            gap: "3%",
            alignItems: "center",
            fontWeight: "bold",
            margin: "3% 0",
          }}
        >
          <Box
            sx={{
              width: "30vw",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </Box>
          <Box sx={{ width: "5vw" }}>{getSex()}</Box>
          <Box sx={{ width: "20vw" }}>{getBirthday(birthday)}</Box>
          <Box sx={{ width: isLandscape ? "35vw" : "25vw" }}>
            {phoneNumberFormatHyphen(phoneNumber)}
          </Box>
        </Box>
        <Box>
          <Box sx={{ marginBottom: "1%" }}>
            <Label
              islandscape={isLandscape ? "true" : undefined}
              fontWeight="bold"
              control={
                <CustomCheckbox
                  checked={smsEnabled && eventSmsEnabled}
                  islandscape={isLandscape ? "true" : undefined}
                />
              }
              label="약관 전체동의"
              onChange={(e) => {
                const value = e.target.checked;
                setSmsEnabled(value);
                setEventSmsEnabled(value);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Label
              islandscape={isLandscape ? "true" : undefined}
              control={
                <CustomCheckbox
                  checked={smsEnabled}
                  islandscape={isLandscape ? "true" : undefined}
                />
              }
              label="[필수] 개인정보 수집 및 활용에 대한 처리방침"
              onChange={(e) => {
                setSmsEnabled(e.target.checked);
              }}
            />
            <span
              style={{
                textDecoration: "underline",
                fontSize: isLandscape ? "2vw" : "2.5vw",
              }}
              onClick={() => setOpenTermsModal(true)}
            >
              전문보기
            </span>
          </Box>
          <Label
            islandscape={isLandscape ? "true" : undefined}
            control={
              <CustomCheckbox
                checked={eventSmsEnabled}
                islandscape={isLandscape ? "true" : undefined}
              />
            }
            label="[선택] 이벤트 및 광고, 혜택알림 수신"
            onChange={(e) => {
              setEventSmsEnabled(e.target.checked);
            }}
          />
        </Box>
      </Box>
    </>
  );
};
