import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "./AlertModal.styled";
import { ReactNode } from "react";

export interface AlertModalProps {
  onClose: (params?: any) => void;
  children: ReactNode;
  confirmText?: string;
  variant?: "warning";
  bgClickCancel?: boolean;
  disableBgClick?: boolean;
  disableActions?: boolean;
}

export const AlertModal = ({
  onClose,
  children,
  confirmText,
  // variant,
  bgClickCancel = false,
  disableBgClick = false,
  disableActions = false,
  ...props
}: AlertModalProps) => {
  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason === "backdropClick" && disableBgClick === true) {
          return;
        }
        if (reason === "backdropClick" && bgClickCancel === true) {
          onClose("cancel");
        }
        onClose();
      }}
      {...props}
    >
      <DialogContent>{children}</DialogContent>
      {disableActions ? (
        ""
      ) : (
        <DialogActions>
          <Button variant={"contained"} onClick={onClose}>
            {confirmText ?? "확인"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
