import { phoneNumberFormatHyphen } from "~/utils/filters";
import {
  Wrapper,
  Row,
  Column,
  ColumnValue,
} from "./FindCustomerAutoCompleteRow.styled";

export const returnSex = (sex: "male" | "female") => {
  switch (sex) {
    case "male":
      return "남성";
    case "female":
      return "여성";
  }
};

type FindCustomerAutoCompleteRowProps = {
  props: any;
  option: any;
};

export const FindCustomerAutoCompleteRow = ({
  props,
  option,
}: FindCustomerAutoCompleteRowProps) => {
  return (
    <Wrapper key={option.id}>
      <Row {...props}>
        <Column>
          <ColumnValue width={60}>{option.name}</ColumnValue>
        </Column>
        <Column>
          <ColumnValue width={100}>{option.birthday}</ColumnValue>
        </Column>
        <Column>
          <ColumnValue width={50}>{returnSex(option.sex)}</ColumnValue>
        </Column>
        <Column>
          <ColumnValue width={100}>
            {phoneNumberFormatHyphen(option.phoneNumber)}
          </ColumnValue>
        </Column>
      </Row>
    </Wrapper>
  );
};
