import { observer } from "mobx-react";
import { format } from "date-fns";
import { TabType } from "constant/Tabs";
import QuillText from "~/components/quill/QuillText";
import { ChartTypeBadge } from "./ChartCard/ChartTypeBadge";
import { TypeIcon } from "./ChartCard/TypeIcon";
import { MemoWrapper } from "./ChartCard/MemoWrapper";
import { SurgeryCategoriesNames } from "./ChartCard/SurgeryCategoriesNames";
import { ConnectRegistrationItems } from "./ChartCard/ConnectRegistrationItems";
import { RegistrationConnectChart } from "./ChartCard/RegistrationConnectChart";
import {
  Wrapper,
  Header,
  LeftSection,
  BadgeWrapper,
  Body,
  Contents,
} from "./ChartCard.styled";
import { ManagerNames } from "./ChartCard/ManagerName";
import { getCardValue } from "utils/tabUtils";

export const StatusConfig: any = {
  APPOINTMENT: { label: "예약", color: "#FF9D5F", apiPath: "/appointments" },
  CANCELED: {
    label: "예약 - 취소",
    color: "#FF3D3D",
    apiPath: "/appointments",
  },
  NO_SHOW: {
    label: "예약 - 미방문",
    color: "#E6E6E6",
    apiPath: "/appointments",
  },
  REGISTRATION: { label: "내원", color: "#2C62F6", apiPath: "/registrations" },
  CONSULTING: { label: "상담", color: "#B7E4B0", apiPath: "/consultings" },
  PAYMENT: { label: "수납", color: "#5080FA", apiPath: "/payments" },
  SURGERY: { label: "시/수술", color: "#FF508C", apiPath: "/surgeries" },
  TREATMENT: { label: "진료", color: "#56B1E4", apiPath: "/treatments" },
  NURSE_CARE: { label: "간호", color: "#D1E20F", apiPath: "/nurse_cares" },
  NURSE_OPERATION: {
    label: "수술간호",
    color: "#6DCDD1",
    apiPath: "/nurse_operations",
  },
  SKINCARE: { label: "피부관리", color: "#A4A4A4", apiPath: "/skincares" },
};

const AcquisitionChannel = ({
  acquisitionChannel,
}: {
  acquisitionChannel: any;
}) => {
  return acquisitionChannel?.id ? <div>{acquisitionChannel.name}</div> : <></>;
};

const MemoContent = ({ session }: { session: any }) => {
  const getMemoText = () => {
    const { category, memo } = session;
    let text = memo;
    if (category === "NONE") {
      const cardValue = getCardValue(session);
      text = cardValue && cardValue[0].memo;
    }

    return text;
  };

  return (
    <>
      {getMemoText() && (
        <MemoWrapper type={session.category === "NONE" ? "" : session.type}>
          <QuillText
            value={getMemoText()}
            style={{ lineHeight: "1.4", width: "auto" }}
          />
        </MemoWrapper>
      )}
    </>
  );
};

export const ChartCard = observer(({ item }: { item: any }) => {
  return (
    <Wrapper>
      <Header>
        <LeftSection>
          <div>{format(new Date(item.date), "yyyy-MM-dd(E)")}</div>
          <BadgeWrapper>
            <ChartTypeBadge item={item} />
          </BadgeWrapper>
        </LeftSection>
      </Header>
      <Body style={{ marginTop: "5px" }}>
        <Contents>
          {item.type === TabType.registration &&
            item.appointment !== null &&
            !item.appointment.isDeleted && (
              <RegistrationConnectChart
                chart={{
                  key: "APPOINTMENT",
                  value: [item.appointment],
                }}
              />
            )}
          <TypeIcon item={item} />
          {item.category !== "NONE" && <ManagerNames item={item} />}
          <AcquisitionChannel acquisitionChannel={item.acquisitionChannel} />
          <SurgeryCategoriesNames item={item} />
          <MemoContent session={item} />
        </Contents>
      </Body>
      <ConnectRegistrationItems item={item} />
    </Wrapper>
  );
});
