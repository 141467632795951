import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useApi } from "./api";
import { useAuth } from "./auth";
import * as Sentry from "@sentry/react";

interface UserContextValue {
  user: any;
  refresh: () => void;
  hasPermission: (name: string) => boolean;
}

const UserContext = createContext<UserContextValue | null>(null);

export function UserProvider({ children }: { children: ReactNode }) {
  const { clear, authed }: any = useAuth();
  const api = useApi();

  const [user, setUser] = useState<any>(null);

  const loadUser = useCallback(async () => {
    let res;
    try {
      res = await api.me();
    } catch (e) {
      clear();
      return;
    }

    const payload = await res.json();

    Sentry.setContext("info", {
      clinic_name: payload.data.clinic.name,
      clinic_id: payload.data.clinic.id,
      name: payload.data.name,
      user_id: payload.data.id,
      email: payload.data.email,
    });

    setUser(payload.data);
  }, [api, clear]);

  useEffect(() => {
    if (!authed) return;

    loadUser();
  }, [loadUser, authed]);

  const hasPermission = useCallback(
    (code: string) => {
      return (
        user.role.permissions.find((v: any) => v.code === code) &&
        user.role.permissions.find((v: any) => v.code === code).allow === "ON"
      );
    },
    [user]
  );

  return (
    <UserContext.Provider value={{ user, refresh: loadUser, hasPermission }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext) as UserContextValue;
}
