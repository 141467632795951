import {
  styled,
  Box as MuiBox,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Button as MuiButton,
  IconButton as MuiIconButton,
} from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  () => `
  margin-left: auto;
  width: 24px;
  height: 24px;
  padding: 6px;
`
);

export const Button = styled(MuiButton)(
  ({ theme }) => `
  width: 100%;
  height: 58px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  color: ${theme.palette.grey["700"]};
  &.Mui-disabled {
    color: ${theme.palette.grey["400"]};
  }
`
);

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 340px;
    max-width: 400px;
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  padding: 18px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 0 24px 16px 24px;
`;

export const DialogContent = styled(MuiDialogContent)`
  padding: 40px 27px 24px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  .deco {
    display: block;
    position: relative;
    padding-left: 14px;
    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background: black;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 0;
    }
    & + .deco {
      margin-top: 8px;
    }
  }

  .underline {
    text-decoration: underline;
  }
`;

export const Box = styled(MuiBox)`
  &.body {
    display: flex;
    width: 1164px;

    &.max-size {
      width: 100%;

      .left-section {
        min-height: 670px;
        height: calc(100vh - 62px);
      }

      .right-section {
        min-height: 670px;
        height: calc(100vh - 62px);
        .sms-data-table {
          min-height: 450px;
          height: calc(100vh - 255px);
        }
      }
    }
  }
  &.wrapper {
    display: flex;
    column-gap: 18px;
    p {
      font-weight: 400;
    }
  }
`;
