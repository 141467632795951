import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { myTheme } from "styles/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { InputAdornment } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "dayjs/locale/ko";

const DateInputTheme = ({ editInput }: any) =>
  createTheme(myTheme, {
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: !editInput && {
            ".PrivateDatePickerToolbar-penIcon": {
              display: "none",
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            button: {
              height: "34px",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h4: {
            fontSize: "25px",
            wordBreak: "keep-all",
          },
        },
      },
    },
  });

type DateInputProps = {
  value: any;
  onChange: (value: any) => void;
  onAccept?: (value: any) => void;
  inputFormat?: string;
  mask?: string;
  InputComponent?: any;
  placeholder?: null | string;
  editInput?: boolean;
  showAdornment?: boolean;
  width?: null | string;
  disabled?: boolean;
};

export function DateInput({
  value,
  onChange,
  onAccept,
  inputFormat = "YYYY-MM-DD",
  mask = "",
  InputComponent,
  placeholder = null,
  editInput = false,
  showAdornment = false,
  width = null,
  disabled = false,
}: DateInputProps) {
  return (
    <MuiThemeProvider theme={DateInputTheme({ editInput })}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={"ko"}
        localeText={{ okButtonLabel: "확인", cancelButtonLabel: "취소" }}
      >
        <MobileDatePicker
          disabled={disabled}
          value={value}
          onChange={(v: any) => onChange?.(v)}
          onAccept={(v: any) => onAccept?.(v)}
          inputFormat={inputFormat}
          mask={mask}
          toolbarFormat="MM월 DD일 (dd)"
          toolbarTitle=""
          toolbarPlaceholder=""
          orientation="landscape"
          renderInput={(params: any) => (
            <InputComponent
              {...params}
              name={String(params)}
              sx={{
                width: "100%",
                cursor: "pointer",
                input: {
                  width: width ?? undefined,
                  height: "34px",
                  fontSize: "14px",
                  padding: "0 20px",
                },
              }}
              placeholder={placeholder}
              InputProps={{
                endAdornment: showAdornment ? (
                  <InputAdornment
                    position="end"
                    onClick={params.inputProps.onClick}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{ color: "#292929", fontSize: "1em" }}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </MuiThemeProvider>
  );
}
