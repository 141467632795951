import { useState } from "react";
import { styled } from "@mui/material";
import SMSPreviewButton from "~/pages/AppointmentPage/form/SMSPreviewButton";
import { ScheduledSmsPreviewModal } from "modals/ScheduledSmsPreviewModal";

const Wrapper = styled("div")`
  font-size: 13px;
  font-weight: 500;
  color: #2d2d2d;

  .button-wrapper {
    margin-top: 8px;
  }

  span.blue-text {
    color: #2c62f6;
  }
`;

export const ImmediateSmsConfirmPopup = ({ smsRules }: { smsRules: any[] }) => {
  const [showScheduledSmsModal, setShowScheduledSmsModal] = useState(false);

  return (
    <Wrapper>
      <div>
        <span className="blue-text">[즉시 전송 예약문자]</span>가 있습니다.
        전송하시겠습니까?
      </div>
      <div>다른 예약문자 취소는 [문자설정]에서 가능합니다.</div>
      <div className="button-wrapper">
        <SMSPreviewButton onClick={() => setShowScheduledSmsModal(true)} />
        {showScheduledSmsModal && (
          <ScheduledSmsPreviewModal
            data={smsRules}
            onClose={() => setShowScheduledSmsModal(false)}
          />
        )}
      </div>
    </Wrapper>
  );
};
