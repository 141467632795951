import { styled } from "@mui/material";

export const Wrapper = styled("div")`
  background-color: #fff;
  width: 100%;
  height: 100%;
  color: #292929;
`;

export const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;
  font-weight: 500;
`;

export const Body = styled("div")`
  padding: 15px 30px;
  height: calc(100% - 68px);
  box-sizing: border-box;
`;
