export const bloodTypeList = [
  { id: "A", label: "A" },
  { id: "B", label: "B" },
  { id: "O", label: "O" },
  { id: "AB", label: "AB" },
  { id: "Rh-A", label: "Rh-A" },
  { id: "Rh-B", label: "Rh-B" },
  { id: "Rh-O", label: "Rh-O" },
  { id: "Rh-AB", label: "Rh-AB" },
];

export const marriedList = [
  { id: false, label: "미혼" },
  { id: true, label: "기혼" },
];
