import { useState, useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";

import { Box, Button } from "@mui/material";
import { SlideModal } from "components/Modal/SlideModal";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import { Container } from "components/SimpleRegistrations/Container";
import { Header } from "components/SimpleRegistrations/Header";
import { Body } from "components/SimpleRegistrations/Body";
import { Footer } from "components/SimpleRegistrations/Footer";
import { CustomerList } from "components/SimpleRegistrations/landscape/CustomerList";
import { CustomersList } from "components/SimpleRegistrations/landscape/CustomersList";

export function SimpleRegistrationsCustomerListModal({ open, onClose }) {
  const { isLandscape, customers, setLoading } = useContext(
    SimpleRegistrationsContext
  );
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <>
      {openAlert && (
        <ConfirmModal
          body={
            <Box sx={{ fontWeight: "bold" }}>
              <Box sx={{ fontSize: "2vw", marginBottom: "5%" }}>안내</Box>
              <div>
                이전 단계로 이동할까요?
                <br />
                입력중인 정보는 저장되지 않습니다.
              </div>
            </Box>
          }
          cancelText="아니오"
          confirmText="예"
          onClose={() => setOpenAlert(false)}
          onConfirm={() => {
            setOpenAlert(false);
            onClose();
          }}
          open={openAlert}
        />
      )}
      <SlideModal open={open}>
        <Container>
          <Header onOpen={setOpenAlert} onClose={onClose} />
          <Body>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box
                  sx={{
                    fontSize: "3vw",
                    fontWeight: "bold",
                    marginBottom: isLandscape ? "1vw" : "3vw",
                  }}
                >
                  다음과 같은 고객 정보가 조회되었습니다.
                </Box>
                {customers.length === 1 ? (
                  <CustomerList customer={customers[0]} />
                ) : (
                  <CustomersList
                    customers={customers}
                    onClick={(customer) => {
                      onClose("registration", customer);
                    }}
                  />
                )}
                <Box
                  sx={{
                    fontSize: isLandscape ? "2vw" : "3vw",
                    color: "#BBBBBB",
                  }}
                >
                  ∙ 본인이 아닌 정보로 접수한 경우 법적 책임은 접수한 당사자에게
                  있습니다.
                </Box>
              </div>
              <Box>
                <Button
                  sx={{
                    width: "100%",
                    height: "10vh",
                    fontSize: "3vw",
                    color: "black",
                    textDecoration: "underline",
                    textUnderlineOffset: "6px",
                  }}
                  onClick={() => {
                    setLoading(false);
                    onClose("registration_new");
                  }}
                >
                  새로운 고객으로 접수하겠습니다.
                </Button>
              </Box>
            </Box>
          </Body>
          {customers.length === 1 && (
            <Footer
              text="접수하기"
              onClick={() => onClose("registration", customers[0])}
            />
          )}
        </Container>
      </SlideModal>
    </>
  );
}
