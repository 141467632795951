import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

type ButtonGroupProps = {
  isToggle?: boolean;
  buttonData?: any[];
  value: any;
  onChange: (value: any) => void;
};

export const ButtonGroup = ({
  isToggle = true,
  buttonData = [],
  value,
  onChange,
}: ButtonGroupProps) => {
  const handleChange = (e: any, newAlignment: string) => {
    switch (newAlignment) {
      default:
        if (!isToggle && !newAlignment) return;
        onChange(newAlignment);
        break;
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value || ""}
      exclusive
      onChange={handleChange}
      sx={{
        width: "auto",
        borderRadius: "3px",
        background: "#fff",
        columnGap: "10px",
        padding: 0,
      }}
    >
      {buttonData.map((v: any) => (
        <ToggleButton
          key={v.value}
          value={v.value || ""}
          onClick={v.onClick}
          sx={{
            height: "34px",
            fontSize: "14px",
            padding: "0 16px",
            color: "#282828",
            border: "1px solid #efeff4 !important",
            borderRadius: "4px !important",
            background: "#fff !important",
            transition: "0.1s",
            "&.Mui-selected": {
              borderColor: "currentColor !important",
            },
          }}
        >
          {v.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
