import {
  useState,
  useEffect,
  useMemo,
  useRef,
  ChangeEvent,
  MouseEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AuthError, useAuth } from "providers/auth";
import { useSnackbar } from "SnackbarProvider";
import defaultLogo from "assets/images/uno_logo.svg";
import { CheckboxLabel } from "components/Form/CheckboxLabel";
import {
  Wrapper,
  Body,
  Form,
  FieldWrapper,
  Input,
  WarningWrapper,
  WarningText,
  Box,
  Button,
  Divider,
} from "./LoginPage.styled";
import { Footer } from "./components/Footer";
import { checkEmailFormat } from "utils/filters";
import ConfirmCertNumber from "./components/ConfirmCertNumber";
import { AlertModal } from "modals/AlertModal";
import { DefaultModal } from "components/Modal/DefaultModal";
import { useImperativeModal } from "ImperativeModalProvider";
import { useApi } from "providers/api";

const getErrorDesc = (reason: string) => {
  switch (reason) {
    case AuthError.ACCESS_RESTRICTED:
      return "현재 접속한 IP에서는 서비스를 이용할 수 없습니다. 관리자에게 문의하세요.";
    case AuthError.UNAUTHORIZED:
      return "인증이 만료되었습니다. 다시 로그인하세요.";
    default:
      return "";
  }
};

export function LoginPage() {
  const navigate = useNavigate();
  const auth: any = useAuth();
  const snackbar = useSnackbar();
  const [step, setStep] = useState(1);
  const { error } = auth;
  const imperativeModal = useImperativeModal();
  const api = useApi();
  const [autoLogin, setAutoLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState<null | { image: { originalUrl: string } }>(
    null
  );
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showAccessWarningText, setShowAccessWarningText] = useState(false);
  const trimmedEmail = useMemo(() => email.trim(), [email]);
  const passwordRef = useRef();

  useEffect(() => {
    getLogo();
  }, []);

  useEffect(() => {
    if (!error) return;
    getErrorDesc(error) && snackbar.open(getErrorDesc(error));
  }, [error, snackbar]);

  const validate = () => {
    if (!trimmedEmail) {
      throw new Error("아이디(이메일)를 입력해주세요.");
    }
    if (!password) {
      throw new Error("비밀번호를 입력해주세요.");
    }

    if (!checkEmailFormat(trimmedEmail) || password.length < 8) {
      throw new Error(
        "등록되지 않은 아이디거나 로그인 정보가 일치하지 않습니다. 고객센터로 문의해 주세요."
      );
    }
  };

  const getLogo = async () => {
    const response = await api.getPopupList();
    const payload = await response.json();
    if (payload.data) {
      const logo = payload.data.find(
        (popup: { type: "logo" }) => popup.type === "logo"
      );
      setLogo(logo || null);
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickSignin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  async function handleSubmit() {
    try {
      validate();
    } catch (e: any) {
      snackbar.open(e.message);
      return;
    }

    try {
      const response = await auth.signin(trimmedEmail, password, autoLogin);
      if (response === "certUser") {
        setStep(2);
      } else if (response === "manualUser") {
        navigate("/", { replace: true });
      }
    } catch (e: any) {
      if (e.code === 403 && e.name === "ACCESS_RESTRICTED") {
        return setShowAccessWarningText(true);
      } else if (e.code === 403 && e.name === "ACCESS_DENIED") {
        return await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            비밀번호 5회 오류로 로그인을 할 수 없습니다.
            <br />
            병원 관리자에게 비밀번호 초기화를 요청하세요.
          </AlertModal>
        ));
      } else if (e.code === 403 && e.name === "OWNER_ACCESS_DENIED") {
        return await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            비밀번호 5회 오류로 로그인을 할 수 없습니다.
            <br />
            고객센터에 비밀번호 초기화를 요청하세요.
          </AlertModal>
        ));
      } else if (e.code === 401) {
        return snackbar.open(
          "등록되지 않은 아이디거나 로그인 정보가 일치하지 않습니다. 고객센터로 문의해 주세요."
        );
      }
      snackbar.open(
        "일시적인 오류로 로그인할 수 없습니다. 고객센터로 문의해 주세요."
      );
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Wrapper>
      {step === 1 ? (
        <Body className={`login-form-wrapper ${isMobile ? "mobile" : ""}`}>
          <Box className="logo-image-wrapper">
            <img
              draggable="false"
              src={logo && logo.image ? logo.image.originalUrl : defaultLogo}
              alt={`고객을 관리하는 가장 좋은 선택 "UNO CRM"`}
            />
          </Box>
          <Form>
            <FieldWrapper>
              <label htmlFor="email-input">이메일</label>
              <Input
                id="email-input"
                name="email"
                type="text"
                value={email}
                onChange={handleChangeEmail}
                placeholder="이메일을 입력해주세요"
              />
            </FieldWrapper>
            <FieldWrapper className="mt-24">
              <label htmlFor="password-input">비밀번호</label>
              <Input
                type="password"
                name="password"
                value={password}
                inputRef={passwordRef}
                onChange={handleChangePassword}
                placeholder="●●●●●●●●"
              />
            </FieldWrapper>
            <Box className="auto-login-option">
              <CheckboxLabel
                text="자동로그인"
                checked={autoLogin}
                onChange={setAutoLogin}
              />
            </Box>
            <Button
              variant="contained"
              className="login-btn"
              onClick={handleClickSignin}
            >
              로그인
            </Button>
            <Box className="company-info-wrapper">
              <Box component={"span"} onClick={handleOpenModal}>
                주식회사 케어랩스
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box component={"span"}>
                <a
                  target="_blank"
                  href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208836643"
                  rel="noreferrer"
                >
                  사업자정보확인
                </a>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box component={"span"}>
                <a
                  target="_blank"
                  href="https://www.carecrm.co.kr/privacy"
                  rel="noreferrer"
                >
                  개인정보처리방침
                </a>
              </Box>
            </Box>
            {showAccessWarningText && (
              <WarningWrapper>
                <WarningText>
                  현재 접속한 IP에서는 서비스를 이용할 수 없습니다.
                </WarningText>
                <WarningText>관리자에게 문의하세요.</WarningText>
              </WarningWrapper>
            )}
          </Form>
          <DefaultModal
            open={openModal}
            title=""
            body={<Footer />}
            onClose={handleCloseModal}
          />
        </Body>
      ) : (
        <ConfirmCertNumber email={trimmedEmail} autoLogin={autoLogin} />
      )}
    </Wrapper>
  );
}
