import { useState } from "react";

export function useModal() {
  const [opened, setOpened] = useState(false);

  const close = () => {
    setOpened(false);
  };

  const open = () => {
    setOpened(true);
  };

  const toggle = () => {
    setOpened((v) => !v);
  };

  return { opened, close, open, toggle };
}
