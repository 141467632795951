import { styled } from "@mui/material";

export const Wrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  min-height: calc(100vh - 168px);
  color: ${theme.palette.bluegrey["600"]};
  justify-content: center;
  gap: 38.5px;

  p {
    width: 100%;
    text-align: center;
  }
`
);
