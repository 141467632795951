export const MAX_DIMENSION_WIDTH = 4128;
export const MAX_DIMENSION_HEIGHT = 4128;

export const MAX_IPAD_DIMENSION_WIDTH = 4096;
export const MAX_IPAD_DIMENSION_HEIGHT = 4096;

export const getMaxDimesionSize = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const re = new RegExp("iphone|ipad", "gi");
  const iDevice = re.test(userAgent);
  //   const androidDevice = /android/i.test(userAgent);

  return iDevice
    ? {
        width: MAX_IPAD_DIMENSION_WIDTH,
        height: MAX_IPAD_DIMENSION_HEIGHT,
      }
    : {
        width: MAX_DIMENSION_WIDTH,
        height: MAX_DIMENSION_HEIGHT,
      };
};
