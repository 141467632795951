import { useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { useApi } from "providers/api";
import { useSnackbar } from "SnackbarProvider";
import { LoadingBackground } from "components/SimpleRegistrations/LoadingBackground";

import { CreateNewRegistrationModal } from "modals/CreateNewRegistrationModal";
import { LeftSection } from "components/SimpleRegistrations/landscape/LeftSection";
import { RightSection } from "components/SimpleRegistrations/landscape/RightSection";
import { TopSection } from "components/SimpleRegistrations/portrait/TopSection";
import { BottomSection } from "components/SimpleRegistrations/portrait/BottomSection";
import { getBirthday } from "utils/birthdayUtil";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  ${({ isLandscape }) =>
    !isLandscape &&
    css`
      flex-direction: column;
    `}
  justify-content: center;
  align-items: center;
`;

export const SimpleRegistrations = () => {
  const {
    isLandscape,
    loading,
    setLoading,
    phoneNumber,
    birthday,
    name,
    acquisitionChannelsIds,
    smsEnabled,
    eventSmsEnabled,
    reset,
  } = useContext(SimpleRegistrationsContext);
  const api = useApi();
  const sb = useSnackbar();

  useEffect(() => {
    setOpenCreateNewRegistrationModal(false);
    reset();
  }, [isLandscape]);

  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [openCreateNewRegistrationModal, setOpenCreateNewRegistrationModal] =
    useState(false);

  const getSex = () => {
    const ssnHead = birthday.charAt(birthday.length - 1);
    return ssnHead % 2 ? "male" : "female";
  };

  const onSaveNewRegistration = async () => {
    setOpenCreateNewRegistrationModal(false);
    setLoading(true);
    try {
      const res = await api.createCustomer({
        phoneNumber,
        birthday: getBirthday(birthday),
        sex: getSex(),
        name,
        acquisitionChannels: acquisitionChannelsIds,
        smsEnabled,
        eventSmsEnabled,
        ssnHead: birthday,
      });
      const response = await res.json();

      if (response.data.id) {
        await api.createRegistrationRequests({ customerId: response.data.id });
        setOpenCompleteModal(true);
      }
    } catch (e) {
      sb.open("실패했습니다.");
      reset();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingBackground />}
      {openCreateNewRegistrationModal && (
        <CreateNewRegistrationModal
          open={openCreateNewRegistrationModal}
          onClose={(value) => {
            if (value) {
              onSaveNewRegistration();
            } else {
              reset();
              setOpenCreateNewRegistrationModal(false);
            }
          }}
        />
      )}
      <Container isLandscape={isLandscape}>
        {isLandscape ? (
          <>
            <LeftSection />
            <RightSection
              setOpenCreateNewRegistrationModal={
                setOpenCreateNewRegistrationModal
              }
              openCompleteModal={openCompleteModal}
              setOpenCompleteModal={setOpenCompleteModal}
            />
          </>
        ) : (
          <>
            <TopSection />
            <BottomSection
              setOpenCreateNewRegistrationModal={
                setOpenCreateNewRegistrationModal
              }
              openCompleteModal={openCompleteModal}
              setOpenCompleteModal={setOpenCompleteModal}
            />
          </>
        )}
      </Container>
    </>
  );
};
