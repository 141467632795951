import { format, parse, isValid } from "date-fns";

export const getBirthday = (birthday: string) => {
  const date = "20" + birthday.slice(0, -1);
  const parsedDate = parse(date, "yyyyMMdd", new Date());
  let day;
  if (isValid(parsedDate)) {
    day = new Date(format(new Date(parsedDate), "yyyy-MM-dd"));
  } else {
    day = new Date(format(new Date(0), "yyyy-MM-dd"));
  }

  if (day > new Date()) {
    day.setFullYear(day.getFullYear() - 100);
  }
  return format(new Date(day), "yyyy-MM-dd");
};
