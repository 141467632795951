import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Label = styled(FormControlLabel)(() => ({
  margin: 0,
  gap: "5px",
  ".MuiFormControlLabel-label": {
    fontSize: "13px",
  },
}));

const CustomCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  margin: 0,
  svg: {
    fontSize: "18px",
  },
}));

type CheckboxLabelProps = {
  checked: boolean;
  onChange: (value: any) => void;
  text: string;
};

export function CheckboxLabel({ checked, onChange, text }: CheckboxLabelProps) {
  return (
    <Label
      control={<CustomCheckbox checked={checked} />}
      label={text}
      onChange={(e: any) => onChange(e.target.checked)}
    />
  );
}
