import { useCallback, useEffect, useState } from "react";
import { forwardRef, useMemo } from "react";
import { unusedCode } from "~/utils/unusedCodeUtil";
import { DropdownList as _DropdownList } from "components/DropdownList";
import { styled } from "@mui/material";
import { useApi } from "providers/api";

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

type DepartmentSelectProps = {
  value: any;
  onChange: (value: any) => void;
};

const DepartmentSelect = forwardRef(
  ({ value, onChange }: DepartmentSelectProps, ref) => {
    const api = useApi();
    const [departmentList, setDepartmentCategory] = useState();

    const loadDepartments = useCallback(async () => {
      const res = await api.getDepartments();
      const payload = await res.json();
      let data = payload?.data;
      if (value?.category?.id) {
        if (data.find((f: any) => f.id === value?.category?.id) === undefined) {
          const department = {
            id: value.category.id,
            name: unusedCode.getNameByUnusedValue(value.category),
            departments: [
              {
                id: value.id,
                name: unusedCode.getNameByUnusedValue(value),
              },
            ],
          };
          data.push(department);
        } else {
          data = data.map((v: any) =>
            v.id === value.category.id &&
            v.departments.find((f: any) => f.id === value.id) == undefined
              ? {
                  ...v,
                  deparmtents: v.departments.push({
                    id: value.id,
                    name: unusedCode.getNameByUnusedValue(value),
                  }),
                }
              : v
          );
        }
      }

      setDepartmentCategory(data);
    }, [api, value]);

    useEffect(() => {
      loadDepartments();
    }, [loadDepartments]);

    const options = useMemo(() => {
      return (departmentList || [])
        .map((category: any) =>
          category.departments.map((v: any) => {
            const update = { ...v };
            update.name = `${category.name}-${update.name}`;
            update.label = update.name;
            return update;
          })
        )
        .flat();
    }, [departmentList]);

    return (
      <DropdownList
        options={options}
        value={options.find((f: any) => f.id === value?.id)}
        onChange={(v) => {
          onChange(v ? v : null);
        }}
        placeholder="부서를 선택하세요."
      />
    );
  }
);

export default DepartmentSelect;
