import { ReactNode, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface InfinityScrollProps {
  children: ReactNode;
  action: () => void;
  isLoading?: boolean;
  hasMore?: boolean;
  threshold?: number;
}

export function InfinityScroll({
  children,
  action,
  isLoading = false,
  hasMore = true,
  threshold = 0.8,
}: InfinityScrollProps) {
  const observerRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef(false);

  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    const targetRef = observerRef.current;
    if (!targetRef) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (!loadingRef.current && hasMore && entries[0].isIntersecting) {
          action();
        }
      },
      {
        threshold,
        rootMargin: "100px",
      }
    );

    observer.observe(targetRef);

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, [action, hasMore, threshold]);

  return (
    <div style={{ height: "100%", overflowY: "auto", position: "relative" }}>
      {children}
      <div
        ref={observerRef}
        style={{
          height: "10px",
          width: "100%",
          visibility: hasMore ? "visible" : "hidden",
        }}
      />
      {isLoading && hasMore && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  );
}

export default InfinityScroll;
