import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import { ReactNode } from "react";

type RoundFloatProps = {
  open: boolean;
  onClick: (value?: any) => void;
  children: ReactNode;
};

export function RoundFloat({ open, onClick, children }: RoundFloatProps) {
  return (
    <Zoom in={open} unmountOnExit>
      <Fab
        onClick={onClick}
        color="primary"
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
        }}
      >
        {children}
      </Fab>
    </Zoom>
  );
}
