import { ReactNode, forwardRef, useState } from "react";
import { Fade } from "@mui/material";
import { ClearButton } from "~/components/ClearButton";
import {
  Wrapper,
  StyledInput,
  InputStartAdornment,
  InputEndAdornment,
  EndAdornmentWrapper,
} from "./TextField.styled";

type TextFieldProps = {
  type: "default" | "search";
  inputType?: "text" | "password";
  defaultValue?: any;
  value: any;
  onClick?: (event?: any) => void;
  onChange?: (value?: any, event?: any) => void;
  onEnter?: (event?: any) => void;
  onClear?: (event?: any) => void;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disabled?: boolean;
  style?: any;
  maxLength?: number;
  className?: string;
};

export const TextField = forwardRef(
  (
    {
      type = "default",
      defaultValue,
      value,
      onClick,
      onChange,
      onEnter,
      placeholder,
      startAdornment,
      endAdornment,
      disabled,
      maxLength,
      style,
      onClear,
      inputType = "text",
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const handleChange = (e: any) => {
      onChange && onChange(e.target.value, e);
    };

    const onKeyDown = (e: any) => {
      if (e.keyCode == 13 && onEnter) {
        onEnter && onEnter();
      }
    };

    return (
      <Wrapper style={style}>
        <StyledInput
          $type={type}
          $active={focused}
          inputRef={ref}
          variant="outlined"
          type={inputType}
          defaultValue={defaultValue}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          disabled={disabled}
          inputProps={{
            maxLength,
          }}
          InputProps={{
            startAdornment: startAdornment && (
              <InputStartAdornment position="start">
                {startAdornment}
              </InputStartAdornment>
            ),
            endAdornment: (
              <EndAdornmentWrapper $disabled={disabled}>
                <Fade in={focused && value.length > 0}>
                  <InputEndAdornment position="end">
                    <ClearButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onClear ? onClear() : onChange && onChange("", e);
                      }}
                    />
                  </InputEndAdornment>
                </Fade>
                {endAdornment && endAdornment}
              </EndAdornmentWrapper>
            ),
          }}
          onClick={onClick}
          {...props}
        />
      </Wrapper>
    );
  }
);

export default TextField;
