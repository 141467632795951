import { styled } from "@mui/material";
import { hexToRgb } from "utils/colorUtil";

export const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  gap: 4px;
`;

export const Status = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 2px 6px;
  border-radius: 3px;
  background: ${({ color }: { color: string }) => {
    const rgb = hexToRgb(color);
    return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.6)`;
  }};
  border: 1px solid
    ${({ color }) => {
      const rgb = hexToRgb(color);
      return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 1)`;
    }};
  cursor: pointer;
`;
