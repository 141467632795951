import { unusedCode } from "~/utils/unusedCodeUtil";

export const getOptions = (data: any, value: any) => {
  const options = [...data];

  if (!value || !value.id) return options;

  return [
    {
      ...value,
      name: unusedCode.getNameByUnusedValue({ ...value }),
    },
    ...options,
  ];
};
