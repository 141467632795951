import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SlideModal, Wrapper, Body } from "components/Modal/SlideModal";
import { RegistrationForm } from "pages/AppointmentPage/form/RegistrationForm";
import { getCustomerName } from "utils/getCustomerName";
import {
  Header,
  Avatar,
  CustomerName,
  iconButtonStyle,
} from "./CreateRegistrationModal.styled";

export function CreateRegistrationModal({
  open,
  onClose,
  customer,
  registration,
  onSaveCallback,
  profileUrl,
}: {
  open?: any;
  onClose?: () => void;
  customer?: any;
  registration?: any;
  onSaveCallback?: (value?: any) => void;
  profileUrl?: string;
}) {
  return (
    <SlideModal open={open}>
      <Wrapper>
        <Header>
          <IconButton sx={iconButtonStyle} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <span className="title">
            <Avatar src={profileUrl} className={`${customer?.sex || ""}`} />
            <CustomerName>
              <b>{getCustomerName(customer)}</b>&nbsp;님의
              {registration?.id ? " 접수내역 수정" : " 접수등록"}
            </CustomerName>
          </span>
        </Header>
        <Body>
          <RegistrationForm
            registration={registration}
            customer={customer}
            onSaveCallback={() => {
              onSaveCallback && onSaveCallback();
              onClose && onClose();
            }}
          />
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
