import Icon from "./Icon";
import { Wrapper } from "components/EmptyView.styled";

type EmptyViewProps = {
  text: string;
};

export function EmptyView({ text, ...props }: EmptyViewProps) {
  return (
    <Wrapper {...props}>
      <Icon variant="empty" />
      <p>{text}</p>
    </Wrapper>
  );
}
