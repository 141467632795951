import styled from "styled-components";
import {
  TextField,
  Popper as MuiPopper,
  textFieldClasses,
  Autocomplete,
  autocompleteClasses,
} from "@mui/material";
import { ComponentType } from "react";

const fontSize = "12px";

export const Input = styled(TextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
      cursor: pointer;
    }

    & .MuiOutlinedInput-root {
      border-radius: 2px;
      height: 100%;
      padding: 0;
    }
  }
`;

export const Li = styled.li`
  min-height: 29px;
  font-size: ${fontSize};
  padding: 5px 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.listbox} {
    padding: 4px 0;
    max-height: 406px;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none;
    border: 1px solid #dee2ec;
    border-radius: initial;
  }

  .${autocompleteClasses.option} {
    background: none;
    &.Mui-focused {
      background-color: #f1f1f1;
    }
  }

  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 4px 10px;
    min-height: 100px;
  }
`;

export const AutoCompleteSelect = styled(Autocomplete)(
  ({
    theme,
    variant,
    $active,
  }: {
    theme: any;
    variant?: "default" | "search" | "none";
    $active?: boolean;
  }) => `
  &.${autocompleteClasses.root} {
    width: 100%;

    & .MuiOutlinedInput-notchedOutline {
      border: ${
        variant === "none"
          ? "none "
          : $active
          ? "solid 1px #2C62F6 "
          : variant === "search" && "none "
      };
      border-radius: ${variant === "search" && 0};
    }
  }

  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }

  .${autocompleteClasses.endAdornment} {
    right: 4px;
    position: relative;
    top: 8px;
    display: flex;
    align-items: center;
    color: ${theme.palette.grey["700"]};

    svg, path {
      fill: ${theme.palette.grey["700"]};
    }
  }

  .Mui-disabled {
    input {
      background: none;
    }
    button {
      border: 0 none;
    }
  }

  .${autocompleteClasses.clearIndicator} {
    margin-right: -10px;
    color: ${theme.palette.grey["700"]};

    svg, path {
      fill: ${theme.palette.grey["700"]};
    }
  }
`
) as ComponentType<any>;
