import MoreVertIcon from "@mui/icons-material/MoreVert";
import { grey } from "@mui/material/colors";
import { IconButton, Menu, MenuItem, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import { useDriveView } from "./DriveView";
import { useModal } from "hooks/useModal";
import { useApi } from "providers/api";
import { useSnackbar } from "SnackbarProvider";
import { ChangeNameDialog } from "./ChangeNameDialog";
import CheckIcon from "@mui/icons-material/Check";
import { DeleteConfirmDialog } from "./DeleteConfirmDialog";
import { TitleTypo } from "./TitleTypo";
import { ApiException } from "core/apiClient";
import { ReactComponent as BrokenFileIcon } from "assets/icons/ic-errorfile.svg";
import { useUser } from "providers/user";
import { CircularProgress, Box } from "./File.styled";

type FileProps = {
  file: any;
  onEnter: () => void;
  selecting: any;
  onChangeChecked: (value: boolean) => void;
  checked: boolean;
  readonly?: boolean;
};

export function File({
  file,
  onEnter,
  selecting,
  onChangeChecked,
  checked,
  readonly = false,
}: FileProps) {
  const api = useApi();
  const sb = useSnackbar();
  const { hasPermission } = useUser();

  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState(file.image);

  const broken = image.thumbnailStatus === "FAILED";

  const driveView = useDriveView();
  const changeNameModal = useModal();
  const deleteConfirmDialog = useModal();
  const permission =
    driveView.penchartDrive === true
      ? hasPermission("PENCHART_SAMPLE_DELETE")
      : hasPermission("PENCHART_DELETE");

  useEffect(() => {
    if (broken) return;
    if (image.thumbnailUrl) return;

    const interval = setInterval(async () => {
      const res = await api.getImage(image.id);
      const resBody = await res.json();
      setImage(resBody.data);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [api, broken, image]);

  const onClickMenu = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onClickCopy = async () => {
    try {
      await api.copyFile(file.id, file.parent.id);
      sb.open("파일을 복사했습니다.");
    } catch (e) {
      if (e instanceof ApiException && e.code === 404) {
        sb.open("작업에 실패했습니다. 삭제되었거나 없는 항목입니다.");
        return;
      }
      sb.open("작업에 실패했습니다.");
      throw e;
    } finally {
      driveView.reload();
      closeMenu();
    }
  };

  const onClickChangeName = async () => {
    changeNameModal.open();
    closeMenu();
  };

  const onClickDelete = async () => {
    deleteConfirmDialog.open();
    closeMenu();
  };

  const onConfirmChangeName = async (name: string) => {
    await api.updateFile(file.id, {
      name,
    });
    driveView.reload();
    sb.open("파일 이름을 변경했습니다.");
    changeNameModal.close();
  };

  const onClick = (e: any) => {
    e.stopPropagation();
    if (selecting) {
      onChangeChecked(!checked);
    } else {
      if (!image.originalUrl) {
        sb.open("이미지 업로드가 완료되지 않았습니다.");
        return;
      }
      const img = new Image();
      img.onload = () => {
        // console.log("이미지 로드 성공");
        onEnter();
      };
      img.onerror = () => {
        console.error("이미지 로드 실패");
        sb.open("정상적인 파일이 아닙니다.");
      };
      img.src = image.originalUrl;
    }
  };

  const onDelete = async () => {
    try {
      await api.deleteFile(file.id);
      sb.open("파일을 삭제했습니다.");
    } catch (e) {
      if (e instanceof ApiException && e.code === 404) {
        sb.open("작업에 실패했습니다. 삭제되었거나 없는 항목입니다.");
        return;
      }

      sb.open("작업에 실패했습니다.");
      throw e;
    } finally {
      driveView.reload();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "14px",
        }}
        onClick={onClick}
      >
        <Paper
          sx={{
            borderRadius: 1,
            border: `1px solid ${grey[300]}`,
            overflow: "hidden",
          }}
          elevation={0}
        >
          <Box
            sx={{
              height: 280,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {broken && <BrokenFileIcon />}
            {!broken && image.thumbnailUrl == null && (
              <Box className="spinner-wrapper">
                <CircularProgress />
              </Box>
            )}
            {!broken && image.thumbnailUrl && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url(${image.thumbnailUrl})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundColor: "#f1f1f1",
                }}
              />
            )}
          </Box>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleTypo>{file.name}</TitleTypo>
          {!readonly && !selecting && (
            <IconButton onClick={onClickMenu} sx={{ padding: 0 }}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
        {checked && (
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 28,
              height: 28,
              transform: "translate(50%, -50%)",
              color: "white",
              backgroundColor: "primary.main",
            }}
          >
            <CheckIcon color="inherit" />
          </IconButton>
        )}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={onClickCopy}>복사</MenuItem>
        <MenuItem onClick={onClickChangeName}>이름 변경</MenuItem>
        {permission && <MenuItem onClick={onClickDelete}>삭제</MenuItem>}
      </Menu>
      <ChangeNameDialog
        label="파일 이름"
        defaultName={file.name}
        open={changeNameModal.opened}
        onClose={changeNameModal.close}
        onConfirm={onConfirmChangeName}
      />
      <DeleteConfirmDialog
        open={deleteConfirmDialog.opened}
        onClose={deleteConfirmDialog.close}
        onConfirm={onDelete}
      />
    </>
  );
}
