import { observer } from "mobx-react";
import { useSnackbar } from "SnackbarProvider";
import { translate } from "~/utils/filters";
import { TextField as _TextField } from "~/components/TextField";
import { ComponentType } from "react";

const TextField = _TextField as ComponentType<any>;

type PhoneProps = {
  phone: string;
  onChange: (params?: any) => void;
  className?: string;
  placeholder?: string;
  tabIndex?: number;
  disabled?: boolean;
  border?: boolean;
};

const Phone = ({
  phone,
  onChange,
  className,
  placeholder,
  tabIndex,
  disabled,
  border = false,
}: PhoneProps) => {
  const snackbar = useSnackbar();

  const onChangePhone = (value: string, event: Event) => {
    event.preventDefault();

    if (!/^[0-9-]*$/.test(value)) {
      snackbar.open(translate("ERROR_NUMBERS_ONLY"));
      return;
    }

    if (value.length > 13) {
      snackbar.open(translate("ERROR_PHONE_TOO_LONG"));
      return;
    }

    const numberTemp = (value || "")
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
        "$1-$2-$3"
      )
      .replace("--", "-");
    onChange(numberTemp);
  };

  return (
    <div className={`phone ${className}`}>
      <TextField
        tabIndex={tabIndex}
        value={(phone || "")
          .replace(/[^0-9]/g, "")
          .replace(
            /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
            "$1-$2-$3"
          )
          .replace("--", "-")}
        onChange={onChangePhone}
        placeholder={placeholder}
        disabled={disabled}
        $border={border}
      />
    </div>
  );
};

export default observer(Phone);
