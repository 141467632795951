export const TabType = {
  appointment: "APPOINTMENT",
  registration: "REGISTRATION",
  consulting: "CONSULTING",
  treatment: "TREATMENT",
  surgery: "SURGERY",
  nurseOperation: "NURSE_OPERATION",
  nurseCare: "NURSE_CARE",
  skinCare: "SKINCARE",
  payment: "PAYMENT",
  penchart: "PENCHART",
  sms: "SMS",
  callHistory: "CALL_HISTORY",
  prescriptions: "PRESCRIPTIONS",
};
