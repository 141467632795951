import { useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { Box, Button } from "@mui/material";
import { maskName, maskBirthday } from "utils/maskString";

export const CustomersList = ({ customers, onClick }) => {
  const { isLandscape } = useContext(SimpleRegistrationsContext);

  return (
    <Box
      sx={{
        marginBottom: "3%",
        border: "solid 1px #DEE2EC",
      }}
    >
      {customers.map((customer, i) => (
        <div
          key={customer.customerId}
          style={{
            borderBottom:
              i === customers.length - 1 ? "none" : "solid 1px #DEE2EC",
            height: isLandscape ? "10vh" : "8vh",
            padding: "2%",
            fontSize: "3vw",
            display: "flex",
            gap: "3%",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              width: "40vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {maskName(customer.name ?? "")}
          </div>
          <div style={{ width: "5vw" }}>
            {customer.sex === "female" ? "여" : "남"}
          </div>
          <div style={{ width: isLandscape ? "35vw" : "30vw" }}>
            {maskBirthday(customer.birthday)}
          </div>
          <div style={{ width: isLandscape ? "10vw" : "15vw" }}>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: isLandscape ? "7vh" : "5vh",
                fontSize: isLandscape ? "2vw" : "3vw",
              }}
              onClick={() => onClick(customer)}
            >
              접수
            </Button>
          </div>
        </div>
      ))}
    </Box>
  );
};
