import { Box as MuiBox, styled } from "@mui/material";
import portraitImage from "assets/images/background-portrait.png";

export const Box = styled(MuiBox)`
  &.top-wrapper {
    width: 100vw;
    height: 30vh;

    .background-image-wrapper {
      width: 100%;
      height: 100%;
      background-image: url(${portraitImage});
      background-size: 100% 100%;
      padding: 3%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .title-wrapper {
      font-size: 2vw;
      font-weight: bold;
      color: white;
      display: flex;
      justify-content: space-between;
    }

    .description-wrapper {
      display: flex
      flex-direction: column;
      padding: 5%;

      .description {
        font-weight: bold;
        color: white;
        margin: 0;
      }
      .font-size-5vw {
        font-size: 5vw;
      }
      .font-size-3vw {
        font-size: 3vw;
      }
    }
  }
`;
