import { styled, Box as MuiBox, Stack as MuiStack } from "@mui/material";
import { Button as _Button } from "components/Button";

export const Box = styled(MuiBox)(
  ({ theme }) => `
margin: 4px 0;
color: ${theme.palette.grey["700"]};
`
);

export const Stack = styled(MuiStack)`
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;

export const Button = styled(_Button)(
  () => `
  height: 54px !important;
`
);

export const ButtonWrapper = styled(Box)`
  width: 100%;
`;

export const TextFieldBox = styled(Box)`
  width: 110px;
  margin-right: 5px;

  &.m-l-r-5 {
    margin: 5px;
  }
`;

export const BlueTextBox = styled(MuiBox)(
  ({ theme }) => `
color: ${theme.palette.unoblue};
font-weight: 700;
`
);
