import { useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingBackground = () => {
  const { isLandscape, showNewCustomerText } = useContext(
    SimpleRegistrationsContext
  );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100%",
        position: "absolute",
        zIndex: 10,
        background: "rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            position: "absolute",
            zIndex: 1,
            bottom: isLandscape ? "25%" : "36%",
          }}
        >
          {showNewCustomerText && (
            <>
              <Box sx={{ fontSize: "5vw" }}>환영합니다!</Box>
              <Box sx={{ fontSize: "3vw" }}>신규 고객 접수를 시작합니다.</Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
