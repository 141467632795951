import { styled } from "@mui/material";
import { TabType } from "constant/Tabs";
import { RegistrationConnectChart } from "./RegistrationConnectChart";
import { getCardType } from "utils/tabUtils";

const RegistrationWrapper = styled("div")`
  hr {
    margin: 10px 0;
    background: #dee2ec;
    height: 1px;
    border: 0;
  }
`;

export const ConnectRegistrationItems = ({ item }: { item: any }) => {
  const {
    consultings,
    treatments,
    surgeries,
    nurseCares,
    nurseOperations,
    skincares,
    payments,
  } = item;

  const regstrationConnectCharts = [
    { key: TabType.consulting, value: consultings },
    { key: TabType.treatment, value: treatments },
    { key: TabType.surgery, value: surgeries },
    { key: TabType.nurseCare, value: nurseCares },
    { key: TabType.nurseOperation, value: nurseOperations },
    { key: TabType.skinCare, value: skincares },
    { key: TabType.payment, value: payments },
  ];

  return item.type === "REGISTRATION" && item.progress === "OPEN" ? (
    <RegistrationWrapper>
      {getCardType(item) && <hr />}
      {regstrationConnectCharts.map((v) => (
        <RegistrationConnectChart key={v.key} chart={v} />
      ))}
    </RegistrationWrapper>
  ) : (
    <></>
  );
};
