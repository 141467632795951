import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useApi } from "./api";
import { useUser } from "providers/user";

interface ClinicContextValue {
  clinic: any;
  isMasking: boolean;
}

const ClinicContext = createContext<ClinicContextValue | null>(null);

export function ClinicProvider({ children }: { children: ReactNode }) {
  const api = useApi();
  const { user } = useUser();
  const clinicId = user?.clinic?.id;

  const [clinic, setClinic] = useState<any>({});
  const [clinicConfig, setClinicConfig] = useState<any>([]);

  const loadClinic = useCallback(async () => {
    let res;
    try {
      res = await api.getClinic(clinicId);
    } catch (e) {
      return;
    }

    const payload = await res.json();
    setClinic(payload.data);
  }, [api, clinicId]);

  const loadClinicConfigs = useCallback(async () => {
    const res = await api.getConfigs({
      groupName: "privacy",
    });
    const configData = await res.json();

    setClinicConfig(configData.data);
  }, [api]);

  useEffect(() => {
    if (!clinicId) return;

    loadClinic();
    loadClinicConfigs();
  }, [loadClinic, loadClinicConfigs, clinicId]);

  const isMasking = useMemo(() => {
    const data = clinicConfig ?? [];
    return data.length > 0
      ? data[0].codeValue === "True"
        ? true
        : false
      : false;
  }, [clinicConfig]);

  return (
    <ClinicContext.Provider value={{ clinic, isMasking }}>
      {children}
    </ClinicContext.Provider>
  );
}

export function useClinic() {
  return useContext(ClinicContext) as ClinicContextValue;
}
