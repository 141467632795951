import { styled, css } from "@mui/material";
import { ReactNode } from "react";

const Wrapper = styled("div")<{ disabled?: boolean }>`
  position: relative;
  label {
    color: ${({ disabled }) => (disabled ? "#adaebc" : "#282828")};
  }
`;

const LabelDom = styled("label")<{ $isRequire?: boolean }>`
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
  ${({ $isRequire, theme }) =>
    $isRequire &&
    css`
      &::after {
        content: " *";
        color: ${theme.palette.error["main"]};
      }
    `}
`;

const SubLabel = styled("span")`
  font-size: 11px;
  color: #f7685b;
  margin-left: 4px;
`;

export const LabelWrapper = styled("div")<{ column?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ column }) => column ?? 2}, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  align-items: end;
`;

export const InnerBoxStyle = css`
  width: 100%;
  height: 29px;
  border: 1px solid #dee2ec;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 12px;
  transition: border-color 0.1s;
  &:focus {
    border-color: #2c62f6;
  }
`;

const InnerWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 29px;
`;
type LabelProps = {
  isRequire?: boolean;
  disabled?: boolean;
  text: ReactNode;
  helperText?: ReactNode;
  children?: ReactNode;
  style?: any;
};

export const Label = ({
  isRequire = false,
  disabled = false,
  text = "",
  helperText = "",
  children = <></>,
  style = {},
  ...props
}: LabelProps) => {
  return (
    <Wrapper disabled={disabled} style={style} {...props}>
      {text && (
        <LabelDom $isRequire={Boolean(isRequire)}>
          {text}
          {helperText && <SubLabel>{helperText}</SubLabel>}
        </LabelDom>
      )}
      {children && <InnerWrapper>{children}</InnerWrapper>}
    </Wrapper>
  );
};
