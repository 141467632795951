import {
  Box as MuiBox,
  Stack as MuiStack,
  IconButton as MuiIconButton,
  styled,
  Typography as MuiTypography,
  typographyClasses,
  buttonBaseClasses,
  buttonClasses,
  radioClasses,
  inputBaseClasses,
  formControlLabelClasses,
  checkboxClasses,
} from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";
import { disabledBgColor } from "utils/surveyHelper";

export const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  
  width: 100%;
  resize: vertical;
  &:hover, &:focus {
    border: 1px solid ${theme.palette.primary["main"]};
  }
  &:disabled {
    color: ${theme.palette.grey["700"]};
  }
  &:hover, &:focus {
    border-color: ${theme.palette.layout.line};
  }

  border-color: ${theme.palette.layout.line};
  border-radius: 4px;

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: ${theme.palette.bluegrey["600"]};
    font-weight: normal;
  }
  
`
);

export const IconButton = styled(MuiIconButton)(
  () => `
  width: 28px;
  height: 28px;
  padding: 2px;
  margin-left: auto;
`
);

export const Typography = styled(MuiTypography)(
  () => `
  &.${typographyClasses.h2} {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  &.${typographyClasses.h3} {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px;
  }
  &.${typographyClasses.body1} {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`
);

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.content {
    background-color: ${theme.palette.bluegrey["100"]};
    padding: 16px 20px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.38px;

  }

  &.content-radio {
    position: relative;

    .radio {
      flex-direction: column;
      gap: 10px;

      .${typographyClasses.body1} {
        font-size: 14px;
        line-height: 20px;
      }
    }
    
    .${radioClasses.disabled}{
      span {
        &::before {
          background-color: ${theme.palette.primary["main"]};
        }
      }
    }

    .${radioClasses.root} {
      span {
        border-color: ${theme.palette.layout.line};
        background: #ffffff;
      }
    }

    .reset-radio-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${theme.palette.grey["700"]};
      text-decoration: underline;
      padding: 8px;
      background: rgba(255,255,255,0.7);
    }
  }

  &.textarea-box {
    textarea {
      padding: 8px;
      background: #ffffff;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      &:disabled {
        background-color: ${theme.palette.grey["200"]} !important;
      }
    }

    .${typographyClasses.body1} {
      white-space: pre-wrap;
    }

    &.white-disabled-mode {
      textarea:disabled {
        background: #ffffff !important;
      }
    }
  }

 

  &.date-selector-box {
    width: 100%;
    max-width: 270px;

    &.full-width {
      max-width: unset;
    }
  }
`
);

export const Stack = styled(MuiStack)(
  ({ theme }) => `
  &.mt-10 {
    margin-top: 10px;
  }

  &.btn-wrappers {
    width: 100%;
    gap: 10px;
    .${buttonBaseClasses.root} {
      width: 100%;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      padding: 14px;
      display: flex;
      align-items: center;
    }
    .${buttonClasses.outlined}{
      border-color: ${theme.palette.grey["700"]};
      color: ${theme.palette.grey["700"]};
    }
  }

   &.content-checkbox {
    .${typographyClasses.body1} {
      font-size: 14px;
      line-height: 20px;
    }

    .${checkboxClasses.root}.${checkboxClasses.checked}.${checkboxClasses.disabled} {
      svg {
        rect {
          fill: ${theme.palette.primary["main"]};
        }
      }
    }
  }

  .date-selector {
    border: 1px solid ${theme.palette.layout.line};
    border-radius: 4px;
    width: 100% !important;

    input {
      width: 100%;
      background-image: unset !important;
      border:0 none;
      &:disabled {
        background-color: ${theme.palette.grey["200"]};
        color: ${theme.palette.grey["700"]};
      }

      &::placeholder {
        color: ${theme.palette.bluegrey["600"]} important;
        opacity: 1;
      } 
    }

    &:disabled {
      input {
        &:disabled {
          background-color: ${disabledBgColor};
        }
      }
    }
  }

  &.hours-selector, &.minutes-selector {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.38px;
    border: 0 none !important;
    width: 100px;
    flex-shrink: 0;
    gap: 10px;

    .${inputBaseClasses.root} {
      background-color: #ffffff;

      input {
        &::placeholder {
          color: ${theme.palette.bluegrey["600"]};
          opacity: 1;
        }
      }
    }

    .${inputBaseClasses.disabled} {
      background-color: ${theme.palette.grey["200"]};
      input {
        /** -webkit-text-fill-color: ${theme.palette.grey["700"]}; */
      }
    }

    .${typographyClasses.body1} {
      min-width: 40px;
    }

    fieldset {
      border-color: ${theme.palette.layout.line};
      border-radius: 4px;
    }
  }

  &.agreement {
    .${formControlLabelClasses.label}{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
      margin-top: -2px;
    }
    .${formControlLabelClasses.label}.${formControlLabelClasses.disabled} {
      color: ${theme.palette.grey["700"]};
    }

    .${checkboxClasses.root}.${checkboxClasses.checked}.${checkboxClasses.disabled} {
      svg {
        rect {
          fill: ${theme.palette.primary["main"]};
        }
      }
    }

    &.checkbox-wrapper {
     .${formControlLabelClasses.root}{
        width: 100%;
        margin-bottom: 10px;
      }
      .${typographyClasses.body1}{
        font-weight: 700;
      }

      .${formControlLabelClasses.root}{
        width: 100%;
      }
    }
  }
`
);
