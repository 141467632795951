import { createContext, useMemo } from "react";
import { NavPage } from "../Nav/NavPage";
import { Info } from "pages/AppointmentPage/list/Info";
import { List } from "pages/AppointmentPage/list/List";
import { ControlHeader } from "pages/AppointmentPage/list/Header";
import { useAppointment } from "hooks/useAppointment";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { Wrapper } from "pages/AppointmentPage/AppointmentPage.styled";
import appointments from "store/appointments";
import { observer } from "mobx-react";
import { useClinic } from "providers/clinic";

export const AppointmentContext = createContext<any>(null);
export const AppointmentPage = observer(() => {
  const clinic = useClinic();
  const { setDepartmentId: setDepartmentIdInStore } = appointments;
  const [searchParams, setSearchParams] = useSearchParams({
    date: format(new Date(), "yyyy-MM-dd"),
  });

  const date = useMemo(
    () =>
      searchParams.get("date")
        ? new Date(searchParams.get("date") as string)
        : new Date(),
    [searchParams]
  );

  const departmentId = useMemo(
    () =>
      searchParams.get("departmentId")
        ? searchParams.get("departmentId")
        : null,
    [searchParams]
  );

  const appointment = useAppointment({
    date,
    customerId: null,
    initOrder: { order: "asc", target: "startHour" },
    departmentId,
    clinic: clinic.clinic,
  });

  const setDate = (d: any) => {
    setSearchParams(
      {
        ...searchParams,
        date: format(new Date(d), "yyyy-MM-dd"),
      },
      {
        replace: true,
      }
    );
    appointment.setDepartmentId(null);
    setDepartmentIdInStore(null);
    appointment.setPage(1);
  };

  const setDepartmentId = (departmentId: null | number) => {
    setSearchParams(
      {
        ...searchParams,
        date: format(new Date(date), "yyyy-MM-dd"),
        departmentId: String(departmentId),
      },
      {
        replace: true,
      }
    );
    appointment.setDepartmentId(departmentId);
    setDepartmentIdInStore(departmentId);
  };

  return (
    <AppointmentContext.Provider
      value={{
        ...appointment,
        date,
        setDate,
        departmentId,
        setDepartmentId,
      }}
    >
      <NavPage title={<ControlHeader />}>
        <Wrapper>
          <Info />
          <List />
        </Wrapper>
      </NavPage>
    </AppointmentContext.Provider>
  );
});
