import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { StatusColor } from "core/statusColor";
import * as Sentry from "@sentry/react";
import { useApi } from "providers/api";
import { useAuth } from "./auth";
import { SessionStatusConfigType } from "type/statusSettings";

export const defaultSettings = [
  {
    id: 1,
    code: StatusColor.scheduled,
    name: "예약",
    color: "#F0C324",
    type: "APPOINTMENT",
  },
  {
    id: 2,
    code: StatusColor.canceled,
    name: "예약취소",
    color: "#E76464",
    type: "APPOINTMENT",
  },
  {
    id: 3,
    code: StatusColor.no_show,
    name: "미방문",
    color: "#373737",
    type: "APPOINTMENT",
  },
  {
    id: 4,
    code: StatusColor.consulting_waiting,
    name: "상담대기",
    color: "#56B1E4",
    type: "REGISTRATION",
  },
  {
    id: 5,
    code: StatusColor.consulting_during,
    name: "상담중",
    color: "#5A82F2",
    type: "REGISTRATION",
  },
  {
    id: 6,
    code: StatusColor.treatment_waiting,
    name: "진료대기",
    color: "#8692FF",
    type: "REGISTRATION",
  },
  {
    id: 7,
    code: StatusColor.treatment_during,
    name: "진료중",
    color: "#6563E7",
    type: "REGISTRATION",
  },
  {
    id: 8,
    code: StatusColor.surgery_waiting,
    name: "시/수술대기",
    color: "#EF8D4F",
    type: "REGISTRATION",
  },
  {
    id: 9,
    code: StatusColor.surgery_during,
    name: "시/수술중",
    color: "#FF508C",
    type: "REGISTRATION",
  },
  {
    id: 10,
    code: StatusColor.payment_waiting,
    name: "수납대기",
    color: "#30C184",
    type: "REGISTRATION",
  },
  {
    //완료상태 (consulting_done, treatment_done, .... 기타 _done 상태)
    id: 11,
    code: StatusColor.complete,
    name: "완료",
    color: "#727776",
    type: "REGISTRATION",
  },
  {
    id: 12,
    code: StatusColor.leave,
    name: "퇴원",
    color: "#D1E20F",
    type: "REGISTRATION",
  },
  {
    id: 13,
    code: StatusColor.absence,
    name: "휴진",
    color: "#A4A4A4",
    type: "ABSENCE",
  },
  {
    id: 14,
    code: StatusColor.registration_canceled,
    name: "접수취소",
    color: "#546B90",
    type: "REGISTRATION",
  },
];

const unknownSettings = {
  code: StatusColor.unknown,
  name: "UNKNOWN",
  color: "#202020",
};

const StatusSettingsContext = createContext<null | StatusSettings>(null);

export interface StatusSettings {
  getStatusSettings: (id: string) => void;
  settings: SessionStatusConfigType[];
}

export const StatusSettingsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const api = useApi();
  const { authed }: any = useAuth();
  const [settings, setSettings] =
    useState<SessionStatusConfigType[]>(defaultSettings);

  const loadConfig = async () => {
    const res = await api.getStatusSettings();
    const data = await res.json();
    setSettings([...data.data]);
  };

  useEffect(() => {
    if (authed) {
      loadConfig();
    }
  }, [authed]);

  const getStatusSettings = useCallback(
    (id: string) => {
      const setting = settings.find(
        (item: { code: string }) => item.code == id
      );
      if (!setting) {
        Sentry.captureException(
          new Error(`Color settings for ${id} does not exist.`)
        );
        return unknownSettings;
      }

      return setting;
    },
    [settings]
  );

  const providerValue = useMemo(
    () => ({
      getStatusSettings: getStatusSettings,
      settings: settings,
    }),
    [settings, getStatusSettings]
  );

  return (
    <StatusSettingsContext.Provider value={providerValue}>
      {children}
    </StatusSettingsContext.Provider>
  );
};

export function useStatusSettings() {
  return useContext(StatusSettingsContext);
}
