import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Icon from "components/Icon";

const Name = styled.div`
  color: #2c62f6;
  max-width: 30vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Span = styled.span`
  color: #2c62f6;
`;

export const LandscapeCompleteConetents = ({ name }) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
        <Box sx={{ fontWeight: "bold", fontSize: "60px", lineHeight: "80px" }}>
          <Box sx={{ display: "flex" }}>
            <Name>{name}</Name>님
          </Box>
          접수가 완료되었습니다.
          <br />
          대기해주세요 :)
          <br />
        </Box>
        <Box sx={{ fontWeight: "bold", fontSize: "2.5vw" }}>
          <Span>5초</Span> 뒤에 첫 화면으로 돌아갑니다.
        </Box>
      </Box>
      <div>
        <Icon variant="thumb" />
      </div>
    </>
  );
};
