import Radio, { RadioProps } from "@mui/material/Radio";
import { RadioCheckedIcon, RadioIcon } from "./Radio.styled";

export const CustomRadio = (props: RadioProps) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  );
};
