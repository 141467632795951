import { useState, useRef, Fragment, ComponentType } from "react";
import { Checkbox } from "../Checkbox/Checkbox";
import { DataTableHead } from "./DataTableHead";
import { CollapseTr } from "./CollapseTr";
import { PlaceHolder as _PlaceHolder } from "./PlaceHolder";
import { ResizeButton, Table, Tbody, Tr, Td } from "./DataTable.styled";
import { ColumnValue, LoadingIcon } from "./DataTableUtils.styled";
import { CollapseButton } from "./CollapseButton";
import { Box as MuiBox } from "@mui/material";
import { RowGroup } from "./RowGroup";

const Box = MuiBox as ComponentType<any>;
const PlaceHolder = _PlaceHolder as ComponentType<any>;

const createHeaders = (headers: any[]) => {
  return headers.map((item: any) => ({
    text: item,
    ref: { current: null },
    headerRef: { current: null },
    active: false,
  }));
};

type DataCellProps = {
  item: any;
  column: any;
  index: number;
};

export const DataCell = ({ item, column, index }: DataCellProps) => {
  if (column.value) {
    if (Array.isArray(column.value(item))) {
      return <ColumnValue>{column.value(item)[index]}</ColumnValue>;
    }
    return <ColumnValue>{column.value(item, index)}</ColumnValue>;
  }

  if (column.component) {
    return <column.component item={item} />;
  }

  throw new Error("invalid column schema");
};

type DataTableProps = {
  resizable?: boolean;
  loading?: boolean;
  placeholder?: string;
  data: any[];
  schema: any;
  sorts?: any[];
  onChangeSorts?: (params1?: any, params2?: any) => void;
  checkedAll?: boolean;
  checked?: any[];
  checkedItems?: any[];
  setCheckedItems?: (params?: any) => void;
  onChangeChecked?: (params?: any) => void;
  collapsed?: any[];
  onChangeCollapsed?: (params?: any) => void;
  onDoubleClickItem?: (params?: any) => void;
  expanded?: any[];
  HoverButton?: any;
  className?: string;
  style?: any;
};

export const DataTable = ({
  resizable = false,
  loading,
  placeholder,
  data = [],
  schema,
  sorts,
  onChangeSorts,
  checkedAll,
  checked,
  checkedItems,
  setCheckedItems,
  onChangeChecked,
  collapsed,
  onChangeCollapsed,
  onDoubleClickItem,
  expanded = [],
  HoverButton,
  ...props
}: DataTableProps) => {
  const tbodyRef = useRef([]);
  const [columnsRef, setColumnsRef] = useState(
    resizable ? createHeaders(schema.columns.map((v: any) => v.id) ?? []) : []
  );

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const onCheckAll = (v: any) => {
    if (v) {
      onChangeChecked && onChangeChecked(data.map((item) => item.id));
      setCheckedItems && setCheckedItems(data);
    } else {
      onChangeChecked && onChangeChecked([]);
      setCheckedItems && setCheckedItems([]);
    }
  };

  const onCheckItemValue = (value: any, v: any) => {
    if (v) {
      setCheckedItems &&
        checkedItems !== undefined &&
        setCheckedItems([value, ...checkedItems]);
    } else {
      setCheckedItems &&
        checkedItems !== undefined &&
        setCheckedItems(checkedItems.filter((c) => c.id !== value.id));
    }
  };

  const onCheckItem = (id: any, v: any) => {
    if (v) {
      onChangeChecked &&
        checked !== undefined &&
        onChangeChecked([id, ...checked]);
    } else {
      onChangeChecked &&
        checked !== undefined &&
        onChangeChecked(checked.filter((c) => c !== id));
    }
  };

  const onCollapseAll = (v: any) => {
    if (v) {
      onChangeCollapsed && onChangeCollapsed(data.map((item) => item.id));
    } else {
      onChangeCollapsed && onChangeCollapsed([]);
    }
  };

  const onCollapseItem = (id: any) => {
    if (collapsed !== undefined && !collapsed.includes(id)) {
      onChangeCollapsed && onChangeCollapsed([id, ...collapsed]);
    } else {
      collapsed !== undefined &&
        onChangeCollapsed &&
        onChangeCollapsed(collapsed.filter((c) => c !== id));
    }
  };

  // [] -> [[]]
  const convertToRowArray = (arr: any[]) => {
    if (Array.isArray(schema.columns[0])) return arr;
    else return [arr];
  };
  // if map
  const convertMapArray = (arr: any[], item: any) => {
    const convertArr = convertToRowArray(arr);
    const matchSchemaIndex = 0;
    const mapCol = convertArr[matchSchemaIndex].filter(
      (v: any) => v.value && Array.isArray(v.value(item))
    );
    const mapLength = Math.max.apply(
      null,
      mapCol.map((v: any) => v.value(item).length)
    );

    // layout check
    if (
      !mapCol
        .map((v: any) => v.value(item).length)
        .every((val: any, i: number, arr: any[]) => val === arr[0])
    ) {
      throw new Error("The array length of item should be the same.");
    }

    // set rowspan
    convertArr[matchSchemaIndex].map((v: any) => {
      if (
        v.rowSpan !== "100%" &&
        (v.component || !Array.isArray(v.value(item)))
      )
        v.rowSpan = (isFinite(mapLength) && mapLength) || 1;
      return v;
    });

    if (!mapCol.length || !mapLength) return convertArr;
    // add columns
    const updateArr = convertArr
      .slice(matchSchemaIndex, 1)
      .concat(new Array(mapLength - 1).fill(null).map(() => mapCol));

    return convertArr
      .map((v, i) => {
        if (i === matchSchemaIndex) return updateArr;
        return [v];
      })
      .flat();
  };

  const FlatRows = ({ tdColumns, item, trIndex = 0 }: any) => {
    return tdColumns.map((tdColumn: any, tdIndex: number) => (
      <Td
        key={`td-${item.id}-${tdIndex}`}
        style={tdColumn.style}
        $grow={tdColumn.grow}
        rowSpan={
          tdColumn.rowSpan === "100%"
            ? convertMapArray(schema.columns, item).length
            : tdColumn?.rowSpan
        }
        colSpan={tdColumn?.colSpan}
      >
        <div
          ref={(el) => {
            const resizeObj: any = columnsRef.find(
              (f) => f.text === tdColumn.id
            );
            if (resizeObj?.ref?.current && el !== null) {
              resizeObj.ref.current = el;
            }
          }}
        >
          <DataCell item={item} index={trIndex} column={tdColumn} />
        </div>
      </Td>
    ));
  };

  const GroupRows = ({ data, schema }: any) => {
    const rows = data;
    const groupKeys = Array.from(
      new Set(rows.map((row: any) => schema.rowGroup.groupKey(row)))
    );
    const groupData = groupKeys.map((v) => schema.rowGroup.groupData(v));
    return (
      <>
        {groupData.map((v, gi) => {
          const filterRows = rows.filter(
            (row: any) =>
              schema.rowGroup.groupKey(row) === v[schema.rowGroup.groupId] &&
              expanded.indexOf(schema.rowGroup.groupKey(row)) > -1
          );
          return (
            <>
              <Tr
                key={`tr-${v.id}-${gi}`}
                trStyle={schema?.rows?.style(v)}
                firstTr={true}
              >
                <RowGroup item={v} column={schema.rowGroup} />
              </Tr>
              {filterRows.map((row: any, trIndex: number) => {
                return (
                  <Tr
                    key={`tr-${row.id}-${trIndex}`}
                    trStyle={schema?.rows?.style(row)}
                    firstTr={true}
                  >
                    <FlatRows tdColumns={schema.columns} item={row} />
                  </Tr>
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  return (
    <Table
      $resizable={resizable}
      onMouseLeave={() => setHoverIndex(null)}
      {...props}
    >
      <DataTableHead
        resizable={resizable}
        tbodyRef={tbodyRef}
        columnsRef={columnsRef}
        setColumnsRef={setColumnsRef}
        sorts={sorts}
        onChangeSorts={(sort: any) => {
          onChangeSorts &&
            onChangeSorts(sort.id, sort.value === "desc" ? "asc" : "desc");
        }}
        checkedAll={checkedAll}
        checked={
          checkedAll
            ? true
            : checked
            ? data.every((item) => checked.includes(item.id))
            : undefined
        }
        onCheckAll={onCheckAll}
        collapsed={
          collapsed
            ? data.every((item) => collapsed.includes(item.id))
            : undefined
        }
        onCollapseAll={onCollapseAll}
        columns={convertToRowArray(schema.columns)[0]}
        headers={schema.headers}
      />
      <Tbody ref={tbodyRef}>
        {loading && (
          <PlaceHolder>
            <LoadingIcon size={20} thickness={5} />
          </PlaceHolder>
        )}
        {!loading &&
          (!data.length ? (
            <PlaceHolder>
              {placeholder ?? "등록된 내용이 없습니다."}
            </PlaceHolder>
          ) : schema.rowGroup ? (
            <GroupRows data={data} schema={schema} />
          ) : (
            data.map((item, i) => (
              <Fragment key={item.id ?? i}>
                {convertMapArray(schema.columns, item).map(
                  (trColumn, trIndex) => (
                    <Tr
                      key={`tr-${item.id}-${trIndex}`}
                      $highlight={
                        hoverIndex === i || checked?.includes(item.id)
                      }
                      onMouseOver={() => setHoverIndex(i)}
                      onDoubleClick={() =>
                        onDoubleClickItem && onDoubleClickItem(item)
                      }
                      firstTr={trIndex === 0}
                      $bg={i % 2 === 0}
                      trStyle={schema?.rows?.style(item)}
                    >
                      {trIndex === 0 && checked && (
                        <Td
                          rowSpan={convertMapArray(schema.columns, item).length}
                          style={{
                            padding: "6px 8px !important",
                            width: "32px !important",
                            "> span": { padding: "0 !important" },
                          }}
                        >
                          <Checkbox
                            disabled={checkedAll}
                            checked={
                              checkedAll ? true : checked.includes(item.id)
                            }
                            onChange={(e) => {
                              onCheckItem(item.id, e.target.checked);
                              setCheckedItems &&
                                onCheckItemValue(item, e.target.checked);
                            }}
                          />
                        </Td>
                      )}
                      {trIndex === 0 && collapsed && (
                        <Td
                          rowSpan={convertMapArray(schema.columns, item).length}
                        >
                          <CollapseButton
                            collapsed={!collapsed.includes(item.id)}
                            onClick={() => onCollapseItem(item.id)}
                          />
                        </Td>
                      )}
                      {trColumn.map((tdColumn: any, tdIndex: number) => (
                        <Td
                          key={`td-${item.id}-${tdIndex}`}
                          style={tdColumn.style}
                          $grow={tdColumn.grow}
                          rowSpan={
                            tdColumn.rowSpan === "100%"
                              ? convertMapArray(schema.columns, item).length
                              : tdColumn?.rowSpan
                          }
                          className={`${
                            tdColumn.className ? tdColumn.className : ""
                          }`}
                          colSpan={tdColumn?.colSpan}
                          disabled={
                            tdColumn.disabled
                              ? tdColumn.disabled(item, trIndex)
                              : false
                          }
                        >
                          <Box
                            ref={(el: any) => {
                              const resizeObj = columnsRef.find(
                                (f) => f.text === tdColumn.id
                              );
                              if (resizeObj) {
                                resizeObj.ref.current = el;
                              }
                            }}
                          >
                            <DataCell
                              item={item}
                              index={trIndex}
                              column={tdColumn}
                            />
                          </Box>
                          {resizable && (
                            <ResizeButton
                              active={
                                columnsRef?.find((f) => f.text === tdColumn.id)
                                  ?.active
                              }
                            />
                          )}
                        </Td>
                      ))}
                      {HoverButton && trIndex === 0 && (
                        <Box
                          component="td"
                          rowSpan={convertMapArray(schema.columns, item).length}
                          sx={{
                            position: "sticky",
                            zIndex: 1,
                            right: 5,
                            "&&&": {
                              width: 0,
                              minWidth: 0,
                              padding: 0,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              right: 0,
                              top: 4,
                              "&&&": {
                                border: 0,
                              },
                            }}
                          >
                            <HoverButton show={i === hoverIndex} item={item} />
                          </Box>
                        </Box>
                      )}
                    </Tr>
                  )
                )}
                {schema.collapseColumns && (
                  <CollapseTr
                    item={item}
                    schema={schema.collapseColumns}
                    collapsed={
                      collapsed !== undefined && !collapsed.includes(item.id)
                    }
                    onMouseOver={() => setHoverIndex(null)}
                  />
                )}
              </Fragment>
            ))
          ))}
      </Tbody>
    </Table>
  );
};
