import { ApiClient } from "core/apiClient";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { generateCookieName } from "utils/cookieUtil";
import { useApi } from "./api";

const AuthContext = createContext<any>(null);
const ACCESS_TOKEN_COOKIE_NAME = generateCookieName("access-token");
const REFRESH_TOKEN_COOKIE_NAME = generateCookieName("refresh-token");
const AUTO_SIGNIN_COOKIE_NAME = generateCookieName("auto-signin");
const SECURE = process.env.REACT_APP_ENV !== "local";

export const AuthError = {
  ACCESS_DENIED: "ACCESS_DENIED",
  OWNER_ACCESS_DENIED: "OWNER_ACCESS_DENIED",
  ACCESS_RESTRICTED: "ACCESS_RESTRICTED",
  UNAUTHORIZED: "UNAUTHORIZED",
};

export function AuthProvider({ children }: { children?: any }) {
  const api = useApi();
  const [cookies, setCookie, removeCookie] = useCookies([
    ACCESS_TOKEN_COOKIE_NAME,
    REFRESH_TOKEN_COOKIE_NAME,
    AUTO_SIGNIN_COOKIE_NAME,
  ]);

  const [error, setError] = useState<any>(null);
  const [intialized, setInitialized] = useState(false);

  const clear = useCallback(() => {
    removeCookie(ACCESS_TOKEN_COOKIE_NAME, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    removeCookie(REFRESH_TOKEN_COOKIE_NAME, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    removeCookie(AUTO_SIGNIN_COOKIE_NAME, {
      path: "/",
    });
  }, [removeCookie]);

  const setTokens: any = useCallback(
    (accessToken: any, refreshToken: any, sessionOnly: any) => {
      api.setTokens(accessToken, refreshToken);

      setCookie(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
        secure: SECURE,
        maxAge: sessionOnly ? undefined : 60 * 60 * 24 * 7,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: "/",
      });

      setCookie(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
        secure: SECURE,
        maxAge: sessionOnly ? undefined : 60 * 60 * 24 * 30,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: "/",
      });
    },
    [api, setCookie]
  );

  const setAutoLogin = useCallback(
    (autoLogin: any) => {
      setCookie(AUTO_SIGNIN_COOKIE_NAME, autoLogin.toString(), {
        maxAge: 60 * 60 * 24 * 7,
        path: "/",
      });
    },
    [setCookie]
  );

  const signin = useCallback(
    async (email: any, password: any, autoLogin: any) => {
      const res = await api.signin(email, password);
      const { accessToken, refreshToken } = await res.json();
      if (accessToken === null || refreshToken === null) {
        return "certUser";
      }
      setTokens(accessToken, refreshToken, !autoLogin);
      setAutoLogin(autoLogin);
      setError(null);
      return "manualUser";
    },
    [api, setAutoLogin, setTokens]
  );

  const confirmCertification = useCallback(
    async (email: any, confirmNumber: any, autoLogin: any) => {
      const response = await api.confirmCertification(email, confirmNumber);
      const { accessToken, refreshToken } = await response.json();
      setTokens(accessToken, refreshToken, !autoLogin);
      setAutoLogin(autoLogin);
      setError(null);
    },
    [api, setAutoLogin, setTokens]
  );

  const signout = useCallback(() => {
    clear();
  }, [clear]);

  const accessToken = useMemo(
    () => cookies[ACCESS_TOKEN_COOKIE_NAME],
    [cookies]
  );

  const refreshToken = useMemo(
    () => cookies[REFRESH_TOKEN_COOKIE_NAME],
    [cookies]
  );

  useEffect(() => {
    setInitialized(true);
    api.setTokens(accessToken, refreshToken);

    return api.on(
      ApiClient.Event.REFRESH_TOKEN,
      ({
        accessToken,
        refreshToken,
      }: {
        accessToken?: any;
        refreshToken?: any;
      }) => {
        setTokens(accessToken, refreshToken);
      }
    );
  }, [accessToken, api, refreshToken, setTokens]);

  useEffect(() => {
    return api.on(ApiClient.Event.ACCESS_DENIED, () => {
      setError(AuthError.ACCESS_DENIED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    return api.on(ApiClient.Event.ACCESS_RESTRICTED, () => {
      setError(AuthError.ACCESS_RESTRICTED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    return api.on(ApiClient.Event.OWNER_ACCESS_DENIED, () => {
      setError(AuthError.OWNER_ACCESS_DENIED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    return api.on(ApiClient.Event.UNAUTHORIZED, () => {
      setError(AuthError.UNAUTHORIZED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    if (
      error === AuthError.UNAUTHORIZED ||
      error === AuthError.ACCESS_DENIED ||
      error === AuthError.OWNER_ACCESS_DENIED ||
      error === AuthError.ACCESS_RESTRICTED
    ) {
      location.href = "/login";
    }
  }, [error]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        authed: Boolean(refreshToken),
        setTokens,
        clear,
        signin,
        signout,
        error,
        setError,
        confirmCertification,
      }}
    >
      {intialized && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
