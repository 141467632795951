import { useContext, useMemo, useState } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { useApi } from "providers/api";
import { useSnackbar } from "SnackbarProvider";
import { AlertModal } from "components/Modal/AlertModal";
import Icon from "components/Icon";
import { SimpleRegistrationsCompleteModal } from "modals/SimpleRegistrationsCompleteModal";
import { SimpleRegistrationsCustomerListModal } from "modals/SimpleRegistrationsCustomerListModal";
import { DialButton } from "components/SimpleRegistrations/DialButton";
import { Box } from "./BottomSection.styled";

const AlertType = {
  CUSTOMER_LIST_OVER: (
    <>
      추가 정보 확인이 필요합니다.
      <br />
      데스크에 문의 부탁드립니다.
    </>
  ),
  EXIST_REGISTRATION: (
    <>
      이미 접수된 고객입니다.
      <br />
      데스크에 문의 부탁드립니다.
    </>
  ),
};

export const BottomSection = ({
  setOpenCreateNewRegistrationModal,
  openCompleteModal,
  setOpenCompleteModal,
}) => {
  const {
    phoneNumber,
    setPhoneNumber,
    setLoading,
    setShowNewCustomerText,
    setCustomers,
    setName,
    reset,
    currentIdx,
    setCurrentIdx,
  } = useContext(SimpleRegistrationsContext);
  const api = useApi();
  const sb = useSnackbar();
  const [openAlert, setOpenAlert] = useState({ open: false, type: null });
  const [openCustomerListModal, setOpenCustomerListModal] = useState(false);

  const displayPhoneNumber = useMemo(() => {
    let value = phoneNumber.substring(0, 11).padEnd(11, "0");
    if (phoneNumber.length < 2) {
      return value.substring(0, 1) + "1" + value.substring(2);
    }
    return value;
  }, [phoneNumber]);

  const onChangePhoneNumber = (value) => {
    if (phoneNumber.length < 11) {
      setPhoneNumber((number) => number + value);
      setCurrentIdx(currentIdx + 1);
    }
  };

  const newCustomerFlow = () => {
    // 신환
    setShowNewCustomerText(true);
    setTimeout(function () {
      setLoading(false);
      setOpenCreateNewRegistrationModal(true);
      setCurrentIdx(3);
    }, 2000);
  };

  const establishedCustomerFlow = (checkSession, customerList) => {
    setCustomers(customerList);
    setOpenCustomerListModal(true);
    setCurrentIdx(3);
  };

  const onClickNext = async () => {
    setLoading(true);
    const res = await api.getRegistrationCheck(phoneNumber);
    const payload = await res.json();
    const customerList = payload.data;

    if (customerList.length > 0) {
      // 예약/접수이력, 신/구환 확인
      if (customerList.length > 3) {
        setOpenAlert({ open: true, type: "CUSTOMER_LIST_OVER" });
        setCurrentIdx(3);
        return;
      }
      const checkSession = payload.data.filter(
        (f) => f.sessionCount > 0 || f.visitType !== "NEW"
      );
      if (checkSession.length > 0) {
        // 구환
        establishedCustomerFlow(checkSession, customerList);
      } else {
        newCustomerFlow();
      }
    } else {
      newCustomerFlow();
    }
  };

  const onRegistrationRequest = async (customer) => {
    try {
      await api.createRegistrationRequests({ customerId: customer.customerId });
      setName(customer.name);
      setOpenCompleteModal(true);
    } catch (e) {
      reset();
      if (e.code === 400 && e.message === "Duplicated Registration Request") {
        setOpenAlert({ open: true, type: "EXIST_REGISTRATION" });
        return;
      }
      sb.open("실패했습니다.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {openAlert.open && (
        <AlertModal
          body={
            <div style={{ fontWeight: "bold" }}>
              <div style={{ fontSize: "2vw", marginBottom: "5%" }}>안내</div>
              <div>{AlertType[openAlert.type]}</div>
            </div>
          }
          onClose={() => {
            setOpenAlert({ open: false, type: null });
            setLoading(false);
            reset();
          }}
          open={openAlert.open}
        />
      )}
      {openCompleteModal && (
        <SimpleRegistrationsCompleteModal
          open={openCompleteModal}
          onClose={() => {
            reset();
            setOpenCompleteModal(false);
          }}
        />
      )}
      {openCustomerListModal && (
        <SimpleRegistrationsCustomerListModal
          open={openCustomerListModal}
          onClose={(value, customer) => {
            setOpenCustomerListModal(false);
            setLoading(true);
            if (customer?.registrationToday === true) {
              setLoading(false);
              setOpenAlert({
                open: true,
                type: "EXIST_REGISTRATION",
              });
              return;
            }
            if (value === "registration") {
              onRegistrationRequest(customer);
            } else if (value === "registration_new") {
              newCustomerFlow();
            } else {
              reset();
            }
          }}
        />
      )}
      <Box className="bottom-wrapper">
        <Box className="display-wrapper">
          <Box className="display-phone-number">
            {displayPhoneNumber.split("").map((char, index) => (
              <span
                key={index}
                className={
                  index >= currentIdx ? "place-holder" : "display-text"
                }
              >
                {char}
                {(index === 2 || index === 6) && "-"}
              </span>
            ))}
          </Box>
        </Box>
        <Box className="dial-container">
          <Box className="dial-column">
            <DialButton
              className="dial-button"
              text={1}
              onClick={() => onChangePhoneNumber(1)}
            />
            <DialButton
              className="dial-button"
              text={2}
              onClick={() => onChangePhoneNumber(2)}
            />
            <DialButton
              className="dial-button"
              text={3}
              onClick={() => onChangePhoneNumber(3)}
            />
          </Box>
          <Box className="dial-column">
            <DialButton
              className="dial-button"
              text={4}
              onClick={() => onChangePhoneNumber(4)}
            />
            <DialButton
              className="dial-button"
              text={5}
              onClick={() => onChangePhoneNumber(5)}
            />
            <DialButton
              className="dial-button"
              text={6}
              onClick={() => onChangePhoneNumber(6)}
            />
          </Box>
          <Box className="dial-column">
            <DialButton
              className="dial-button"
              text={7}
              onClick={() => onChangePhoneNumber(7)}
            />
            <DialButton
              className="dial-button"
              text={8}
              onClick={() => onChangePhoneNumber(8)}
            />
            <DialButton
              className="dial-button"
              text={9}
              onClick={() => onChangePhoneNumber(9)}
            />
          </Box>
          <Box className="dial-column">
            <DialButton
              text={
                <Box className="icon-wrapper">
                  <Icon variant="backspace" />
                </Box>
              }
              onClick={() => {
                if (phoneNumber.length !== 0) {
                  setPhoneNumber((number) =>
                    number.slice(0, number.length - 1)
                  );
                  setCurrentIdx(currentIdx - 1);
                }
              }}
            />
            <DialButton
              className="dial-button"
              text={0}
              onClick={() => onChangePhoneNumber(0)}
            />
            <DialButton
              text="다음"
              className={`next-button ${
                phoneNumber.length > 10 ? "able-button" : "disable-button"
              }`}
              disabled={phoneNumber.length < 11}
              onClick={() => onClickNext()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
