import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  width: 100%;
`;

const StyledInput = styled(TextField)`
  border: 0;
  width: 100%;
  height: 100%;
  input {
    height: 34px;
    font-size: 14px;
    padding: 0 20px;
  }
`;

type TextInputProps = {
  value?: any;
  onChange?: (value: any) => void;
  placeholder?: string;
  disabled?: boolean;
  type?: "text" | "password" | "number";
};

export const TextInput = ({
  value,
  onChange,
  placeholder,
  disabled = false,
  type = "text",
  ...props
}: TextInputProps) => {
  const handleChange = (e: any) => {
    onChange && onChange(e.target.value);
  };

  return (
    <Wrapper {...props}>
      <StyledInput
        type={type}
        variant="outlined"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Wrapper>
  );
};
