import { useMemo, useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material";
import { useApi } from "providers/api";
import { useUser } from "providers/user";
import { DropdownList as _DropdownList } from "components/DropdownList";

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

// 예약차트 > 방문시간
const startHourList = (clinic: any) => {
  const { appointmentTimeUnit, workStart, workEnd } = clinic;

  // 10:30 -> 600
  const convertH2M = (timeInHour: string) => {
    const timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  };

  const getTimesArray = (start: string, end: string, length: number) => {
    let startMin = convertH2M(start);
    const endMin = convertH2M(end);
    const times = [];

    while (startMin < endMin) {
      const mins = startMin % 60;
      const hours = Math.floor(startMin / 60);
      const label = hours.toString() + ":" + mins.toString().padStart(2, "0");
      const value =
        hours.toString().padStart(2, "0") +
        ":" +
        mins.toString().padStart(2, "0");

      times.push({ label, value });
      startMin += length;
    }
    return times;
  };

  return getTimesArray(workStart, workEnd, appointmentTimeUnit);
};

type StartHourSelectProps = {
  value: any;
  onChange: (value: any) => void;
  department: any;
  date: string;
};

export function StartHourSelect({
  value,
  onChange,
  department,
  date,
}: StartHourSelectProps) {
  const api = useApi();
  const { user } = useUser();
  const [timeResp, setTimeResp]: any = useState(null);
  const visitTimes = useMemo(() => {
    const timeOptions = startHourList(user.clinic).map((o) => {
      if (!timeResp || !timeResp?.items) return o;
      const item = timeResp.items.find((v: any) => v.time === o.value);
      if (!item || item.count === 0) return o;
      return {
        label: `${o.label} (${item.count})`,
        value: o.value,
      };
    });

    if (value !== "" && !timeOptions.find((f) => f.value === value)) {
      const count = timeResp?.items.find((f: any) => f.time === value)?.count;
      timeOptions.unshift({
        label: `${value} ${count > 0 ? `(${count})` : ""}`,
        value: value,
      });
    }
    return timeOptions;
  }, [timeResp, user.clinic, value]);

  const loadTimeCount = useCallback(async () => {
    if (!department || !date) return;
    const res = await api.getAppointmentsTime(date as any, department?.id);
    const payload = await res.json();
    setTimeResp(payload.data);
  }, [api, date, department]);

  useEffect(() => {
    loadTimeCount();
  }, [date, department, loadTimeCount]);

  return (
    <>
      <DropdownList
        options={visitTimes}
        value={visitTimes.find((f: any) => f.value === value)}
        onChange={(v) => {
          onChange(v ?? null);
        }}
        placeholder="방문시간을 선택하세요."
      />
    </>
  );
}
