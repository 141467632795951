import { LoadingIcon } from "~/components/DataTable/DataTableUtils.styled";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled("div")(
  () => `
    width: calc(100% + 1px);
    
    .infinite-scroll-component {
      padding-right: 1px;
      overflow-x: hidden !important;
    }
`
);

type InfinityScrollWrapperProps = {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  children: ReactNode;
  targetId: string;
};

export const InfinityScrollWrapper = ({
  dataLength,
  next,
  hasMore,
  children,
  targetId,
}: InfinityScrollWrapperProps) => {
  return (
    <Wrapper>
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        hasMore={hasMore}
        scrollableTarget={targetId}
        loader={
          <div
            style={{
              width: "100%",
              height: "30px",
              padding: "10px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIcon size={20} />
          </div>
        }
      >
        {children}
      </InfiniteScroll>
    </Wrapper>
  );
};
