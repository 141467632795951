import { styled } from "@mui/material";

export const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 14px;
  height: 50px;
  font-size: 13px;
  background-color: #f9fbff;
  color: #282828;
  padding: 0 20px;
  flex: 0 0 auto;
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  margin-left: auto;
  column-gap: 10px;
  button {
    height: 32px;
  }
`;

export const Bold = styled("span")`
  font-weight: bold;
`;
