import { styled } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

const Button = styled("button")(
  () => `
  background: #ffffff;
  display: flex;
  align-items: center;
  column-gap: 2px;
  font-weight: 500;
  font-size: 11px;
  color: #a1b1ca;
  border: 1px solid #dee2ec;
  border-radius: 2px;
  height: 100%;
  padding: 0 4px 0 2px;
  cursor: pointer;
  svg {
    font-size: 16px;
  }
`
);

type SMSPreviewButtonProps = {
  onClick: (value: any) => void;
};

const SMSPreviewButton = ({ onClick }: SMSPreviewButtonProps) => {
  return (
    <Button onClick={onClick}>
      <EmailIcon />
      미리보기
    </Button>
  );
};

export default SMSPreviewButton;
