import update from "immutability-helper";
import { format } from "date-fns";
import { Button } from "@mui/material";
import { TemplateContentType } from "type/survey";
import Radio from "~/components/Radio";
import { Checkbox } from "~/components/Checkbox";
import DateInput from "~/components/DateInput";
import { Stack, Box, Textarea, Typography } from "./SurveyContent.styled";
import { DropdownList } from "~/components/DropdownList";
import { hoursOptions, minutesOptions } from "utils/surveyHelper";
import { v4 as uuidv4 } from "uuid";

type ContentType = {
  data: TemplateContentType;
  onChange: (
    template: TemplateContentType,
    key: "values" | "text",
    value: any
  ) => void;
  disabled?: boolean;
  mode?: "write" | "edit" | "read";
};

const Content = (props: ContentType) => {
  const { data, onChange, disabled, mode = "write" } = props;
  const { type, result, content } = data;

  const handleChangeRadio = (
    template: TemplateContentType,
    value: string | undefined
  ) => {
    onChange(template, "values", value ? [value] : []);
  };

  const handleChangeCheckbox = (
    template: TemplateContentType,
    value: string,
    index: number
  ) => {
    const find = template.result.values[index];
    if (find) {
      const newValues = update(template.result.values, {
        [index]: { $set: "" },
      });
      onChange(template, "values", newValues);
    } else {
      const newValues = update(template.result.values, {
        [index]: { $set: value },
      });
      onChange(template, "values", newValues);
    }
  };

  const handleChangeDate = (
    template: TemplateContentType,
    value: string,
    index: number
  ) => {
    const newValues = update(template.result.values, {
      [index]: { $set: value },
    });
    onChange(template, "values", newValues);
  };

  const handleChangeText = (template: TemplateContentType, value: string) => {
    onChange(template, "text", value);
  };

  return (
    <>
      {type === "RADIO-1" && (
        <Box className="content-radio">
          <Radio
            className="radio"
            value={result.values[0] !== undefined && result.values[0]}
            options={content.values
              .filter((item) => Boolean(item))
              .map((item) => {
                return { label: item, value: item };
              })}
            onChange={(value) => {
              handleChangeRadio(data, value);
            }}
            disabled={mode === "read" || disabled}
          />
          {result.values[0] && !(mode === "read" || disabled) && (
            <Button
              variant="text"
              className="reset-radio-btn"
              onClick={() => {
                handleChangeRadio(data, undefined);
              }}
            >
              선택해제
            </Button>
          )}
        </Box>
      )}
      {type === "CHECKBOX-1" && (
        <Stack
          flexDirection={"column"}
          gap={"10px"}
          className="content-checkbox"
        >
          {content.values
            .filter((item) => Boolean(item))
            .map((valueItem, index) => {
              return (
                <Checkbox
                  key={`checkbox-${index}`}
                  checked={result.values[index] === valueItem}
                  label={valueItem}
                  onChange={() => {
                    handleChangeCheckbox(data, valueItem, index);
                  }}
                  disabled={mode === "read" || disabled}
                ></Checkbox>
              );
            })}
        </Stack>
      )}
      {(type === "DATE-1" || type === "DATE-2") && (
        <Stack className={`mt-10 `} flexDirection={"row"} gap={"10px"}>
          <Box
            className={`date-selector-box ${
              type === "DATE-1" ? "full-width" : ""
            }`}
          >
            {mode === "read" ? (
              <Typography variant="body1">{result.values[0]}</Typography>
            ) : (
              <DateInput
                wrapperClassName="date-selector"
                disablePortal={false}
                id={uuidv4()}
                value={result.values[0] ? result.values[0] : undefined}
                disabled={disabled}
                dateFormat={"yyyy-MM-dd"}
                placeholder="YYYY-MM-DD"
                onChange={(date: Date) => {
                  handleChangeDate(data, format(date, "yyyy-MM-dd"), 0);
                }}
                onSelect={() => {
                  return;
                }}
              />
            )}
          </Box>
          {type === "DATE-2" && (
            <>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                className="hours-selector"
              >
                {mode === "read" ? (
                  <Typography variant="body1">{result.values[1]}</Typography>
                ) : (
                  <DropdownList
                    className="select-box"
                    options={hoursOptions}
                    value={result.values[1]}
                    disableClearable={true}
                    placeholder={"HH"}
                    disabled={disabled}
                    onChange={(option) => {
                      handleChangeDate(data, option.value, 1);
                    }}
                  />
                )}
                시
              </Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                className="minutes-selector"
              >
                {mode === "read" ? (
                  <Typography variant="body1">{result.values[2]}</Typography>
                ) : (
                  <DropdownList
                    className="select-box"
                    options={minutesOptions}
                    disabled={disabled}
                    value={result.values[2]}
                    disableClearable={true}
                    onChange={(option) => {
                      handleChangeDate(data, option.value, 2);
                    }}
                    placeholder={"MM"}
                  />
                )}
                분
              </Stack>
            </>
          )}
        </Stack>
      )}
      {(type === "TEXT-1" || type === "AGREEMENT-1") && (
        <>
          <Box className={`textarea-box`}>
            {type === "AGREEMENT-1" ? (
              <Typography variant="body1">{content.text}</Typography>
            ) : type === "TEXT-1" && mode === "read" ? (
              <Typography variant="body1">{result.text}</Typography>
            ) : (
              <Textarea
                minRows={3}
                disabled={disabled}
                placeholder="답변 입력란"
                value={result.text}
                onChange={(event) => {
                  handleChangeText(data, event.target.value);
                }}
              />
            )}
          </Box>
          {type === "AGREEMENT-1" && (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              className="mt-10 agreement checkbox-wrapper"
            >
              <Checkbox
                checked={
                  result.values[0] !== undefined &&
                  content.values[0] === result.values[0]
                }
                label={content.values[0]}
                onChange={() => {
                  handleChangeCheckbox(data, content.values[0], 0);
                }}
                disabled={mode === "read" || disabled}
              ></Checkbox>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default Content;
