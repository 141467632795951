import { Wrapper, StyledCheckbox } from "./Checkbox.styled";
import Icon from "./Icon";
import { ReactNode } from "react";

type CheckboxProps = {
  checked: boolean;
  onChange?: (event?: any) => void;
  label?: string | ReactNode;
  disabled?: boolean;
  id?: string;
  color?: string;
};

export const Checkbox = ({
  label = "",
  checked,
  onChange,
  disabled = false,
  id,
  ...props
}: CheckboxProps) => {
  return (
    <Wrapper
      {...props}
      control={
        <StyledCheckbox
          id={id}
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          icon={<Icon variant="unchecked" />}
          checkedIcon={
            <Icon variant="checked" color={disabled ? "#BBBBBB" : "#2C62F6"} />
          }
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};
