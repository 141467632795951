import {
  Stack as MuiStack,
  Dialog as MuiDialog,
  styled,
  dialogContentClasses,
  dialogTitleClasses,
  buttonBaseClasses,
  paperClasses,
  IconButton as MuiIconButton,
} from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  () => `
  

  &.close-btn {
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
  }

  &.m-l {
    margin-left: auto;
  }
`
);

export const Stack = styled(MuiStack)(
  () => `
  &.template-list {
    height: 340px;
    overflow-y: auto;
  }
`
);

export const Dialog = styled(MuiDialog)(
  ({ theme }) => `
  .${dialogTitleClasses.root}{
    font-size: 15px;
    font-weight: 700;
    line-height: 21.72px;
    display: flex;
  }

  .${paperClasses.root}{
    min-width: 340px;
  }

  .${dialogContentClasses.root}{
    padding: 0;
  }

  &&& .${buttonBaseClasses.root}{
    width: 100%;
    padding: 18px 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21.72px;
    text-decoration: none;
    color: ${theme.palette.grey["700"]};
    justify-content: flex-start;
    &:hover{
      text-decoration: none;
    }
  }
`
);
