import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ReactNode } from "react";
import Icon from "components/Icon";

type NormalModalProps = {
  title: any;
  children: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
  open: boolean;
  paperSx?: any;
  dialogSx?: any;
};

export function NormalModal({
  title,
  children,
  footer,
  onClose,
  open,
  paperSx,
}: NormalModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        sx: { minWidth: "350px", maxWidth: "initial", ...paperSx },
      }}
      disableEnforceFocus
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: "bold",
          borderBottom: "1px solid #DEE2EC",
          padding: "16px",
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ marginLeft: "auto", svg: { width: "10px", height: "10px" } }}
          onClick={onClose}
        >
          <Icon variant="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: "14px",
          padding: "16px !important",
        }}
      >
        {open && children}
      </DialogContent>
      {footer && (
        <DialogActions sx={{ padding: "16px" }}>{footer}</DialogActions>
      )}
    </Dialog>
  );
}
