import {
  LinkedChannelType,
  LinkedChannelStatusType,
} from "~/type/linkedChannelType";

export const getLinkedChannelIconVariant = (type: LinkedChannelType) => {
  switch (type) {
    case "NAVER":
    default:
      return "naver";
  }
};

export const externalUpdateConflictErrorMessage =
  "고객이 연동된 채널에서 예약 변경 신청하여 취소된 예약입니다. 먼저 PC화면 상단의 연동채널에서 신청된 예약의 처리를 해주세요.";

export const validateCanceledNaverAppointment = (
  appointmentStatus: string,
  externalRequestStatus: LinkedChannelStatusType,
  externalLinkedChannel: LinkedChannelType
) => {
  if (appointmentStatus === "CANCELED" && externalLinkedChannel === "NAVER") {
    if (externalRequestStatus === "REQUESTED") {
      throw new Error(externalUpdateConflictErrorMessage);
    }
  }
};
