import { ButtonGroup } from "../../../components/Form/ButtonGroup";

const typeButtonData = [
  {
    name: "상담예약",
    value: "CONSULTING",
  },
  {
    name: "진료예약",
    value: "TREATMENT",
  },
  {
    name: "시/수술예약",
    value: "SURGERY",
  },
];

type AppointmentTypeButtonProps = {
  value: any;
  onChange: (value: any) => void;
};

export const AppointmentTypeButton = ({
  value,
  onChange,
}: AppointmentTypeButtonProps) => {
  return (
    <ButtonGroup
      value={value}
      isToggle={false}
      onChange={onChange}
      buttonData={typeButtonData}
    />
  );
};
