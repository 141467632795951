import { useState } from "react";
import styled from "@emotion/styled";
import { IconButton, Box } from "@mui/material";
import Icon from "components/Icon";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import { PageDotIndicator } from "components/SimpleRegistrations/PageDotIndicator";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  font-weight: 500;
`;

const iconButtonStyle = { width: "70px", height: "100%" };

export const Header = ({ onOpen, onClose, usePageIndicator, page, total }) => {
  const [openAlert, setOpenAlert] = useState(false);

  const onCancelRegistrations = () => {
    onClose();
  };

  return (
    <>
      {openAlert && (
        <ConfirmModal
          body={
            <div style={{ fontWeight: "bold" }}>
              <div style={{ fontSize: "2vw", marginBottom: "5%" }}>안내</div>
              <div>
                진행하던 접수를 취소할까요?
                <br />
                입력중인 정보는 저장되지 않습니다.
              </div>
            </div>
          }
          cancelText="아니오"
          confirmText="예"
          onClose={() => setOpenAlert(false)}
          onConfirm={() => onCancelRegistrations()}
          open={openAlert}
        />
      )}
      <Wrapper>
        <IconButton sx={iconButtonStyle} onClick={() => onOpen(true)}>
          <Icon variant="back_arrow" />
        </IconButton>
        <Box>
          {usePageIndicator && <PageDotIndicator page={page} total={total} />}
        </Box>
        <IconButton
          sx={{
            ...iconButtonStyle,
            color: "#ADAEBC",
          }}
          onClick={() => setOpenAlert(true)}
        >
          <Icon variant="close" />
        </IconButton>
      </Wrapper>
    </>
  );
};
