import { useContext, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { Box, Button } from "@mui/material";

const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 5vw;
  font-weight: bold;
  border: none;

  &::placeholder {
    color: #f1f1f1;
  }
`;

const Span = styled.span`
  font-weight: 800;
`;

export const LandscapeNameInput = ({ onClickNext }) => {
  const ref = useRef();
  const { name, setName } = useContext(SimpleRegistrationsContext);

  useEffect(() => {
    ref.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          fontSize: "2.5vw",
          fontWeight: "bold",
        }}
      >
        <Span>이름</Span>을 입력하세요.
      </div>
      <div style={{ display: "flex", gap: "5vw" }}>
        <Box
          sx={{
            width: "75vw",
          }}
        >
          <Input
            ref={ref}
            placeholder="홍길동"
            value={name}
            onChange={(e) => {
              const input = e.target.value;
              let maxChars;

              if (/^[a-zA-Z]*$/.test(input)) {
                // 입력이 영문일 경우
                maxChars = 32;
              } else {
                // 입력이 한글일 경우
                maxChars = 16;
              }

              if (input.length > maxChars) {
                e.target.value = input.slice(0, maxChars);
              }
              setName(e.target.value);
            }}
          />
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "25vw",
            height: "10vh",
            fontSize: "40px",
          }}
          disabled={name.length < 2}
          onClick={() => onClickNext()}
        >
          다음
        </Button>
      </div>
    </Box>
  );
};
