import { IconButton as MuiIconButton, styled } from "@mui/material";
import { Body as BodyWrapper } from "components/Modal/SlideModal";

export const Body = styled(BodyWrapper)(
  ({ theme }) => `
  padding: 0;
  background: ${theme.palette.bluegrey["100"]};
  overflow: auto;
`
);

export const SearchInput = styled("input")<{ ref?: HTMLInputElement }>(
  ({ theme }) => `
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border: 0 none;
  text-align: center;

  &::placeholder {
    color: ${theme.palette.bluegrey["600"]};
  }
`
);

export const IconButton = styled(MuiIconButton)(
  () => `
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 5px 10px;

`
);
