import {
  DefaultStatItem,
  StatItemWrapper,
  StatLabel,
  StatCount,
  LayoutStatCount,
  Wrapper,
  StatDivision,
  StatGroup,
} from "./StatItems.styled";

type StatItemProps = {
  label: any;
  count?: number;
  color?: string;
};

export const StatItem = ({
  label,
  count = 0,
  color = "blue",
}: StatItemProps) => {
  return (
    <StatItemWrapper>
      <StatLabel color={color}>{label}</StatLabel>
      <StatCount color={color}>{count}</StatCount>
    </StatItemWrapper>
  );
};

type StatItemsProps = {
  data: any;
};

export const StatItems = ({ data }: StatItemsProps) => {
  return (
    <Wrapper className="stat-items">
      <DefaultStatItem>
        <StatLabel bold={true}>전체</StatLabel>
        <LayoutStatCount>{data?.total ?? 0}</LayoutStatCount>
      </DefaultStatItem>
      <StatGroup>
        <StatItem label="신환" count={data.new} />
        <StatDivision>|</StatDivision>
        <StatItem label="구환" count={data.established} />
        <StatDivision>|</StatDivision>
        <StatItem label="취소" count={data.canceled} color="red" />
      </StatGroup>
      <StatGroup>
        <StatItem label="당일접수" count={data.today} />
        <StatDivision>|</StatDivision>
        <StatItem label="예약접수" count={data.appointment} />
      </StatGroup>
    </Wrapper>
  );
};
