import { makeAutoObservable, action } from "mobx";

class SimpleRegistration {
  private activeLogout = false;

  constructor() {
    makeAutoObservable(this);
    this.setHiddenCondition = this.setHiddenCondition.bind(this);
  }

  @action private setActiveLogout(flag: boolean) {
    this.activeLogout = flag;
  }

  public setHiddenCondition(flag: boolean) {
    this.setActiveLogout(flag);
  }

  get getHiddenCondition() {
    return this.activeLogout;
  }
}

export default new SimpleRegistration();
