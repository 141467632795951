import { FormControl, Select } from "@mui/material";
import Icon from "components/Icon";

export const SortType = {
  CreatedAtDesc: "createdAt desc",
  CreatedAtAsc: "createdAt asc",
  NameDesc: "name desc",
  NameAsc: "name asc",
};

const options = [
  {
    value: SortType.CreatedAtDesc,
    label: "등록일 최신순",
  },
  {
    value: SortType.CreatedAtAsc,
    label: "등록일 오래된순",
  },
  {
    value: SortType.NameAsc,
    label: "이름 오름차순",
  },
  {
    value: SortType.NameDesc,
    label: "이름 내림차순",
  },
];

export function SortField({ value, onChange }: { value: any; onChange: any }) {
  return (
    <>
      <FormControl variant="outlined">
        <Select
          native
          id="orderby-drive-input"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            height: "29px",
            backgroundColor: "white",
            fontSize: "14px",
            borderRadius: 2,
            pt: 0,
            pb: 0,
            "& .MuiNativeSelect-outlined": {
              pl: 4,
              pr: "10px !important",
            },
            ".MuiNativeSelect-icon": {
              left: 10,
              right: "auto",
            },
            fieldset: {
              borderColor: "#efeff4 !important",
            },
          }}
          IconComponent={() => <Icon variant="penchart_sort" />}
        >
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
