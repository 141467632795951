import { ReactNode } from "react";
import { CenterAlignedTAB } from "components/CenterAlignedTAB/CenterAlignedTAB";
import { Box, Stack, Button } from "./ActionPage.styled";
import { useImperativeModal } from "ImperativeModalProvider";
import { CustomerInfoModal } from "modals/CustomerInfoModal";

type ActionPageProps = {
  children: ReactNode;
  onCancel: () => void;
  trailingButton: null | ReactNode;
  title: ReactNode | null;
  customer: any;
};

export function ActionPage({
  title,
  trailingButton = null,
  children,
  onCancel,
  customer,
}: ActionPageProps) {
  const imperativeModal = useImperativeModal();

  const handleOpenCustomerPopup = async () => {
    await imperativeModal.open((close) => (
      <CustomerInfoModal open={true} customerData={customer} onClose={close} />
    ));
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "grid",
        gridTemplateRows: `max-content 1fr`,
      }}
    >
      <CenterAlignedTAB
        leadingButton={
          <Stack flexDirection={"row"} gap={"24px"}>
            <Button
              size="large"
              variant="text"
              onClick={onCancel}
              color="inherit"
            >
              취소
            </Button>
            {customer && (
              <Button
                className="customer-popup-btn"
                size="medium"
                variant="outlined"
                onClick={handleOpenCustomerPopup}
                color="primary"
              >
                고객정보 보기
              </Button>
            )}
          </Stack>
        }
        title={title}
        trailingButton={trailingButton}
      />
      <Box sx={{ overflow: "auto" }}>{children}</Box>
    </Box>
  );
}
