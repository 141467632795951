import { styled } from "@mui/material";

export const ProfileWrapper = styled("div")`
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const Bold = styled("span")`
  font-weight: bold;
`;
