import styled from "styled-components";
import { Dialog } from "@mui/material";
import { ModalWrapper } from "./common/ModalWrapper";
import { ModalHeader } from "./common/ModalHeader";
import { ModalBody } from "./common/ModalBody";
import { ScheduledSmsPreviewTable } from "./ScheduledSmsPreviewTable";

export const TableContent = styled("div")`
  overflow: auto;
  flex-shrink: 0;
`;

const TableWrapper = styled(TableContent)`
  height: 400px;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    box-shadow: none;
    max-width: 1300px;
  }
`;

type ScheduledSmsPreviewModalProps = {
  data: any[];
  onClose: () => void;
};

export const ScheduledSmsPreviewModal = ({
  data,
  onClose,
}: ScheduledSmsPreviewModalProps) => {
  return (
    <StyledDialog open={true} onClose={onClose}>
      <ModalWrapper>
        <ModalHeader title="예약문자 미리보기" onClose={() => onClose()} />
        <ModalBody>
          <TableWrapper>
            <ScheduledSmsPreviewTable data={data} loading={false} />
          </TableWrapper>
        </ModalBody>
      </ModalWrapper>
    </StyledDialog>
  );
};
