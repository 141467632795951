import { useState, Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DataTableHead } from "./DataTableHead";
import { Checkbox } from "../Checkbox/Checkbox";
import { PlaceHolder } from "./PlaceHolder";
import { CollapseTr } from "./CollapseTr";
import { ColumnValue, LoadingIcon } from "./DataTableUtils.styled";
import { CollapseButton } from "./CollapseButton";
import { RowGroup } from "./RowGroup";
import { Table, Tbody, Tr, Td, TdInner, DragButton } from "./DragTable.styled";
import Icon from "components/Icon";

type DataCellProps = {
  item: any;
  column: any;
  index: number;
  draggingTarget?: boolean;
  key?: string;
  hiddenValue?: boolean;
};

export const DataCell = ({
  item,
  column,
  index,
  draggingTarget,
  hiddenValue,
  ...rest
}: DataCellProps) => {
  if (column.value) {
    if (Array.isArray(column.value(item))) {
      return draggingTarget ? (
        <ColumnValue {...rest}>
          <span className="seperate-value">{column.value(item)}</span>
        </ColumnValue>
      ) : (
        <ColumnValue {...rest}>{column.value(item)[index]}</ColumnValue>
      );
    }
    return (
      <ColumnValue {...rest}>
        {hiddenValue ? "" : column.value(item, index)}
      </ColumnValue>
    );
  }

  if (column.component) {
    return <column.component item={item} />;
  }

  throw new Error("invalid column schema");
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type DragTableProps = {
  loading?: boolean;
  placeholder?: string;
  data: any[];
  schema: any;
  checked?: any[];
  collapsed?: any[];
  onChangeCollapsed?: (params?: any) => void;
  onDoubleClickItem?: (value: any) => void;
  dragCallback?: (value: any, fromIndex?: number, toIndex?: number) => void;
  DragIcon?: () => JSX.Element;
  expanded?: any[];
  onChangeChecked?: (params?: any) => void;
  hideHidden?: boolean;
};

export const DragTable = ({
  loading,
  placeholder,
  data = [],
  schema,
  checked,
  collapsed,
  onChangeCollapsed,
  onChangeChecked,
  onDoubleClickItem,
  dragCallback,
  DragIcon,
  expanded = [],
}: DragTableProps) => {
  const [isDragging, setIsDragging] = useState<false | string>(false);
  const [draggingTrHeight, setDraggingTrHeight] = useState<number[]>([]);
  const [trHeight, setTrHeight] = useState<{ id: string; height: number }[]>(
    []
  );
  const [hoverId, setHoverId] = useState<{
    trId: string;
    target: "td" | "tr";
  } | null>(null);
  const onCheckAll = (v: any) => {
    if (v) {
      checked &&
        onChangeChecked &&
        onChangeChecked(data.map((item: any) => item.id));
    } else {
      checked && onChangeChecked && onChangeChecked([]);
    }
  };

  const onCheckItem = (id: any, v: any) => {
    if (v) {
      checked && onChangeChecked && onChangeChecked([id, ...checked]);
    } else {
      checked &&
        onChangeChecked &&
        onChangeChecked(checked.filter((c: any) => c !== id));
    }
  };

  const handleDragStart = ({ draggableId }: { draggableId: string }) => {
    const target = document.getElementsByClassName(
      `draggable-tr-${draggableId}`
    );
    const getHeight = [];
    for (const item of target) {
      getHeight.push(item.scrollHeight - 1);
    }
    setIsDragging(draggableId);
    setDraggingTrHeight(getHeight);
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;
    const updateList = reorder(data, source.index, destination.index);
    dragCallback && dragCallback(updateList, source.index, destination.index);
    setIsDragging(false);
    setDraggingTrHeight([]);
  };

  const onCollapseItem = (id: any) => {
    if (collapsed !== undefined && !collapsed.includes(id)) {
      onChangeCollapsed && onChangeCollapsed([id, ...collapsed]);
    } else {
      collapsed !== undefined &&
        onChangeCollapsed &&
        onChangeCollapsed(collapsed.filter((c) => c !== id));
    }
  };

  // [] -> [[]]
  const convertToRowArray = (arr: any[]) => {
    if (Array.isArray(schema.columns[0])) return arr;
    else return [arr];
  };
  // if map
  const convertMapArray = (arr: any[], item: any) => {
    const convertArr = convertToRowArray(arr);
    const matchSchemaIndex = 0;
    const mapCol = convertArr[matchSchemaIndex].filter(
      (v: any) => v.value && Array.isArray(v.value(item))
    );
    const mapLength = Math.max.apply(
      null,
      mapCol.map((v: any) => v.value(item).length)
    );

    // layout check
    if (
      !mapCol
        .map((v: any) => v.value(item).length)
        .every((val: any, i: number, arr: any[]) => val === arr[0])
    ) {
      throw new Error("The array length of item should be the same.");
    }

    // set rowspan
    convertArr[matchSchemaIndex].map((v: any) => {
      if (
        v.rowSpan !== "100%" &&
        (v.component || !Array.isArray(v.value(item)))
      )
        v.rowSpan = (isFinite(mapLength) && mapLength) || 1;
      return v;
    });

    if (!mapCol.length || !mapLength) return convertArr;
    // add columns
    const updateArr = convertArr
      .slice(matchSchemaIndex, 1)
      .concat(new Array(mapLength - 1).fill(null).map(() => mapCol));

    return convertArr
      .map((v, i) => {
        if (i === matchSchemaIndex) return updateArr;
        return [v];
      })
      .flat();
  };

  const FlatRows = ({ tdColumns, item, trIndex = 0 }: any) => {
    return tdColumns.map((tdColumn: any, tdIndex: number) => (
      <Td
        key={`td-${item.id}-${tdIndex}`}
        style={tdColumn.style}
        $grow={tdColumn.grow}
        rowSpan={
          tdColumn.rowSpan === "100%"
            ? convertMapArray(schema.columns, item).length
            : tdColumn?.rowSpan
        }
        colSpan={tdColumn?.colSpan}
      >
        <div>
          <DataCell item={item} index={trIndex} column={tdColumn} />
        </div>
      </Td>
    ));
  };

  const GroupRows = ({ data, schema }: any) => {
    const rows = data;
    const groupKeys = Array.from(
      new Set(rows.map((row: any) => schema.rowGroup.groupKey(row)))
    );
    const groupData = groupKeys.map((v) => schema.rowGroup.groupData(v));
    return (
      <>
        {groupData.map((v, gi) => {
          const filterRows = rows.filter(
            (row: any) =>
              schema.rowGroup.groupKey(row) === v[schema.rowGroup.groupId] &&
              expanded.indexOf(schema.rowGroup.groupKey(row)) > -1
          );
          return (
            <>
              <Tr
                key={`tr-${v.id}-${gi}`}
                trStyle={schema?.rows?.style(v)}
                firstTr={true}
              >
                <RowGroup item={v} column={schema.rowGroup} />
              </Tr>
              {filterRows.map((row: any, trIndex: number) => {
                return (
                  <Tr
                    key={`tr-${row.id}-${trIndex}`}
                    trStyle={schema?.rows?.style(row)}
                    firstTr={true}
                  >
                    <FlatRows tdColumns={schema.columns} item={row} />
                  </Tr>
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  const handleMouseOverTd = (trId: string, target: "tr" | "td") => {
    if (hoverId?.trId !== trId || hoverId.target !== target) {
      setHoverId({ trId, target });
    }
  };

  const handleMouseOut = () => {
    setHoverId(null);
  };

  useEffect(() => {
    getHeightTr();
  }, [data]);

  const getHeightTr = () => {
    const target = document.querySelectorAll(`tr[data-rbd-draggable-id]`);
    const findIds = new Set();
    for (const item of target) {
      findIds.add(item.getAttribute("data-rbd-draggable-id"));
    }
    const trHeightValues = [];
    for (const item of [...findIds]) {
      const target = document.querySelectorAll(
        `tr[data-rbd-draggable-id="${item}"]`
      );
      let height = 0;
      for (const _item of target) {
        height += _item.clientHeight;
      }
      trHeightValues.push({ id: item as string, height: height - 1 });
    }
    setTrHeight(trHeightValues);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Table $isDragging={isDragging}>
        <DataTableHead
          styleType="chart"
          checked={
            checked
              ? data.every((item: any) => checked.includes(item.id))
              : undefined
          }
          onCheckAll={onCheckAll}
          columns={schema.columns}
          collapsed={
            collapsed
              ? data.every((item) => collapsed.includes(item.id))
              : undefined
          }
        />
        <Droppable droppableId="droppable-1">
          {(provider) => (
            <Tbody
              ref={provider.innerRef}
              {...provider.droppableProps}
              $isDragging={isDragging}
              onMouseLeave={handleMouseOut}
            >
              {loading && (
                <PlaceHolder styleType="chart">
                  <LoadingIcon size={20} />
                </PlaceHolder>
              )}
              {!loading &&
                (!data.length ? (
                  <PlaceHolder styleType="chart">
                    {placeholder ?? "등록된 내용이 없습니다."}
                  </PlaceHolder>
                ) : schema.rowGroup ? (
                  <GroupRows data={data} schema={schema} />
                ) : (
                  data.map((item, i) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={String(item.id)}
                        index={i}
                      >
                        {(provider) => {
                          const mappingArray: any = convertMapArray(
                            schema.columns,
                            item
                          );

                          return (
                            <Fragment key={item.id ?? i}>
                              {mappingArray.map(
                                (trColumn: any, trIndex: number) => {
                                  const draggableProps =
                                    provider.draggableProps;
                                  const dragHandleProps: any =
                                    provider.dragHandleProps;
                                  return (
                                    <Tr
                                      {...draggableProps}
                                      key={`tr-${item.id}-${trIndex}`}
                                      id={`tr-${item.id}-${trIndex}`}
                                      itemsLength={item.items?.length || 0}
                                      className={`${
                                        i % 2 === 0 ? "even" : "odd"
                                      } ${
                                        isDragging ===
                                        dragHandleProps[
                                          "data-rbd-drag-handle-draggable-id"
                                        ]
                                          ? "dragging-tr"
                                          : ""
                                      } ${
                                        hoverId?.trId ===
                                        dragHandleProps[
                                          "data-rbd-drag-handle-draggable-id"
                                        ]
                                          ? `hover-${hoverId?.target}`
                                          : ""
                                      } ${
                                        isDragging !==
                                          dragHandleProps[
                                            "data-rbd-drag-handle-draggable-id"
                                          ] && trIndex === 0
                                          ? "z-index-top"
                                          : ""
                                      }
                                      ${`draggable-tr-${provider.draggableProps["data-rbd-draggable-id"]}`}
                                      `}
                                      ref={provider.innerRef}
                                      onDoubleClick={() =>
                                        onDoubleClickItem &&
                                        onDoubleClickItem(item)
                                      }
                                      firstTr={trIndex === 0}
                                      trStyle={schema?.rows?.style(item)}
                                      draggingTrHeight={draggingTrHeight}
                                      sx={
                                        isDragging ===
                                        dragHandleProps[
                                          "data-rbd-drag-handle-draggable-id"
                                        ]
                                          ? {
                                              height: `${draggingTrHeight.reduce(
                                                (acc: number, cur: number) =>
                                                  acc + cur,
                                                0
                                              )}px !important`,
                                            }
                                          : undefined
                                      }
                                    >
                                      {trIndex === 0 && checked && (
                                        <Td rowSpan={mappingArray.length}>
                                          <Checkbox
                                            checked={checked.includes(item.id)}
                                            onChange={(e) =>
                                              onCheckItem(
                                                item.id,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </Td>
                                      )}
                                      {trIndex === 0 && collapsed && (
                                        <Td rowSpan={mappingArray.length}>
                                          <CollapseButton
                                            collapsed={
                                              !collapsed.includes(item.id)
                                            }
                                            onClick={() =>
                                              onCollapseItem(item.id)
                                            }
                                          />
                                        </Td>
                                      )}
                                      {isDragging === false
                                        ? trColumn.map(
                                            (
                                              tdColumn: any,
                                              tdIndex: number
                                            ) => {
                                              const rowSpan =
                                                tdColumn.rowSpan === "100%"
                                                  ? mappingArray.length
                                                  : tdColumn?.rowSpan;
                                              const disabled =
                                                tdColumn.disable &&
                                                tdColumn.disable(item);

                                              return (
                                                <Td
                                                  onMouseOver={() => {
                                                    handleMouseOverTd(
                                                      dragHandleProps[
                                                        "data-rbd-drag-handle-draggable-id"
                                                      ],
                                                      rowSpan ? "tr" : "td"
                                                    );
                                                  }}
                                                  key={`tr-${tdColumn.id}-${tdIndex}`}
                                                  style={{
                                                    ...tdColumn.style,
                                                    padding: tdColumn.map && 0,
                                                  }}
                                                  $grow={tdColumn.grow}
                                                  disable={disabled}
                                                  rowSpan={rowSpan}
                                                  className={`${
                                                    disabled
                                                      ? rowSpan
                                                        ? "disabled dep1"
                                                        : "disabled dep1-dep2"
                                                      : !rowSpan &&
                                                        tdColumn.disable &&
                                                        tdColumn.disable(
                                                          (item?.items || [])[
                                                            trIndex
                                                          ]
                                                        )
                                                      ? "disabled dep2"
                                                      : ""
                                                  } ${
                                                    tdColumn.drag
                                                      ? "drag-cell"
                                                      : ""
                                                  } ${
                                                    tdColumn.className
                                                      ? tdColumn.className
                                                      : ""
                                                  }`}
                                                >
                                                  <TdInner>
                                                    <DataCell
                                                      item={item}
                                                      column={tdColumn}
                                                      index={trIndex}
                                                    />
                                                    {tdColumn.drag ? (
                                                      <DragButton
                                                        {...dragHandleProps}
                                                        className="drag-btn"
                                                        disabled={disabled}
                                                      >
                                                        {DragIcon ? (
                                                          <DragIcon />
                                                        ) : (
                                                          <Icon variant="drag" />
                                                        )}
                                                      </DragButton>
                                                    ) : (
                                                      <span
                                                        {...dragHandleProps}
                                                        style={{
                                                          display: "none",
                                                        }}
                                                      ></span>
                                                    )}
                                                  </TdInner>
                                                </Td>
                                              );
                                            }
                                          )
                                        : schema.columns.map(
                                            (col: any, i: number) => {
                                              const disabled =
                                                (col.disable &&
                                                  col.disable(item)) ||
                                                (col.disable &&
                                                  item.items &&
                                                  col.disable(
                                                    item.items[trIndex] || {
                                                      visible: true,
                                                    }
                                                  ));

                                              return (
                                                <Td
                                                  key={`tr-${col.id}-${i}`}
                                                  style={{
                                                    ...col.style,
                                                    padding: col.map && 0,
                                                  }}
                                                  $grow={col.grow}
                                                  disable={disabled}
                                                  className={`${
                                                    disabled ? "disabled" : ""
                                                  } ${
                                                    col.className
                                                      ? col.className
                                                      : ""
                                                  }`}
                                                  sx={
                                                    col.textValue &&
                                                    isDragging !==
                                                      dragHandleProps[
                                                        "data-rbd-drag-handle-draggable-id"
                                                      ] &&
                                                    trIndex === 0
                                                      ? {
                                                          "::before": {
                                                            content: '""',
                                                            zIndex: 10,
                                                            padding: "4px 14px",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            width: "100%",
                                                            height:
                                                              trHeight.find(
                                                                (item) =>
                                                                  item.id ===
                                                                  dragHandleProps[
                                                                    "data-rbd-drag-handle-draggable-id"
                                                                  ]
                                                              )?.height
                                                                ? trHeight.find(
                                                                    (item) =>
                                                                      item.id ===
                                                                      dragHandleProps[
                                                                        "data-rbd-drag-handle-draggable-id"
                                                                      ]
                                                                  )?.height +
                                                                  "px"
                                                                : "",
                                                            position:
                                                              "absolute",
                                                            top: 0,
                                                            left: 0,
                                                          },
                                                        }
                                                      : undefined
                                                  }
                                                >
                                                  <TdInner>
                                                    <DataCell
                                                      draggingTarget={
                                                        isDragging ===
                                                        dragHandleProps[
                                                          "data-rbd-drag-handle-draggable-id"
                                                        ]
                                                      }
                                                      hiddenValue={
                                                        trIndex !== 0
                                                      }
                                                      item={item}
                                                      column={col}
                                                      index={
                                                        col.multiValue
                                                          ? trIndex
                                                          : 0
                                                      }
                                                    />
                                                    {col.drag ? (
                                                      <DragButton
                                                        {...dragHandleProps}
                                                        className="drag-btn"
                                                        disabled={disabled}
                                                      >
                                                        {DragIcon ? (
                                                          <DragIcon />
                                                        ) : (
                                                          <Icon variant="drag" />
                                                        )}
                                                      </DragButton>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        {...dragHandleProps}
                                                      ></span>
                                                    )}
                                                  </TdInner>
                                                  {col.textValue &&
                                                    isDragging !==
                                                      dragHandleProps[
                                                        "data-rbd-drag-handle-draggable-id"
                                                      ] &&
                                                    trIndex === 0 && (
                                                      <div
                                                        className="injected-collapse"
                                                        style={{
                                                          height: trHeight.find(
                                                            (item) =>
                                                              item.id ===
                                                              dragHandleProps[
                                                                "data-rbd-drag-handle-draggable-id"
                                                              ]
                                                          )?.height
                                                            ? trHeight.find(
                                                                (item) =>
                                                                  item.id ===
                                                                  dragHandleProps[
                                                                    "data-rbd-drag-handle-draggable-id"
                                                                  ]
                                                              )?.height + "px"
                                                            : "",
                                                        }}
                                                      >
                                                        {col.textValue(item)}
                                                      </div>
                                                    )}
                                                </Td>
                                              );
                                            }
                                          )}
                                    </Tr>
                                  );
                                }
                              )}
                              {schema.collapseColumns && (
                                <CollapseTr
                                  item={item}
                                  schema={schema.collapseColumns}
                                  collapsed={
                                    collapsed !== undefined &&
                                    !collapsed.includes(item.id)
                                  }
                                  onMouseOver={() => setHoverId(null)}
                                />
                              )}
                            </Fragment>
                          );
                        }}
                      </Draggable>
                    );
                  })
                ))}
              {provider.placeholder}
            </Tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};
