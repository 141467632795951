import { useState, useEffect, createContext } from "react";
import { SimpleRegistrations } from "components/SimpleRegistrations/SimpleRegistrations";

export const SimpleRegistrationsContext = createContext<any>(null);

export function SimpleRegistrationsPage() {
  const [isLandscape, setIsLandscape] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNewCustomerText, setShowNewCustomerText] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("010");
  const [currentIdx, setCurrentIdx] = useState(3);
  const [birthdayCurrentIdx, setBirthdayCurrentIdx] = useState(0);
  const [birthday, setBirthday] = useState("");
  const [name, setName] = useState("");
  const [acquisitionChannelsIds, setAcquisitionChannelsIds] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [eventSmsEnabled, setEventSmsEnabled] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    function handleResize() {
      if (
        window.matchMedia("(orientation: landscape)").matches ||
        window.orientation === 90 ||
        window.orientation === -90
      ) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reset = () => {
    setPage(1);
    setBirthdayCurrentIdx(0);
    setCurrentIdx(3);
    setLoading(false);
    setPhoneNumber("010");
    setBirthday("");
    setName("");
    setAcquisitionChannelsIds([]);
    setCustomers([]);
    setSmsEnabled(false);
    setEventSmsEnabled(false);
    setShowNewCustomerText(false);
  };

  return (
    <SimpleRegistrationsContext.Provider
      value={{
        isLandscape,
        loading,
        setLoading,
        showNewCustomerText,
        setShowNewCustomerText,
        phoneNumber,
        setPhoneNumber,
        birthday,
        setBirthday,
        name,
        setName,
        acquisitionChannelsIds,
        setAcquisitionChannelsIds,
        customers,
        setCustomers,
        smsEnabled,
        setSmsEnabled,
        eventSmsEnabled,
        setEventSmsEnabled,
        currentIdx,
        setCurrentIdx,
        birthdayCurrentIdx,
        setBirthdayCurrentIdx,
        page,
        setPage,
        reset,
      }}
    >
      <SimpleRegistrations />
    </SimpleRegistrationsContext.Provider>
  );
}
