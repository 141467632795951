import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { CenterAlignedTAB } from "components/CenterAlignedTAB/CenterAlignedTAB";
import { NavigationDrawer } from "./NavigationDrawer";
import { myTheme } from "styles/theme";
import Icon from "components/Icon";

export function NavPage({ title, children }) {
  const [showNav, setShowNav] = useState(false);

  const openNav = () => {
    setShowNav(true);
  };

  const closeNav = () => {
    setShowNav(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "grid",
        gridTemplateRows: `max-content 1fr`,
      }}
    >
      <CenterAlignedTAB
        leadingButton={
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={openNav}
          >
            <Icon variant="navi" />
          </IconButton>
        }
        title={
          <Typography
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
          >
            {title}
          </Typography>
        }
      />

      <Box
        sx={{
          overflow: "auto",
          backgroundColor: myTheme.palette.bluegrey["100"],
        }}
      >
        {children}
      </Box>
      <NavigationDrawer open={showNav} onClose={closeNav} />
    </Box>
  );
}
