import styled from "@emotion/styled";
import { Body as BodyWrapper } from "components/Modal/SlideModal";

const Wrapper = styled(BodyWrapper)`
  flex: 8;
  padding: 4% 3%;
  overflow: auto;
`;

export const Body = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
