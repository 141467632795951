import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
  Fragment,
} from "react";

interface Modal {
  open: <T>(
    modalFactory: (close: (resolveValue?: T) => void) => ReactElement
  ) => Promise<T | void>;
}

const ImperativeModalContext = createContext<Modal | null>(null);

export const ImperativeModalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [modals, setModals] = useState<ReactElement[]>([]);

  const open: Modal["open"] = useCallback((modalFactory) => {
    return new Promise((resolve) => {
      const close = (resolveValue?: any) => {
        setModals((prevModals) => prevModals.slice(0, -1));
        resolve(resolveValue);
      };

      const modal = modalFactory(close);
      setModals((prevModals) => [...prevModals, modal]);
      return;
    });
  }, []);

  return (
    <ImperativeModalContext.Provider value={{ open }}>
      {children}
      {modals.map((modal, index) => (
        <Fragment key={index}>{modal}</Fragment>
      ))}
    </ImperativeModalContext.Provider>
  );
};

export const useImperativeModal = () => {
  return useContext(ImperativeModalContext) as Modal;
};
