import { styled } from "@mui/material";
import { ComponentType } from "react";
import searchIcon from "assets/icons/ic-search-bold.svg";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  width: 100%;
`;

export const LeftSection = styled("div")`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const RightSection = styled("div")`
  width: 220px;
`;

export const DateWrapper = styled("div")`
  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const DatePickerInput = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
` as ComponentType<any>;

export const TextInputWrapper = styled("div")`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: 8px;
  width: 225px;
  min-width: 235px;
  height: 30px;
  overflow: hidden;
  padding: 0 10px;

  &.MuiOutlinedInput-root:focus {
    border-color: #dee2ec !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #dee2ec !important;
  }

  input {
    padding-left: 35px !important;
    background-image: url(${searchIcon});
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left 8px center;
    width: 100%;
    &::placeholder {
      color: #a1b1ca !important;
      font-weight: 500;
    }
  }

  &&& button {
    min-width: 70px;
    height: 100%;
  }
`;
