import {
  Wrapper,
  CategoryRow,
  CategoryName,
} from "./SurgeryCategoriesName.styled";
import { getCardType, getCardValue } from "utils/tabUtils";

export const SurgeryCategoriesNames = ({ item }: { item: any }) => {
  const getCategories = (item: any) => {
    const categories: any = [];
    const { category, treatmentItems } = item;
    if (category === "NONE") {
      const cardType = getCardType(item);
      const cardValue = getCardValue(item);
      switch (cardType) {
        case "SURGERY":
        case "SKINCARE":
          {
            cardValue.map((card: any) => {
              if (card.ticketUses.length > 0) {
                card.ticketUses.forEach((v: any) => {
                  categories.push({
                    categoryName: v.ticket.treatmentItem.category.name,
                    name: v.ticket.treatmentItem.name,
                    count: v.count,
                  });
                });
              }
            });
          }
          break;
        case "PAYMENT":
          {
            cardValue.map((card: any) => {
              if (card.productItems?.length > 0) {
                card.productItems.forEach((v: any) => {
                  categories.push({
                    name: v.product.name,
                    count: v.quantity,
                  });
                });
              }
              if (card.tickets?.length > 0) {
                card.tickets.forEach((v: any) => {
                  categories.push({
                    categoryName: v.treatmentItem.category.name,
                    name: v.treatmentItem.name,
                    count: v.treatmentCount,
                  });
                });
              }
            });
          }
          break;
      }
    } else {
      //예약 or 접수
      if (treatmentItems?.length > 0) {
        treatmentItems.forEach((v: any) => {
          categories.push({
            categoryName: v.category.name,
            name: v.name,
          });
        });
      }
    }

    return categories;
  };

  const categories = getCategories(item);

  return categories.map((v: any, i: number) => {
    return (
      <Wrapper key={i}>
        <CategoryRow>
          {v.categoryName && (
            <>
              <CategoryName>{v.categoryName}</CategoryName>
              <span>&nbsp;-&nbsp;</span>
            </>
          )}
          <CategoryName>{v.name}</CategoryName>
        </CategoryRow>
        {v.count && <div className="count-wrapper">{v.count}</div>}
      </Wrapper>
    );
  });
};
