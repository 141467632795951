import { useCallback, useEffect, useState } from "react";
import { IconButton, Box } from "@mui/material";
import { Tab } from "components/Tabs";
import { TabContext } from "@mui/lab";
import { delay } from "utils/common";
import Icon from "components/Icon";
import { useSnackbar } from "SnackbarProvider";
import { useApi } from "providers/api";
import { SlideModal, Wrapper, Header } from "components/Modal/SlideModal";
import { InfoList } from "pages/Customer/detail/Detail";
import { AppointmentTable } from "pages/Customer/detail/AppointmentTable";
import {
  Body,
  TabWrapper,
  CustomerName,
  iconButtonStyle,
  Tabs,
} from "modals/CustomerInfoModal.styled";
import { CustomerHeaderLabel } from "components/CustomerHeaderLabel";
import { ChartHistoryItems } from "./ChartHistoryItems";
import SurveyTable from "pages/Customer/detail/SurveyTable";

export function CustomerInfoModal({
  customerData,
  open,
  onClose,
  onCustomerDelete,
}: {
  customerData?: any;
  open?: any;
  onClose?: any;
  onCustomerDelete?: () => void;
  reload?: any;
}) {
  const api = useApi();
  const sb = useSnackbar();
  const [customer, setCustomer] = useState(customerData);
  const [profileImage, setProfileImage] = useState<any>();
  const [deleted, setDeleted] = useState(false);
  const [tab, setTab] = useState("0");

  const onChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const loadProfileImage = useCallback(
    async (profileImageId: string | null) => {
      if (profileImageId !== null) {
        try {
          const res = await api.getImage(profileImageId);
          const payload = await res.json();
          setProfileImage(payload.data);
          if (
            payload.data?.thumbnailUrl === null &&
            payload.data?.thumbnailStatus === "CREATING"
          ) {
            await delay(1000);
            loadProfileImage(profileImageId);
          }
        } catch (e) {
          setProfileImage(null);
          console.log(e);
        }
      }
    },
    []
  );

  const loadCustomer = useCallback(async () => {
    if (!customer?.id) return;
    try {
      const res = await api.getCustomer(customer.id);
      const payload = await res.json();
      setCustomer(payload.data);
      if (payload.data.profileImageId) {
        loadProfileImage(payload.data.profileImageId);
      } else {
        setProfileImage(null);
      }
    } catch (e: any) {
      if (e.code === 404) {
        setDeleted(true);
        sb.open("삭제된 고객입니다.");
      } else {
        setDeleted(false);
      }
    }
  }, [customer]);

  useEffect(() => {
    loadCustomer();
  }, []);

  useEffect(() => {
    setTab("0");
  }, [open]);

  return (
    <SlideModal open={open}>
      <Wrapper>
        <Header>
          <IconButton sx={iconButtonStyle} onClick={onClose}>
            <Icon variant="back_arrow" />
          </IconButton>
          <CustomerName className="customer-name-wrapper">
            {customer?.id && (
              <CustomerHeaderLabel
                label={" 님의 고객정보"}
                profileImage={profileImage}
                customer={customer}
              />
            )}
          </CustomerName>
        </Header>
        <Body style={{ background: tab === "0" ? "#fff" : "#f9fbff" }}>
          <TabWrapper>
            <TabContext value={tab}>
              <Box className="box">
                <Tabs
                  className="tabs"
                  classes={{
                    flexContainer: "flexContainer",
                    indicator: "indicator",
                  }}
                  variant="fullWidth"
                  TabIndicatorProps={{
                    children: (
                      <span>{tab === "0" ? "고객정보" : "내원정보"}</span>
                    ),
                  }}
                  value={tab}
                  onChange={onChangeTab}
                >
                  <Tab label="고객정보" value="0" />
                  <Tab label="내원정보" value="1" />
                  <Tab label="차팅이력" value="2" />
                  <Tab label="문진" value="3" />
                </Tabs>
              </Box>
            </TabContext>
          </TabWrapper>
          {tab === "0" && (
            <InfoList
              customer={customer}
              onClose={onClose}
              onCustomerDelete={onCustomerDelete}
              reload={() => {
                loadCustomer();
              }}
              edit={!deleted}
            />
          )}
          {tab === "1" && (
            <AppointmentTable customer={customer} deleted={deleted} />
          )}
          {tab === "2" && <ChartHistoryItems customer={customer} />}
          {tab === "3" && <SurveyTable customer={customer} />}
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
