import { NoOptionsText } from "~/components/NoOptionsText";
import { AutoCompleteSelect, Popper, SearchInput } from "./AutoComplete.styled";
import { forwardRef } from "react";

export const AutoComplete = forwardRef(
  (
    {
      sx,
      options,
      onChange,
      value,
      inputValue,
      ListboxProps,
      noOptionsText,
      renderOption,
      onChangeInput,
      placeholder,
      inputProps,
      disabled,
      ...props
    }: any,
    ref
  ) => {
    return (
      <AutoCompleteSelect
        sx={sx}
        ref={ref}
        options={options}
        getOptionLabel={(option: any) => option.searchOption ?? ""}
        onChange={onChange}
        value={value}
        inputValue={inputValue}
        ListboxProps={ListboxProps}
        noOptionsText={<NoOptionsText value={noOptionsText} />}
        PopperComponent={Popper}
        renderOption={(props, option) => renderOption(props, option)}
        renderInput={(params) => (
          <SearchInput
            {...params}
            variant="outlined"
            onChange={onChangeInput}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
            }}
          />
        )}
        disabled={disabled}
        {...props}
      />
    );
  }
);
