import { styled, Box as MuiBox } from "@mui/material";

export const Memo = styled("div")`
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  * {
    margin: 0;
  }
`;

export const SortColumnWrapper = styled("div")`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Box = styled(MuiBox)`
  &.department-name {
    word-break: break-word;
  }
`;
