import styled from "@emotion/styled";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  color: #292929;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
