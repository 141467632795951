import {
  Box as MuiBox,
  styled as muiStyled,
  ListItem as MuiListItem,
  Link as MuiLink,
} from "@mui/material";

export const Link = muiStyled(MuiLink)`
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  color:unset;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 100%;
`;

export const Box = muiStyled(MuiBox)``;

export const ListItem = muiStyled(MuiListItem)`
  cursor:pointer;
  display: flex;
  column-gap: 18px;
  padding: 10px 20px;
  min-height: 55px;
`;
