import { styled } from "@mui/material";

export const Wrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.palette.bluegrey["100"]};
  overflow: hidden;
  height: 100%;
`
);
