import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import "assets/styles/index.css";
import "assets/styles/base.css";
import "assets/styles/ui.css";
import { ServiceWorkerConfig } from "serviceWorkerConfig";
import { Updater } from "Updater";
import { GlobalErrorBoundary } from "GlobalErrorBoundary";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV,
    ignoreErrors: ["Missing JWT in headers or json", "Expired Access Token"],
  });

  Sentry.setContext("release_version", {
    version: process.env.REACT_APP_RELEASE_VERSION ?? "",
  });
}

document.addEventListener("visibilitychange", () => {
  Sentry.addBreadcrumb({
    category: "view",
    message: document.visibilityState,
    level: Sentry.Severity.Info,
  });
});

window.addEventListener("pagehide", () => {
  Sentry.addBreadcrumb({
    category: "view",
    message: "pagehide",
    level: Sentry.Severity.Info,
  });
});

if (navigator.connection) {
  navigator.connection.addEventListener("change", () => {
    Sentry.addBreadcrumb({
      category: "network",
      message: navigator.connection.effectiveType,
      level: Sentry.Severity.Info,
    });
  });
}

window.addEventListener("offline", () => {
  Sentry.addBreadcrumb({
    category: "network",
    message: "offline",
    level: Sentry.Severity.Info,
  });
});

window.addEventListener("online", () => {
  Sentry.addBreadcrumb({
    category: "network",
    message: "online",
    level: Sentry.Severity.Info,
  });
});

document
  .getElementById("ogUrl")
  .setAttribute("content", process.env.REACT_APP_URL ?? "");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalErrorBoundary>
      <App />
    </GlobalErrorBoundary>
    <Updater />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(ServiceWorkerConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
