import { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { isBefore, addDays } from "date-fns";

export function useSearchHistory() {
  const MAX_DATE = 30;
  const MAX_DATA_LENGTH = 20;
  const cookieName = "search-history";
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [history, setHistory] = useState(
    (cookies[cookieName] ?? []).filter((v: any) =>
      isBefore(new Date(), new Date(v.expires))
    )
  );

  const removeAllHistory = useCallback(() => {
    removeCookie(cookieName, { path: "/" });
    setHistory([]);
  }, [removeCookie]);

  const removeHistory = useCallback(
    (searchValue?: any) => {
      if (!searchValue) return;
      const update = history.filter((v: any) => v.value !== searchValue);
      setCookie(cookieName, [...update], { path: "/" });
      setHistory(update);
    },
    [history, setCookie]
  );

  const addHistory = useCallback(
    (searchValue: string) => {
      if (!searchValue) return;
      const update = history.filter(
        (v: any) => v.value !== searchValue && isBefore(new Date(), v.expires)
      );
      if (searchValue) {
        update.unshift({
          value: searchValue,
          expires: addDays(new Date(), MAX_DATE),
        });
      }
      setCookie(cookieName, update.slice(0, MAX_DATA_LENGTH), { path: "/" });
      setHistory(update);
    },
    [history, setCookie]
  );

  return {
    addHistory,
    removeHistory,
    removeAllHistory,
    history,
  };
}
