import { Box } from "./CustomToolbar.styled";
import { RefObject } from "react";

type CustomToolbarProps = {
  id: string;
  boilerplate?: boolean;
  colorRef: RefObject<HTMLButtonElement>;
  backgroundRef: RefObject<HTMLButtonElement>;
};

export const CustomToolbar = (props: CustomToolbarProps) => {
  const { id, boilerplate, colorRef, backgroundRef } = props;

  return (
    <Box id={id}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button ref={colorRef} className="ql-color" />
      <button ref={backgroundRef} className="ql-background" />
      {boilerplate && <button className="ql-boilerplate" />}
    </Box>
  );
};
