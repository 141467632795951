import {
  Box as MuiBox,
  Button as MuiButton,
  Stack as MuiStack,
  TextField as MuiTextField,
  Typography as MuiTypography,
  typographyClasses,
  buttonClasses,
  Link as MuiLink,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const Link = styled(MuiLink)``;

export const Typography = styled(MuiTypography)`
  font-family: Noto Sans KR;
  &.${typographyClasses.subtitle1} {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.01em;
  }

  &.time-count {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
  }

  &.${typographyClasses.body1} {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    & + .${typographyClasses.body1} {
      margin-top: 10px;
    }
  }
`;

export const TextField = styled(MuiTextField)(
  ({ theme }) => `
  &.MuiOutlinedInput-root {
    &.Mui-focused {
      border: 0 none;
    }
  }
  
  .MuiOutlinedInput-notchedOutline {
    border: 0;
    border-bottom: 1px solid rgb(173 174 188 / 10%);
    border-radius: 0;
  }

  &.error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.error["main"]};
    }
    .MuiOutlinedInput-root:hover fieldset {
      border-color: ${theme.palette.error["main"]};
    }
  }

  fieldset {
    border: 0 none;
  }
  
  input {
    height: 52px;
    box-sizing: border-box;
    background: #f9fbff;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    border: 0 none;

    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
`
);

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.confirm-page-wrapper {
    width: 100vw;
    height: 100vh;
    min-height: 670px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.confirm-certification-number-wrapper {
    width: 400px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .confirm-input {
      margin-top: 30px;
    }

    form {
      width: 100%;
    }
  }

  &.footer {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: ${theme.palette.bluegrey["600"]};
    padding-top: 30px;
    min-height: 150px;
  }
`
);

export const Button = styled(MuiButton)(
  ({ theme }) => `
  font-family: Noto Sans KR;
  width: 100%;
  height: 54px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 4px;
  box-shadow: none;

  &.black {
    background: #293142;
  }

  &.${buttonClasses.disabled} {
    color: #ffffff;
  }


  &.resend-btn {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: underline;
    color: ${theme.palette.grey["400"]};
  }
`
);

export const Stack = styled(MuiStack)`
  &.mt-48 {
    margin-top: 48px;
  }
`;
