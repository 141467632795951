import { useRef, LegacyRef } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
} from "./ProfileImageModal.styled";
import Icon from "components/Icon";
import { useSnackbar } from "SnackbarProvider";

export interface ProfileImageModalProps {
  onClose: (params?: any) => void;
  confirmText?: string;
  variant?: "warning";
  bgClickCancel?: boolean;
  disableBgClick?: boolean;
  disableActions?: boolean;
  profileImage: any;
  localBlobUrl: any;
  setProfileImage: (value: any) => void;
  setLocalBlobUrl: (value: any) => void;
}

export const ProfileImageModal = ({
  onClose,
  confirmText,
  // variant,
  bgClickCancel = false,
  disableBgClick = false,
  disableActions = false,
  profileImage,
  setProfileImage,
  localBlobUrl,
  setLocalBlobUrl,
  ...props
}: ProfileImageModalProps) => {
  const sb = useSnackbar();
  const cameraInputRef = useRef<HTMLInputElement>();
  const imageInputRef = useRef<HTMLInputElement>();

  const handleDeleteProfile = () => {
    setLocalBlobUrl(null);
    setProfileImage(null);
    onClose();
  };

  const onClickCameraInput = (e: any) => {
    e.target.value = null;
  };

  const onClickImageInput = (e: any) => {
    e.target.value = null;
  };

  const onChangeProfileImage = (e: any) => {
    const target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split(".").pop();
    const confirmType = ["jpg", "JPG", "png", "PNG", "jpeg"];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      sb.open("10MB 이하의 이미지 파일만 등록할 수 있습니다.");
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      sb.open("jpg 또는 PNG 파일만 등록할 수 있습니다.");
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event: any) {
      const img: any = new Image();
      img.src = event.target.result;
      img.onload = function () {
        setLocalBlobUrl(URL.createObjectURL(e.target.files[0]));
        setProfileImage(target.files[0]);
      };
    };
    reader.readAsDataURL(target.files[0]);

    onClose();
  };

  const onClickCamera = () => {
    cameraInputRef.current && cameraInputRef.current.click();
  };

  const onClickImage = () => {
    imageInputRef.current && imageInputRef.current.click();
  };

  return (
    <Dialog
      open
      onClose={() => {
        onClose();
      }}
      {...props}
    >
      <DialogTitle>
        프로필 사진 편집{" "}
        <IconButton onClick={onClose}>
          <Icon variant="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Button variant="text" onClick={onClickCamera}>
          사진 촬영
        </Button>
        <Button variant="text" onClick={onClickImage}>
          갤러리에서 가져오기
        </Button>
        <Button
          variant="text"
          disabled={!profileImage ? true : false}
          onClick={handleDeleteProfile}
        >
          사진 삭제
        </Button>
      </DialogContent>
      <input
        ref={cameraInputRef as LegacyRef<HTMLInputElement>}
        style={{ display: "none" }}
        accept="image/*"
        type="file"
        capture
        onChange={onChangeProfileImage}
        onClick={onClickCameraInput}
      />
      <input
        ref={imageInputRef as LegacyRef<HTMLInputElement>}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
        type="file"
        multiple
        onChange={onChangeProfileImage}
        onClick={onClickImageInput}
      />
    </Dialog>
  );
};
