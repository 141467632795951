import styled, { css } from "styled-components";
import { TableStyle, TdStyle } from "./DataTableUtils.styled";

export const ResizeButton = styled.div<{ active?: boolean }>`
  display: flex;
  position: absolute;
  width: 2px;
  right: 0;
  top: 0;
  z-index: 1;
  height: 100%;

  ${({ active, theme }) =>
    active
      ? css`
          border-right: 1px solid transparent;
          border-color: ${theme.color.primary.unoblue};
        `
      : ""}
`;

export const Table = styled.table<{ $resizable?: boolean }>`
  ${TableStyle}
  ${({ $resizable }) => ($resizable ? "width: max-content;" : "width: 100%;")}
`;

export const Tbody = styled.tbody<{ ref?: any }>`
  font-size: 14px;
  color: #2d2d2d;
  white-space: pre;
  tr:last-of-type {
    border-bottom: 1px solid #dee2ec;
  }
`;

export const Tr = styled.tr<{
  $highlight?: boolean;
  firstTr?: boolean;
  $bg?: boolean;
  trStyle?: any;
}>`
  ${({ $bg }) =>
    $bg
      ? css`
          background: #f9fbff;
        `
      : ""}
  &&& {
    ${({ $highlight }) =>
      $highlight
        ? css`
            background: #f1f1f1;
          `
        : ""}
  }
  ${({ trStyle }) => trStyle && trStyle}
`;

export const Td = styled("td")<{
  disabled?: boolean;
  style?: any;
  $grow?: any;
}>`
  ${({ style }) => style && style}
  ${TdStyle}
  border-bottom: unset;
  height: 32px;

  ${({ disabled }) =>
    disabled
      ? `
      background-color: #F1F1F1;
      color: #bbbbbb;
    `
      : ""}
`;
