import styled from "styled-components";
import { styled as muiStyled } from "@mui/material";
import { TableStyle, TbodyStyle, TdStyle } from "./DataTableUtils.styled";

export const Table = styled.table<{ $isDragging: string | false }>`
  ${TableStyle}
  border-top: 0;
  ${({ $isDragging }) =>
    $isDragging &&
    `
      background: #dee2ec;
    `}
`;

export const Tbody = muiStyled("tbody")<{ $isDragging: string | false }>`
  border: 1px solid ${({ theme }) => theme.palette.layout.line};
  ${TbodyStyle as any}
  tr {
    ${({ $isDragging }) =>
      !$isDragging &&
      `
        &:hover {
          background-color: #edeff1;
        }
      `}
  }
  td {
    border: 1px solid ${({ theme }) => theme.palette.layout.line};
  }
`;

const loop = (_: any, length?: number, draggingTrHeight?: number[]) => {
  let style = "";
  if (length === undefined || length <= 1) {
    return style;
  }
  for (let i = 0; i < length; i++) {
    style += `
    .MuiBox-root:nth-of-type(${i + 1}) {
      
      ${draggingTrHeight ? `height: ${draggingTrHeight[i]}px !important;` : ""}
    }
    `;
  }
  return `
    ${style}
  `;
};

export const Tr = muiStyled("tr")<{
  trStyle?: any;
  firstTr?: boolean;
  $highlight?: boolean;
  itemsLength?: number;
  draggingTrHeight?: any[];
}>(
  ({ theme, itemsLength, $highlight, trStyle, draggingTrHeight }) => `
  &.odd {
    background: #ffffff;
    td {
      &::before {
        background: #ffffff;
      }
    }
  }
  &.even {
    background: #f9fbff;
    td {
      &::before {
        background: #f9fbff;
      }
    }
  }
  &.z-index-top {
    z-index: 1;
    position: relative;
  }
  &.dragging-tr {
    background: #f1f1f1;
    td {
      background: #f1f1f1;
      line-height: 19px;
      height: 100%;

      &::before {
        background: #f1f1f1;
      }

      & > div > div {
        overflow: unset;
      }
      
      .MuiButton-root{
        height: 19px;
        position:relative;
        top: 1px;
      }

      &.multi-value {
        padding: 0 !important;
        position: relative;

        & > div > div {
          flex-direction: column;
          align-items: center;
          display: flex;
        }

        .MuiBox-root {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 4px 14px;

          & + .MuiBox-root {
            border-top: 1px solid ${theme.palette.layout.line};
          }
        }

        .seperate-value {
          width: 100%;
        }

        ${loop(theme, itemsLength, draggingTrHeight)}
      }
    }
    & + .dragging-tr {
      display: none;
    }
  }
  &.hover-tr {
    td {
      background-color: #edeff1;
      &::before {
        background: #edeff1;
      }
    }
  }
  &.hover-td {
    td[rowspan]{
      background-color: #edeff1;
    }
  }
  &&& {
    ${$highlight ? `background: #f1f1f1;` : ""}
  }
  ${trStyle ? trStyle : ""}
`
);

export const Td = muiStyled("td")<{ disable?: boolean; $grow?: any }>(
  ({ disable, theme }) => `
  ${TdStyle}
  border-left: 0;
  border-top: 0;
  height: 100%;

  &.disabled {
    color: ${theme.palette.grey["400"]};
    background: ${theme.palette.grey["200"]};
    
    &::before {
      background: ${theme.palette.grey["200"]};
    }
  }

  .MuiBox-root {
    min-height: 25px;
    align-items: center;
    display: flex;
  }

  &.text-align-left {
    .injected-collapse {
      padding: 4px 14px;
      justify-content: flex-start;
      text-align: left;
    }
  }


  .injected-collapse {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    white-space: normal;
   
  }
  
  ${
    disable
      ? `
      color: ${theme.palette.grey[400]};
    `
      : ""
  }
`
);

export const TdInner = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const DragButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 0 0 auto;
  padding: 0;
`;
