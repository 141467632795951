import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { ContextMenuItemSmall } from "components/ContextMenu/ContextMenuItem.styled";
import { AppointmentStatus } from "type/appointmentStatus";
import {
  ListItemWrapper,
  ColorDot,
  StatusText,
  StatusInfo,
} from "components/ChartContextMenu.styled";
import { useStatusSettings } from "providers/StatusSettingsProvider";
import { SessionStatusConfigType } from "type/statusSettings";

const StatusItem = ({
  status,
}: {
  status: { color: string; name: string };
}) => {
  return (
    <ListItemWrapper>
      <StatusInfo>
        <ColorDot color={status.color} />
        <StatusText>{status.name}</StatusText>
      </StatusInfo>
    </ListItemWrapper>
  );
};

const ChartContextMenu = ({
  anchorEl,
  openStatusMenu,
  appointment,
  onClickStatus,
}: {
  anchorEl?: any;
  openStatusMenu: boolean;
  appointment: any;
  onClickStatus?: any;
}) => {
  const statusSettings = useStatusSettings();

  const cancelStatuses = [
    ...(statusSettings?.settings ?? []).filter(
      (item: SessionStatusConfigType) =>
        item.code === AppointmentStatus.scheduled ||
        item.code === AppointmentStatus.noShow
    ),
  ];

  const noShowStatuses = [
    ...(statusSettings?.settings ?? []).filter(
      (item: SessionStatusConfigType) =>
        item.code === AppointmentStatus.scheduled ||
        item.code === AppointmentStatus.canceled
    ),
  ];

  const AppointmentStatusGroup = [
    ...(statusSettings?.settings ?? []).filter(
      (item: SessionStatusConfigType) =>
        item.type === AppointmentStatus.additionalStatus ||
        [
          AppointmentStatus.canceled,
          AppointmentStatus.noShow,
          AppointmentStatus.consultingWaiting,
          AppointmentStatus.consultingDuring,
          AppointmentStatus.treatmentWaiting,
          AppointmentStatus.treatmentDuring,
          AppointmentStatus.surgeryWaiting,
          AppointmentStatus.surgeryDuring,
          AppointmentStatus.paymentWaiting,
        ].includes(item.code)
    ),
  ];

  const RegistrationStatusGroup = [
    ...(statusSettings?.settings ?? []).filter(
      (item: SessionStatusConfigType) =>
        item.type === AppointmentStatus.additionalStatus ||
        [
          AppointmentStatus.registrationCanceled,
          AppointmentStatus.consultingWaiting,
          AppointmentStatus.consultingDuring,
          AppointmentStatus.treatmentWaiting,
          AppointmentStatus.treatmentDuring,
          AppointmentStatus.surgeryWaiting,
          AppointmentStatus.surgeryDuring,
          AppointmentStatus.paymentWaiting,
          AppointmentStatus.complete,
          AppointmentStatus.leave,
        ].includes(item.code)
    ),
  ];

  return (
    <ContextMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
      autoFocus={false}
      disableAutoFocus
      disableEnforceFocus
      open={openStatusMenu}
      onClose={() => {
        return;
      }}
    >
      <div style={{ pointerEvents: "auto", minWidth: "106px" }}>
        {(appointment.status === AppointmentStatus.canceled
          ? cancelStatuses
          : appointment.status === AppointmentStatus.noShow
          ? noShowStatuses
          : appointment.type === "APPOINTMENT"
          ? AppointmentStatusGroup
          : RegistrationStatusGroup
        )
          .filter(
            (v) =>
              ![
                appointment.status.includes("DONE")
                  ? AppointmentStatus.complete
                  : appointment.status === AppointmentStatus.additionalStatus
                  ? appointment.sessionStatusConfig.code
                  : appointment.status,
                ...(appointment.type === "APPOINTMENT"
                  ? [AppointmentStatus.complete, AppointmentStatus.leave]
                  : [AppointmentStatus.scheduled, AppointmentStatus.noShow]),
              ].includes(v.code)
            // 현재 상태 && 예약일경우 완료,퇴원 / 접수일경우 예약, 미방문 제외하고 보여야함
          )
          .map((s) => (
            <ContextMenuItemSmall
              key={s.id}
              onClick={() => {
                onClickStatus({
                  data: appointment,
                  status: s,
                });
              }}
            >
              <StatusItem status={s} />
            </ContextMenuItemSmall>
          ))}
      </div>
    </ContextMenu>
  );
};

export default ChartContextMenu;
