import {
  styled,
  Avatar as MuiAvatar,
  IconButton as MuiIconButton,
} from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  ({ theme }) => `
  &.image-edit-btn {
    position: absolute;
    bottom: 0;
    right:9px;
    width: 22px;
    height: 22px;
    background: #ffffff;
    border: 1px solid ${theme.palette.layout.line};
    padding: 0;
    svg,path {
      width: 9px;
    }
    z-index: 1;
  }
`
);

export const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  background: ${theme.palette.bluegrey["500"]};
  svg {
    width: 25px;
    fill: #A4BCF2;
  }
  margin-right: 18px;
  margin-left: 8px;
  cursor: pointer;

  &.female {
    background: rgb(253, 238, 238);
    svg {
      fill: rgb(235, 87, 87);
      fill-opacity: 0.2;
    }
  }
`
);

export const CustomerName = styled("div")`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  width: 100%;
`;
