import { FormControlLabel, RadioGroup, styled } from "@mui/material";
import { hexToRgbaString } from "~/utils/colorUtil";
export const RadioWrapper = styled(RadioGroup)`
  height: 100%;

  label.MuiFormControlLabel-root {
    margin-right: 14px !important;
  }
`;

export const RadioButton = styled(FormControlLabel)(
  ({ theme }) => `
  min-width: 40px;
  margin-left: 0;
  font-size: 11px;
  gap: 5px;

  &.Mui-disabled {
    
    .MuiTypography-root {
      color: ${hexToRgbaString(theme.palette.grey["700"], 0.5)} !important;
    }
  }

  .MuiTypography-root {
    font-size: inherit;
    line-height: 1rem;
    color: ${theme.palette.grey["700"]};
  }

  span {
    padding: 0px;
  }
`
);

export const RadioIcon = styled("span")(() => ({
  border: "solid 1px #A1B1CA",
  borderRadius: "50%",
  width: "16px",
  height: "16px",

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#f1f1f1",
    border: "solid 1px #DEE2EC",
  },
}));

export const RadioCheckedIcon = styled(RadioIcon)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "16px",
  height: "16px",
  backgroundColor: "white",
  borderRadius: "50%",

  "&::before": {
    content: '""',
    width: "10px",
    height: "10px",
    backgroundColor: "#2C62F6",
    borderRadius: "50%",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#f1f1f1",
    border: "solid 1px #DEE2EC",
  },

  ".Mui-disabled &::before": {
    content: '""',
    width: "10px",
    height: "10px",
    backgroundColor: "#DEE2EC",
    borderRadius: "50%",
  },
});
