import { Avatar as MuiAvatar, styled } from "@mui/material";
import { Body as BodyWrapper } from "components/Modal/SlideModal";

export const Body = styled(BodyWrapper)`
  padding: 0;
`;

export const CustomerName = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const iconButtonStyle = { width: "70px", height: "100%" };

export const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  background: ${theme.palette.bluegrey["500"]};
  svg {
    width: 25px;
    fill: #A4BCF2;
  }
  margin-right: 10px;
  margin-left: 8px;
  
  &.female {
    background: rgb(253, 238, 238);
    svg {
      fill: rgb(235, 87, 87);
      fill-opacity: 0.2;
    }
  }
`
);
