import { format } from "date-fns";

export const getCustomerInfo = (customer?: any) => {
  if (!customer) return;
  const data = [
    customer.sex === "female" ? "F" : "M",
    customer.age === null
      ? "-"
      : customer.age === 0
      ? "-"
      : `만 ${customer.age}세`,
  ];
  return data.filter((v) => v).join("/");
};

export const getCustomerName = (customer: any) => {
  if (!customer) return;
  const { name, birthday } = customer;
  return `${name ?? "-"}(${getCustomerInfo(customer)}/${
    birthday ? format(new Date(birthday), "yyMMdd") : "-"
  })`;
};
