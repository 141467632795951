import {
  Autocomplete,
  TextField,
  textFieldClasses,
  Popper as MuiPopper,
  autocompleteClasses,
  styled,
} from "@mui/material";
import { ClearButton as _ClearButton } from "~/components/ClearButton";
import { NoOptionsText as _NoOptionsText } from "~/components/NoOptionsText";
import { ComponentType } from "react";

const fontSize = "12px";

export const ClearButton = styled(_ClearButton)`` as ComponentType<any>;
export const NoOptionsText = styled(_NoOptionsText)`` as ComponentType<any>;

export const Input = styled(TextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
    }

    & .MuiOutlinedInput-root {
      height: 100%;
      padding: 0 !important;
    }
  }
`;

export const Li = styled("li")`
  height: 29px;
  font-size: ${fontSize};
  padding: 0 10px !important;
`;

export const IconWrapper = styled("div")`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 4px 10px !important;
    min-height: 100px;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }

  .${autocompleteClasses.listbox} {
    padding: 4px 0 !important;
  }
`;

export const AutoCompleteSelect = styled(Autocomplete)`
  &.${autocompleteClasses.root} {
    width: 100%;
  }
  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }
  .${autocompleteClasses.endAdornment} {
    right: 8px;
    position: relative !important;
    top: 10px;
    display: flex;
    align-items: center;
  }
  .${autocompleteClasses.clearIndicator} {
    margin-right: 0 !important;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;

    div {
      margin: 0;
    }
  }
`;

export const SelectLabel = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  height: 29px;
  cursor: default;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.bluegrey[600]};
  padding: 0 10px;
`;
