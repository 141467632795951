import { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { AutoComplete } from "~/components/AutoComplete";
import {
  FindCustomerAutoCompleteRow,
  returnSex,
} from "./FindCustomerAutoCompleteRow";
import Icon from "components/Icon";
import { ClearButton } from "~/components/ClearButton";
import { Fade, InputAdornment, Popper } from "@mui/material";
import { useApi } from "providers/api";

const IconWrapper = styled.div`
  display: flex;
`;

type FindRecommenderInputProps = {
  disabled?: boolean;
  value: any;
  setValue: any;
};

export const FindRecommenderInput = observer(
  ({ value, setValue, disabled, ...props }: FindRecommenderInputProps) => {
    const api = useApi();
    const [focused, setFocused] = useState(false);
    const [timer, setTimer]: any = useState(0); // 디바운싱 타이머
    const [customers, setCustomers] = useState([]);
    const [inputValue, setInputValue] = useState(value.recommender?.name ?? "");

    const findCustomer = async (param: any) => {
      const res = await api.searchCustomer(param, 100);
      const payload = await res.json();
      const { data } = payload;
      setCustomers(
        data.map((v: any) => {
          return {
            ...v,
            searchValue: `${v.name ?? "-"}/${v.birthday ?? "-"}/${
              returnSex(v.sex) ?? "-"
            }/${v.phoneNumber ?? "-"}`,
            searchOption: `${v.name}/${v.phoneNumberLast}`,
          };
        })
      );
    };

    const onChangeItem = (e: any, option: any) => {
      if (e.key === "Enter" && typeof option === "string") {
        return;
      }
      if (option === null) {
        setInputValue("");
        value.recommenderId = null;
        value.recommenderName = null;
        value.recommender = null;
        setValue({ ...value });
      } else {
        setInputValue(option.name);
        value.recommenderId = option.id;
        value.recommenderName = option.name;
        value.recommender = option;
        setValue({ ...value });
      }
    };

    const onChangeInput = (e: any) => {
      const { target } = e;
      const { value: _value } = target;
      if (!_value) {
        value.recommenderId = null;
        value.recommenderName = null;
        value.recommender = null;
        setValue({ ...value });
        setCustomers([]);
      }
      setInputValue(_value);
      if (_value.length >= 2) {
        if (timer) {
          clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
          const param: any = {};
          if (!isNaN(_value) && _value.length === 4) {
            param.phoneNumberLast = _value;
          } else {
            param.name = _value;
          }
          findCustomer(param);
        }, 500);
        setTimer(newTimer);
      }
    };

    const PopperComponent = function (props: any) {
      return (
        <Popper
          {...props}
          placement="bottom-start"
          style={{ width: "360px", zIndex: 1300 }}
        />
      );
    };

    return (
      <AutoComplete
        {...props}
        options={customers}
        onChange={(e: any, option: any) => onChangeItem(e, option)}
        inputValue={inputValue}
        ListboxProps={{ style: { maxHeight: "334px", padding: 0 } }}
        PopperComponent={PopperComponent}
        renderOption={(props: any, option: any) => (
          <FindCustomerAutoCompleteRow props={props} option={option} />
        )}
        onChangeInput={onChangeInput}
        placeholder="소개자를 검색하세요"
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        inputProps={{
          endAdornment: (
            <>
              <Fade in={focused && inputValue ? true : false}>
                <InputAdornment position="end">
                  <ClearButton
                    onClick={() => {
                      setInputValue("");
                      value.recommenderId = null;
                      value.recommenderName = null;
                      value.recommender = null;
                      setValue({ ...value });
                      setCustomers([]);
                    }}
                  />
                </InputAdornment>
              </Fade>
              <IconWrapper>
                <Icon variant="search" />
              </IconWrapper>
            </>
          ),
        }}
      />
    );
  }
);
