import Icon from "components/Icon";
import {
  Wrapper,
  Title,
  Name,
  DeleteButton,
  List,
  IconButton,
} from "./SearchHistoryList.styled";

type SearchHistoryListProps = {
  data: any;
  onDelete: (value: any) => void;
  onDeleteAll: () => void;
  onSearch: (value: any) => void;
};

export function SearchHistoryList({
  data,
  onDelete,
  onDeleteAll,
  onSearch,
}: SearchHistoryListProps) {
  return (
    <Wrapper>
      {Boolean(data?.length) && (
        <>
          <Title>
            <Name>최근검색어</Name>
            <DeleteButton onClick={onDeleteAll}>전체삭제</DeleteButton>
          </Title>
          <List>
            {data.map((v: any) => (
              <li
                key={`${v.value}-${v.expires}`}
                onClick={() => onSearch(v.value)}
              >
                {v.value}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(v.value);
                  }}
                >
                  <Icon variant="close" />
                </IconButton>
              </li>
            ))}
          </List>
        </>
      )}
    </Wrapper>
  );
}
